<template>
  <br /> <br />
  <criar-blacklist />

  <div class="table-responsive">
    <table id="zero_config" class="table table-striped table-bordered no-wrap">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Tag</th>
          <th style="width: 15%;">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="blacklist in blacklists" :key="blacklist" :value="blacklist">

          <td>{{ blacklist.nome }}</td>

          <td>{{ blacklist.tag }}</td>

          <td>
            <button data-toggle="modal" data-target="#editar-modal" @click="selecionarLista(blacklist)" type="button"
              class="btn btn-info">
              Editar
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="modal fade" id="editar-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <form @submit.prevent>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
              &times;
            </button>
            <h4>Editar Lista</h4>
            <br />
            <div class="form-group">
              <label for="email">Nome</label>
              <input class="form-control" id="email" type="text" v-model="lista_selecionada.nome" />
            </div>

            <div class="form-group">
              <label>Novo Telefone</label>
              <div class="input-group mb-3">
                <input type="text" class="form-control" v-model="novo_telefone">
                <div class="input-group-prepend">
                  <button type="button" class="btn btn-success" @click="addTelefone()">Add
                    <i style="margin-left: 3px;" class="nav-icon far fa-plus-square"></i></button>
                </div>
              </div>
            </div>

            <br /><br />
            <label v-if="lista_selecionada.telefones_formatados">Total: {{ lista_selecionada.telefones_formatados.length
            }}</label>
            <table id="zero_config" class="table table-striped table-bordered no-wrap">
              <thead>
                <tr>
                  <th>Telefone</th>
                  <th style="width: 10%;">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(telefone, index) in lista_selecionada.telefones_formatados" :key="telefone" :value="telefone">

                  <td>{{ telefone }}</td>

                  <td>
                    <button @click="confirmarDelete(telefone, index)" type="button" class="btn btn-danger">
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <button data-toggle="modal" data-target="#editar-modal" class="btn btn-info">
              Cancelar
            </button>
            <button @click="validateFields()" class="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import sweetAlert from "../controller/sweetAlert";
import moment from "moment";

export default {
  mixins: [sweetAlert],

  props: ['blacklists'],

  data() {
    return {
      lista_selecionada: "",
      novo_telefone: ""
    };
  },

  mounted() {
    console.log("Listando");
    console.log(this.blacklists);
  },

  methods: {

    confirmarDelete(telefone, indice) {
      let html = "Deseja <b style='font-size:20px; color: red'> Remover</b> o telefone <br/>" +
        "<b style='font-size:25px'>" + telefone + "</b><br/> da Blacklist?";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!"
        })
        .then(result => {
          if (result.value) {
            this.lista_selecionada.telefones_formatados.splice(indice, 1);
            this.update();
          }
        });
    },

    addTelefone() {

      this.novo_telefone = this.novo_telefone.replace(/[^Z0-9]/g, "");

      if (this.novo_telefone.length < 10) {
        this.showErrorMessageWithButton("Ops...", "O campo telefone precisa ter no mínimo 10 caracteres!");
      } else {
        this.lista_selecionada.telefones_formatados.push(this.novo_telefone);

        this.novo_telefone = "";

        this.update();
      }


    },

    validateFields() {
      if (!this.lista_selecionada.nome) {
        this.showErrorMessageWithButton("Ops...", "O campo nome é obrigatório!");
      } else {
        this.update();
      }
    },

    async update() {

      this.isLoading = true;

      let data = {
        id: this.lista_selecionada.id,
        nome: this.lista_selecionada.nome,
        telefones: JSON.stringify(this.lista_selecionada.telefones_formatados),
      };

      console.log("ATUALIZANDO");
      console.log(data);

      axios
        .post(`/blacklist/update`, data)
        .then((response) => {
          this.showSuccessMessage("Lista atualizada!");

          //window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    selecionarLista(blacklist) {
      this.lista_selecionada = blacklist;

      let telefones_formatados = this.validateJSON(this.lista_selecionada.telefones);

      this.lista_selecionada.telefones_formatados = telefones_formatados;

      if (!telefones_formatados) {
        this.lista_selecionada.telefones_formatados = [];
      }

      console.log("Seelcionada");
      console.log(this.lista_selecionada);
    },

    validateJSON(json) {
      console.log("VALIDANDO JSON");

      try {
        let jsonValido = JSON.parse(json);

        console.log("JS");
        console.log(jsonValido);

        if (jsonValido) {
          return jsonValido;
        } else {
          return false;
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },

    replaceAll(string, search, replace) {
      return string.split(search).join(replace);
    },
  },
};
</script>
