<template>
  <br /><br />
  <div class="row">
    <div class="col-md-3 col-sm-6 col-12">
      <div class="info-box">
        <span class="info-box-icon bg-success"><i style="color:white" class="fas fa-solid fa-users"></i></span>

        <div class="info-box-content">
          <span class="info-box-text">Promotores</span>
          <span class="info-box-number">{{ total_usuarios }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-12">
      <div class="info-box">
        <span class="info-box-icon" style="background: rgb(26, 188, 156);"><i style="color:white"
            class="fas fa-solid fa-share"></i></span>

        <div class="info-box-content">
          <span class="info-box-text">Campanhas</span>
          <span class="info-box-number">{{ campanhas.length }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-12">
      <div class="info-box">
        <span class="info-box-icon bg-info"><i class="far fa-envelope"></i></span>

        <div class="info-box-content">
          <span class="info-box-text">Listas capturadas</span>
          <span class="info-box-number">{{ total_listas }}</span>
        </div>
      </div>
    </div>
  </div>
  <br /><br />


  <!-- PROMOTORES
  <cadastrar-promotor />
  <div class="card">
    <div class="card-body p-0">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 10%">Nome</th>
            <th style="width: 10%">Telefone</th>
            <th style="width: 10%">Cadastros</th>
            <th style="width: 10%">Total contatos</th>
            <th style="width: 10%">Link de indicação</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="promotor in promotores" :key="promotor.id">
            <td data-label="Nome">{{ promotor.name }}</td>

            <td data-label="Telefone">{{ promotor.telefone }}</td>

            <td data-label="Cadastros">{{ promotor.total_cadastros }}</td>

            <td data-label="Contatos">{{ promotor.total_contatos }}</td>

            <td data-label="Contatos"> <button @click="selecionarPromotor(promotor)" class="btn btn-success btn-sm"
                data-toggle="modal" data-target="#link-modal">
                Link
              </button></td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
   -->

  <!-- LINK DE INDICAÇÃO -->
  <div v-if="promotor_selecionado" class="modal fade" id="link-modal" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <form @submit.prevent>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
              &times;
            </button>
            <h4>Detalhes</h4>
            <br />
            <div class="col-lg-12">
              <div class="mb-4">
                <div class="form-group">
                  <label>Selecione uma Campanha</label>
                  <select class="form-control" v-model="campanha_selecionada">
                    <option value=""></option>
                    <option v-for="campanha in campanhas" :key="campanha" :value="campanha.id">
                      {{ campanha.nome }}
                    </option>
                  </select>
                </div>

                <button v-if="campanha_selecionada != ''" @click="gerarLink()" class="btn btn-primary btn-sm">
                  Gerar
                </button>

                <div class="input-group mb-3" v-if="url != ''" style="margin-top:5%">
                  <input id="nome" type="text" class="form-control" v-model="url" autocomplete="url" />

                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-success" @click="copiarLink()">Copiar
                      <i style="margin-left: 3px;" class="nav-icon far fa-plus-square"></i></button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </form>
  </div>

  <br /> <br /> <br />
  <h6 style="text-align: center; font-weight: bold;">Últimos cadastros</h6>
  <!-- ULTIMOS CADASTROS -->
  <div class="table-responsive">
    <table id="zero_config" class="table table-striped table-bordered no-wrap">
      <thead>
        <tr>
          <th>Promotor</th>
          <th>Nome Dono</th>
          <th>Telefone</th>
          <th>Total Contatos</th>
          <th>Data de cadastro</th>
          <th>Detalhes</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="lista_contatos in ultimos_cadastros" :key="lista_contatos" :value="lista_contatos">
          <td>{{ lista_contatos.promotor }}</td>

          <td>{{ lista_contatos.nome_dono }}</td>

          <td>{{ lista_contatos.telefone_dono }}</td>

          <td>{{ lista_contatos.total_contatos }}</td>

          <td>
            {{ formatDate(lista_contatos.data_cadastro) }}
          </td>

          <td>
            <button @click="selecionarContato(lista_contatos)" data-toggle="modal" data-target="#detalhes-modal"
              type="button" class="btn btn-primary btn-sm">
              Detalhes <i class="fa fa-search"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div v-if="contato_selecionado" class="modal fade" id="detalhes-modal" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <form @submit.prevent>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
              &times;
            </button>
            <h4>Detalhes</h4>
            <br />
            <div class="col-lg-12">
              <div class="mb-4">
                <p>

                  <button class="btn btn-primary ml-1" type="button" data-toggle="collapse" data-target="#collapseExample"
                    aria-expanded="false" aria-controls="collapseExample">
                    Exibir Lista de contatos
                  </button>

                  <button class="btn btn-success" @click="ExportExcel('xlsx')">Baixar Excel xlsx</button>
                </p>
                <div class="collapse" id="collapseExample">
                  <div class="card card-body">
                    <table id="myTable" ref="exportable_table" class="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th style="width: 25%">Nome</th>
                          <th style="width: 25%">Telefone</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="contato in contatos" :key="contato">
                          <td data-label="Nome">{{ contato.telefone }}</td>

                          <td data-label="Telefone">{{ contato.nome }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [wppController, sweetAlert, Swal],

  components: { Loading },

  props: ["total_usuarios", "total_listas", "promotores", "ultimos_cadastros", "campanhas"],

  data() {
    return {
      promotor: {},
      nome: "",
      email: "",
      senha: "",
      isLoading: false,
      usuario_selecionado: "",
      acoes_usuario: [],
      acao_selecionada: "",
      contato_selecionado: "",
      contatos: "",
      promotor_selecionado: "",
      campanha_selecionada: "",
      url: ""
    };
  },

  mounted() {
    console.log("MOUNTEDD");
    console.log(this.total_usuarios);
    console.log(this.total_listas);
    console.log(this.total_campanhas);
    console.log(this.ultimos_cadastros);
  },

  methods: {

    gerarLink() {
      this.url = process.env.MIX_VUE_APP_ENDPOINT_INDICAZAP + "/indica/" + this.promotor_selecionado.telefone + "/" + this.campanha_selecionada + "/" + this.promotor_selecionado.id
    },

    copiarLink() {
      let that = this;

      this.$copyText(this.url).then(
        function (e) {
          console.log(e);
          that.showSuccessMessage("Dados copiados!");
        },
        function (e) {
          alert("Can not copy");
          console.log(e);
        }
      );
    },

    selecionarPromotor(promotor) {
      this.promotor_selecionado = promotor;
      this.campanha_selecionada = "";
      this.url = "";
    },

    ExportExcel(type, fn, dl) {
      var elt = this.$refs.exportable_table;
      var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      return dl ?
        XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }) :
        XLSX.writeFile(wb, fn || (('Contatos ' + this.contato_selecionado.nome_dono + '.' || 'SheetJSTableExport.') + (type || 'xlsx')));
    },

    selecionarContato(contato) {
      this.contato_selecionado = contato;

      this.contatos = this.formatarListaContatos(contato.contatos);
    },

    selecionarUsuario(usuario) {
      this.usuario_selecionado = usuario;

      if (usuario.acoes_personalizadas) {
        this.acoes_usuario = JSON.parse(usuario.acoes_personalizadas);
      } else {
        this.acoes_usuario = [];
      }
    },

    formatarListaContatos(lista_contatos) {
      try {
        //console.log("Tentando validar");
        //console.log(lista_contatos);
        let jsonValido = JSON.parse(lista_contatos);

        //console.log("JS Válido");
        //console.log(jsonValido);

        if (jsonValido) {
          return jsonValido;
        } else {
          return null;
        }
      } catch (e) {
        console.log("Erro ao validar JSON");
        console.log(e);
        return null;
      }
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
