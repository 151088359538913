<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12">
        <div class="card card-success">
          <div class="card-header">
            <h3 class="card-title">Editar de Funil</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="exampleInputEmail1">Nome do Funil</label>
                  <input class="form-control" id="exampleInputEmail1" placeholder="Digite o nome do seu funil" v-model="funil_selecionado.nome" />
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button @click="updateFunil()" class="btn btn-sm btn-success">Salvar</button>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div class="col-md-12">
        <button @click="addMessage()" class="btn btn-sm btn-info">
          Adicionar Mensagem
          <span class="badge badge-warning">{{ mensagens.length }}</span>
        </button>
      </div>
      <br />
      <br />
      <div id="accordion" class="col-md-12">
        <div class="card card-info" v-for="(mensagem, indice) in mensagens" :key="mensagem.id">
          <div class="card-header" data-toggle="collapse" :href="'#collapse' + indice">
            <input
              v-if="!mensagem.editar_nome"
              readonly="true"
              style="background-color: #17a2b8; color: white; border: none"
              class="card-title"
              v-model="mensagem.nome"
            />

            <button v-if="!mensagem.editar_nome" @click="editarNome(mensagem)" class="btn btn-sm btn-info">
              <i class="fa fa-cog"></i>
            </button>

            <button v-if="mensagem.editar_nome" @click="editarNome(mensagem)" class="btn btn-sm btn-info">
              <i class="fa fa-check"></i>
            </button>

            <input v-if="mensagem.editar_nome" class="card-title" v-model="mensagem.nome" />

            <div class="card-tools">
              <button style="color: white" @click="deleteMessage(mensagem)" class="btn btn-sm">
                <i class="fa fa-trash"></i>
              </button>

              <span class="badge badge-warning">{{ mensagem.resposta_esperada.length }}</span>
            </div>
          </div>
          <div :id="'collapse' + indice" class="collapse" data-parent="#accordion">
            <div class="card-body">
              <div class="row" style="padding: 10px 10px 10px 10px">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="exampleSelectRounded0">Tipo de mensagem</label>
                    <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="mensagem.tipo_envio">
                      <option value="texto">Texto</option>
                      <option value="imagem">Imagem</option>
                      <option value="video">Video</option>
                      <option value="link">Link</option>
                      <option value="voz">Voz</option>
                      <option value="botoes">Botoes</option>
                      <option value="botoes_com_imagem">Botoes + imagem</option>
                    </select>
                  </div>

                  <!-- ENVIO DE BOTÕES -->
                  <div v-if="mensagem.tipo_envio == 'botoes' || mensagem.tipo_envio == 'botoes_com_imagem'">
                    <div class="form-group" v-if="mensagem.tipo_envio == 'botoes_com_imagem'">
                      <label>URL imagem</label>
                      <input style="width: 100%" type="text" v-model="mensagem.url_imagem_botao" class="form-control" />
                    </div>

                    <div class="form-group">
                      <label>Saudação</label>
                      <input style="width: 100%" type="text" v-model="mensagem.saudacao" class="form-control" />
                    </div>

                    <div class="form-group">
                      <label>Texto</label>
                      <textarea
                        class="form-control"
                        rows="3"
                        placeholder="Digite a mensagem que deseja enviar ..."
                        v-model="mensagem.texto_botao"
                      ></textarea>
                    </div>

                    <div class="form-group">
                      <label>Rodapé</label>
                      <input style="width: 100%" type="text" v-model="mensagem.rodape" class="form-control" />
                    </div>

                    <div class="form-group">
                      <label for="exampleSelectRounded0">Quantidade de botões</label>
                      <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="mensagem.qtd_botoes">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="exampleSelectRounded0">Tipo de botão</label>
                      <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="mensagem.tipo_botao">
                        <option value="url">Url</option>
                        <option value="texto">Texto</option>
                      </select>
                    </div>

                    <div class="row">
                      <div class="col-sm-12 col-md-4" v-if="mensagem.qtd_botoes >= 1">
                        <div class="form-group">
                          <label>Botão 01</label>
                          <input style="width: 100%" type="text" v-model="mensagem.botao_1_texto" class="form-control" />
                        </div>
                        <div class="form-group" v-if="mensagem.tipo_botao == 'url'">
                          <label>Url botão 01</label>
                          <input style="width: 100%" type="text" v-model="mensagem.botao_1_valor" class="form-control" />
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-4" v-if="mensagem.qtd_botoes >= 2">
                        <div class="form-group">
                          <label>Botão 02</label>
                          <input style="width: 100%" type="text" v-model="mensagem.botao_2_texto" class="form-control" />
                        </div>
                        <div class="form-group" v-if="mensagem.tipo_botao == 'url'">
                          <label>Url botão 02</label>
                          <input style="width: 100%" type="text" v-model="mensagem.botao_2_valor" class="form-control" />
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-4" v-if="mensagem.qtd_botoes >= 3">
                        <div class="form-group">
                          <label>Botão 03</label>
                          <input style="width: 100%" type="text" v-model="mensagem.botao_3_texto" class="form-control" />
                        </div>
                        <div class="form-group" v-if="mensagem.tipo_botao == 'url'">
                          <label>Url botão 03</label>
                          <input style="width: 100%" type="text" v-model="mensagem.botao_3_valor" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="mensagem.tipo_envio != 'voz' && mensagem.tipo_envio != 'botoes' && mensagem.tipo_envio != 'botoes_com_imagem'">
                    <div class="form-group">
                      <label>Mensagem</label>
                      <textarea
                        class="form-control"
                        rows="3"
                        placeholder="Digite a mensagem que deseja enviar ..."
                        v-model="mensagem.texto"
                      ></textarea>
                    </div>
                  </div>

                  <div v-if="mensagem.tipo_envio == 'imagem'">
                    <div class="form-group">
                      <label for="imageToSend">Url da imagem</label>
                      <textarea class="form-control" placeholder="Url da imagem a ser enviada" v-model="mensagem.url" id="imageToSend" rows="3" />
                      <a href="/gallery" target="_blank" style="font-size: 15px">Não possui url? Busque na sua galeria.</a>
                    </div>
                    <div class="form-group">
                      <div v-if="mensagem.url" class="form-check">
                        <input v-model="preview" type="checkbox" class="form-check-input" id="preview" />
                        <label class="form-check-label" for="preview">Previsualizar imagem?</label>
                      </div>
                    </div>
                    <div v-if="preview" class="form-check">
                      <img id="qrCode" :src="mensagem.url" style="min-width: 300px; min-height: 200px; max-width: 300px; max-height: 200px" />
                    </div>
                  </div>

                  <div v-if="mensagem.tipo_envio == 'video'">
                    <div class="form-group">
                      <label for="imageToSend">Url do vídeo</label>

                      <textarea class="form-control" placeholder="URL do vídeo a ser enviado" v-model="mensagem.url" id="imageToSend" rows="3" />

                      <a href="/gallery" target="_blank" style="font-size: 15px">Não possui url? Busque na sua galeria.</a>
                    </div>
                    <div class="form-group">
                      <div v-if="mensagem.url" class="form-check">
                        <input v-model="preview" type="checkbox" class="form-check-input" id="preview" />
                        <label class="form-check-label" for="preview">Previsualizar vídeo?</label>
                      </div>
                    </div>
                    <div v-if="preview" class="form-check">
                      <video v-if="preview" controls width="250" height="150">
                        <source :src="mensagem.url" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>

                  <div v-if="mensagem.tipo_envio == 'voz'">
                    <div class="form-group">
                      <label for="imageToSend">Url</label>
                      <br />
                      <textarea
                        class="form-control"
                        placeholder="Url do áudio a ser enviado"
                        v-model="mensagem.url"
                        id="imageToSend"
                        rows="4"
                        cols="40"
                      />
                      <a href="/gallery" target="_blank" style="font-size: 15px">Não possui url? Busque na sua galeria.</a>

                      <br />
                      <video v-if="url_to_send" controls width="250" height="150" poster="{{ asset('/dist/img/whats-logo.png') }}">
                        <source :src="url_to_send" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>

                  <div v-if="tipo_envio == 'link'">
                    <div class="form-group">
                      <label for="imageToSend">Url:</label>
                      <input
                        placeholder="Url a ser enviada"
                        style="width: 100%"
                        type="text"
                        id="phone"
                        nome="phone"
                        v-model="mensagem.url"
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="form-check">
                      <input v-model="mensagem.aguardar_resposta" type="checkbox" class="form-check-input" id="preview" />
                      <label class="form-check-label" for="preview">Aguardar resposta</label>
                    </div>
                  </div>

                  <div v-if="!mensagem.aguardar_resposta">
                    <div class="form-group">
                      <div class="form-check">
                        <input v-model="mensagem.fim_funil" type="checkbox" class="form-check-input" id="preview" />
                        <label class="form-check-label" for="preview">Fim Funil</label>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Tempo para envio (em segundos)</label>
                      <input class="form-control" id="exampleInputEmail1" placeholder="Ex: 10" v-model="mensagem.tempo" />
                    </div>

                    <div class="form-group" v-if="mensagens.length > 1">
                      <label>Próxima mensagem</label>
                      <select class="form-control" v-model="mensagem.proxima_tag">
                        <option v-for="mensagem in mensagens" :key="mensagem.tag" :value="mensagem.tag">
                          {{ mensagem.nome }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="mensagem.aguardar_resposta" class="row" style="padding: 10px 10px 10px 10px">
                <button style="margin: 5px 5px 0px 10px; font-size: 12px" @click="addRespostaEsperada(mensagem)" class="btn btn-sm btn-danger">
                  <i class="fa fa-plus"></i>
                  Resposta Esperada
                  <span class="badge badge-warning">{{ mensagem.resposta_esperada.length }}</span>
                </button>
              </div>

              <div v-if="mensagem.aguardar_resposta" class="row" style="padding: 10px 10px 10px 10px">
                <div class="col-sm-12 col-md-4" v-for="(resposta, indice) in mensagem.resposta_esperada" :key="resposta.id">
                  <div class="card card-primary">
                    <div class="card-header">
                      <h3 class="card-title">Resposta Esperada {{ indice + 1 }}</h3>
                    </div>
                    <div class="card-body" style="padding: 10px 10px 10px 10px">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label for="exampleInputEmail1">Resposta Esperada</label>
                            <input class="form-control" id="exampleInputEmail1" placeholder="Ex: Sim" v-model="resposta.resposta_esperada" />
                          </div>

                          <div class="form-group">
                            <label>Resposta</label>
                            <textarea
                              v-bind:class="{
                                'form-control': !resposta.message_error,
                                'form-control is-invalid': resposta.message_error,
                              }"
                              @blur="validateResposta(resposta)"
                              rows="3"
                              placeholder="Digite a mensagem que deseja enviar ..."
                              v-model="resposta.resposta"
                            ></textarea>
                          </div>

                          <div class="form-group" v-if="mensagens.length > 1">
                            <label>Próxima mensagem</label>
                            <select class="form-control" v-model="resposta.proxima_tag" @change="buscarLeads()">
                              <option v-for="mensagem in mensagens" :key="mensagem.tag" :value="mensagem.tag">
                                {{ mensagem.nome }}
                              </option>
                            </select>
                          </div>

                          <div class="form-group">
                            <div class="form-check">
                              <input v-model="resposta.bloquear_contato" type="checkbox" class="form-check-input" id="bloquear" />
                              <label class="form-check-label" for="bloquear">Bloquear Contato</label>
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="form-check">
                              <input v-model="resposta.enviar_contato" type="checkbox" class="form-check-input" id="enviar" />
                              <label class="form-check-label" for="enviar">Enviar Contato</label>
                            </div>
                          </div>

                          <div class="form-group" v-if="planilhas && planilhas.length">
                            <div class="form-check">
                              <input v-model="resposta.enviar_planilha" type="checkbox" class="form-check-input" id="planilha" />
                              <label class="form-check-label" for="planilha">Enviar para planilha</label>
                            </div>
                          </div>

                          <div class="form-group" v-if="resposta.enviar_planilha">
                            <label>Planilha</label>
                            <select class="form-control" @change="selecionarPlanilha(resposta)" v-model="resposta.planilha_selecionada">
                              <option v-for="planilha in planilhas" :key="planilha" :value="planilha">
                                {{ planilha.apelido }}
                              </option>
                            </select>
                          </div>

                          <div class="form-group">
                            <div class="form-check">
                              <input v-model="resposta.encerrar_funil" type="checkbox" class="form-check-input" id="encerrar" />
                              <label class="form-check-label" for="encerrar">Encerrar Funil</label>
                            </div>
                          </div>

                          <div class="form-group" v-if="acoes_personalizadas && acoes_personalizadas.length > 0">
                            <label>Ação personalizada</label>
                            <select class="form-control" v-model="resposta.acao_personalizada">
                              <option value=""></option>
                              <option v-for="acao in acoes_personalizadas" :key="acao" :value="acao">
                                {{ acao }}
                              </option>
                            </select>
                          </div>

                          <div class="form-group" v-if="resposta.enviar_contato">
                            <label for="exampleInputEmail1">Telefone/Grupo</label>
                            <input class="form-control" id="exampleInputEmail1" v-model="resposta.contato" />
                          </div>

                          <div>
                            <button
                              @click="deleteRespostaEsperada(mensagem.resposta_esperada, indice)"
                              class="btn btn-outline-danger btn-sm btn-block"
                            >
                              <i class="fa fa-trash"></i>
                              Deletar Resposta
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="mensagem.aguardar_resposta" style="padding: 10px 10px 10px 10px">
                <div class="form-group">
                  <label>Resposta Padrão</label>
                  <textarea
                    class="form-control"
                    rows="3"
                    placeholder="Digite a resposta padrão que deseja enviar ..."
                    v-model="mensagem.resposta_padrao"
                  ></textarea>
                </div>
              </div>

              <div class="form-group">
                <label>Importar respostas esperadas</label>
                <input class="form-control" type="file" @change="selecionarRespostas" ref="file" />

                <button @click="importar(mensagem)" class="btn btn-sm btn-info">
                  <i class="fa fa-plus"></i>
                  Importar
                </button>
              </div>

              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: ["funil", "my_container", "usuario", "planilhas"],

  components: { Loading },

  mixins: [wppController, sweetAlert, Swal],

  data() {
    return {
      container: {},
      nome_envio: null,
      mesage_to_send: "",
      url_to_send: null,
      isConnected: false,
      send_to_list: false,
      add_to_funil: false,
      list_break: "CSV",
      listToSend: null,
      contacts: [],
      preview: false,
      tipo_envio: "texto",
      mensagens: [],
      resposta_padrao: "",
      funil_selecionado: "",
      isLoading: false,
      arquivoRespostas: "",
      planilhas_cadastradas: [],
      acoes_personalizadas: [],
    };
  },

  mounted() {
    this.container = this.my_container;

    if (this.usuario.acoes_personalizadas) {
      this.acoes_personalizadas = this.validateJSON(this.usuario.acoes_personalizadas);
      console.log("Ações");
      console.log(this.acoes_personalizadas);
      console.log(this.usuario.acoes_personalizadas);
    }

    console.log(this.funil);

    this.funil_selecionado = this.funil.funil;

    this.inicializarMensagens();

    for (let i = 0; i < this.mensagens.length; i++) {
      if (this.mensagens[i].tipo_envio == "botoes" || this.mensagens[i].tipo_envio == "botoes_com_imagem") {
        let json = JSON.parse(this.mensagens[i].texto);

        console.log("JSON");
        console.log(json);

        if (this.mensagens[i].tipo_envio == "botoes_com_imagem") {
          this.mensagens[i].url_imagem_botao = json.url_imagem_botao;
        }

        this.mensagens[i].tipo_botao = json.tipo_botao;
        this.mensagens[i].qtd_botoes = json.qtd_botoes;
        this.mensagens[i].texto_botao = json.texto_botao;
        this.mensagens[i].saudacao = json.saudacao;
        this.mensagens[i].rodape = json.rodape;

        if (this.mensagens[i].qtd_botoes >= 1) {
          this.mensagens[i].botao_1_texto = json.botoes[0].botao_texto;
          this.mensagens[i].botao_1_valor = json.botoes[0].botao_valor;
        }

        if (this.mensagens[i].qtd_botoes >= 2) {
          this.mensagens[i].botao_2_texto = json.botoes[1].botao_texto;
          this.mensagens[i].botao_2_valor = json.botoes[1].botao_valor;
        }

        if (this.mensagens[i].qtd_botoes >= 3) {
          this.mensagens[i].botao_3_texto = json.botoes[2].botao_texto;
          this.mensagens[i].botao_3_valor = json.botoes[2].botao_valor;
        }
      }
    }
  },

  methods: {
    formatarBotoes() {
      for (let i = 0; i < this.mensagens.length; i++) {
        if (this.mensagens[i].tipo_envio == "botoes" || this.mensagens[i].tipo_envio == "botoes_com_imagem") {
          let json = {
            qtd_botoes: this.mensagens[i].qtd_botoes,
            tipo_botao: this.mensagens[i].tipo_botao,
            texto_botao: this.mensagens[i].texto_botao,
            saudacao: this.mensagens[i].saudacao,
            rodape: this.mensagens[i].rodape,
          };

          if (this.mensagens[i].tipo_envio == "botoes_com_imagem") {
            json.url_imagem_botao = this.mensagens[i].url_imagem_botao;
          }

          let botoes = [];

          if (this.mensagens[i].qtd_botoes >= 1) {
            botoes.push({ botao_texto: this.mensagens[i].botao_1_texto, botao_valor: this.mensagens[i].botao_1_valor });
          }
          if (this.mensagens[i].qtd_botoes >= 2) {
            botoes.push({ botao_texto: this.mensagens[i].botao_2_texto, botao_valor: this.mensagens[i].botao_2_valor });
          }
          if (this.mensagens[i].qtd_botoes >= 3) {
            botoes.push({ botao_texto: this.mensagens[i].botao_3_texto, botao_valor: this.mensagens[i].botao_3_valor });
          }

          json.botoes = botoes;

          this.mensagens[i].texto = json;
        }
      }

      console.log(this.mensagens);
    },

    validateJSON(json) {
      console.log("VALIDANDO JSON");

      try {
        let jsonValido = JSON.parse(json);

        console.log("JS");
        console.log(jsonValido);

        if (jsonValido) {
          return jsonValido;
        } else {
          return false;
        }
      } catch (e) {
        console.log("JS invalido");
        console.log(e);
        return false;
      }
    },

    selecionarPlanilha(resposta) {
      resposta.spreadsheetId = resposta.planilha_selecionada.spreadsheetId;
      resposta.range = resposta.planilha_selecionada.range;

      console.log(resposta);
    },

    selecionarRespostas() {
      let file = this.$refs.file[0].files[0];

      this.arquivoRespostas = file;

      console.log(this.arquivoRespostas);
    },

    importar(mensagem) {
      this.isLoading = true;

      const reader = new FileReader();

      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

        data.shift();

        console.log("Mensagem");
        console.log(mensagem);

        console.log(data);

        //INICIALIZANDO RESPOSTAS
        for (let i = 0; i < data.length; i++) {
          mensagem.resposta_esperada.push({
            resposta_esperada: data[i][0],
            resposta: data[i][1],
            proxima_tag: "",
          });
        }
      };

      reader.readAsBinaryString(this.arquivoRespostas);

      this.isLoading = false;
    },

    getId() {},

    inicializarMensagens() {
      console.log("Inicializando");
      for (let i = 0; i < this.funil.mensagens.length; i++) {
        console.log("Mensagem " + i);
        this.mensagens.push(this.converterMensagem(this.funil.mensagens[i]));
      }

      console.log(this.mensagens);
    },

    converterMensagem(mensagem_banco) {
      let mensagem = {
        nome: mensagem_banco.nome,
        id: mensagem_banco.id,
        texto: mensagem_banco.texto,
        tipo_envio: mensagem_banco.tipo_media,
        url: mensagem_banco.url,
        tag: mensagem_banco.tag,
        proxima_tag: mensagem_banco.proxima_tag,
        fim_funil: mensagem_banco.fim_funil == 1,
        tempo: mensagem_banco.dif_minutos,
        aguardar_resposta: mensagem_banco.aguardar_resposta == 1,
        resposta_padrao: mensagem_banco.resposta_padrao,
        resposta_esperada: JSON.parse(mensagem_banco.resposta_esperada),
      };

      return mensagem;
    },

    editarNome(mensagem) {
      mensagem.editar_nome = !mensagem.editar_nome;
    },

    salvar() {
      console.log(this.mensagens);
    },

    addRespostaEsperada(mensagem) {
      mensagem.resposta_esperada.push({
        resposta_esperada: "",
        resposta: "",
        proxima_tag: "",
      });
    },

    addMessage() {
      let mensagem = {
        nome: "Mensagem " + (parseInt(this.mensagens.length, 10) + 1),
        id: this.mensagens.length + 1,
        tipo_envio: "texto",
        tag: this.getTag(),
        tempo: 0,
        aguardar_resposta: true,
        resposta_padrao: "",
        resposta_esperada: [
          {
            resposta_esperada: "",
            resposta: "",
            proxima_tag: "",
            encerrar_funil: false,
          },
        ],
        nova: true,
      };

      this.mensagens.push(mensagem);
    },

    getTag() {
      return this.container.url + "-" + new Date().getTime() + "#msg" + this.mensagens.length;
    },

    deleteMessage(mensagem) {
      let html = "Deseja deletar esta mensagem?";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!",
        })
        .then((result) => {
          if (result.value) {
            this.deletar(mensagem);
          }
        });
    },

    deletar(mensagem) {
      let data = {
        mensagem_id: mensagem.id,
      };

      console.log("Dados do funil");
      console.log(data);

      axios
        .post(`/funil/delete-message`, data)
        .then((response) => {
          const index = this.mensagens.indexOf(mensagem);

          if (index > -1) {
            this.mensagens.splice(index, 1);
          }

          let aux = 1;

          //REORDENANDO ARRAY
          this.mensagens.forEach((element) => {
            element.id = aux;
            aux++;
          });

          this.showSuccessMessage("Mensagem deletada!");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteRespostaEsperada(resposta_esperada, indice) {
      let html = "Deseja deletar esta resposta esperada?";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!",
        })
        .then((result) => {
          if (result.value) {
            resposta_esperada.splice(indice, 1);

            let aux = 1;

            //REORDENANDO ARRAY
            resposta_esperada.forEach((element) => {
              element.id = aux;
              aux++;
            });
          }
        });
    },

    validateResposta(resposta) {
      if (!resposta.resposta) {
        resposta.message_error = true;
      } else {
        resposta.message_error = false;
      }
    },

    validateInput(mensagem) {
      if (mensagem.tipo_envio == "texto" && !mensagem.mensagem) {
        mensagem.message_error = true;
      } else {
        mensagem.message_error = false;
      }

      if (mensagem.tipo_envio != "texto" && !mensagem.url) {
        mensagem.url_error = true;
      } else {
        mensagem.url_error = false;
      }
    },

    validateFields() {
      if (this.mensagens.length <= 0) {
        this.showErrorMessageWithButton("Ops...", "Você precisa adicionar uma mensagem!");
      } else {
        this.mesage_to_send = this.mensagens[0].mensagem;
        this.url_to_send = this.mensagens[0].url;
        this.tipo_envio = this.mensagens[0].tipo_envio;

        if (!this.nome_envio) {
          this.showErrorMessageWithButton("Ops...", "O nome do envio é obrigatório!");
        } else if (this.hasInputError()) {
          this.showErrorMessageWithButton("Ops...", "Preencha todos os campos corretamente!");
        } else if (!this.listToSend) {
          this.showErrorMessageWithButton("Ops...", "A lista de contatos não pode ficar em branco!");
        } else if (this.mensagens.length <= 1) {
          this.showErrorMessageWithButton("Ops...", "A sequência precisa ter pelo menos 2 mensagens!");
        } else {
          this.sendToList();
        }
      }
    },

    hasInputError() {
      let hasError = false;

      for (let i = 0; i < this.mensagens.length; i++) {
        if (this.mensagens[i].tipo_envio == "texto" && !this.mensagens[i].mensagem) {
          this.mensagens[i].message_error = true;
          hasError = true;
        }

        if (this.mensagens[i].tipo_envio != "texto" && !this.mensagens[i].url) {
          this.mensagens[i].url_error = true;
          hasError = true;
        }
      }

      return hasError;
    },

    sendToList() {
      this.contacts = [];

      let arrayContatos = [];

      console.log("ListBreak");
      console.log(this.list_break);

      if (this.list_break == "CSV") {
        arrayContatos = this.listToSend.split(",");
      } else if (this.list_break == "Excel") {
        arrayContatos = this.listToSend.split("\n");
      } else if (this.list_break == "JSON") {
        if (this.validateJSON(this.listToSend)) {
          arrayContatos = this.extractJSON();
        } else {
          this.showErrorWithButton(
            "Ops...",
            null,
            "JSON Inválido! Confira os dados e tente novamente.<br/><br/>" +
              " <div style='font-size: 12px'>Ficou com dúvidas? Você pode validar o seu JSON!<br/>" +
              "<br/><a style='color:green' target='_blank' href='https://jsonformatter.curiousconcept.com/'> Valide aqui o seu JSON </a>" +
              "</div>"
          );

          return 0;
        }
      }

      console.log(arrayContatos);

      for (let i = 0; i < arrayContatos.length; i++) {
        //FIX UNDEFINED ISSUE
        if (!arrayContatos[i]) {
          arrayContatos[i] = "84";
        }

        //REMOVENDO TODOS OS CARACTERES, EXCETO NÚMEROS
        let newPhone = "55" + arrayContatos[i].replace(/[^Z0-9]/g, "");

        let isOnTheList = false;

        for (let i = 0; i < this.contacts.length; i++) {
          if (this.contacts[i].phone == newPhone) {
            isOnTheList = true;
          }
        }
        if (!isOnTheList) {
          this.contacts.push({
            phone: newPhone,
          });
        }
      }

      console.log("Contatos limpos");
      console.log(this.contacts);

      this.index = 0;

      this.contEnviados = 0;

      this.confirmarEnvio("Confirmação", "Foram encontrados " + arrayContatos.length + " contato(s) deseja prosseguir com o envio?", () => {
        this.criarEnvio();
      });
    },

    updateFunil() {
      this.isLoading = true;

      let mensagens_criar = [];
      let mensagens = [];

      //FORMATANDO BOTOES
      this.formatarBotoes();

      for (let i = 0; i < this.mensagens.length; i++) {
        if (this.mensagens[i].nova) {
          mensagens_criar.push(this.mensagens[i]);
        } else {
          mensagens.push(this.mensagens[i]);
        }
      }

      let data = {
        id_funil: this.funil_selecionado.id,
        nome: this.funil_selecionado.nome,
        mensagens: mensagens,
        mensagens_criar: mensagens_criar,
      };

      console.log("Dados do funil");
      console.log(data);

      axios
        .post(`/funil/edit`, data)
        .then((response) => {
          console.log("Funil criado!");
          console.log(response);

          this.showSuccessMessage("Funil editado!");

          window.location.reload();

          callback(response.data.funil_id);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addMessageFlow() {
      for (let i = 0; i < this.mensagens.length; i++) {
        //CASO TENHA SÓ 1 MENSAGEM OU CASO SEJA O ÚLTIMO ELEMENTO DO ARRAY
        if (this.mensagens.length == 1 || i + 1 == this.mensagens.length) {
          this.mensagens[i].resposta_esperada = [
            {
              proxima_tag: "*",
              encerrar_funil: "true",
              //teste: "teste"
            },
          ];
        } else {
          this.mensagens[i].resposta_esperada = [
            {
              proxima_tag: this.mensagens[i + 1].tag,
              encerrar_funil: "false",
              //teste: "teste"
            },
          ];
        }
      }
    },

    extractJSON() {
      console.log("Listando json");
      console.log(this.listToSend);

      let json = JSON.parse(this.listToSend);

      console.log(json[0]);

      let arrayContatos = [];

      for (let i = 0; i < json.length; i++) {
        arrayContatos.push(json[i].Telefone);
      }

      return arrayContatos;
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
