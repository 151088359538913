<template>
  <div class="row" style="padding-top: 2%">
    <div class="col-md-12">
      <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />
      <button data-toggle="modal" data-target="#nova-mensagem-modal" type="button" class="btn btn-success">
        Nova Mensagem
        <i class="nav-icon far fa-plus-square"></i>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-0">
          <table class="table">
            <thead>
              <tr>
                <th style="width: 300px">Nome</th>
                <th style="width: 300px">Tag</th>
                <th style="width: 200px">Tipo</th>
                <th style="width: 200px">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="mensagem in mensagens_padrao" :key="mensagem.id">
                <td data-label="Nome">{{ mensagem.nome }}</td>
                <td data-label="Tag" v-if="mensagem.tag">{{ mensagem.tag }}</td>
                <td data-label="Tag" v-if="!mensagem.tag">-</td>
                <td data-label="Tipo" v-if="mensagem.tipo">{{ mensagem.tipo }}</td>
                <td data-label="Tipo" v-if="!mensagem.tipo">-</td>
                <td data-label="Ações">
                  <div>
                    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />
                    <button
                      @click="selecionarMensagem(mensagem)"
                      data-toggle="modal"
                      data-target="#modificar-modal"
                      type="button"
                      class="btn btn-primary btn-sm"
                    >
                      Gerenciar
                      <i class="nav-icon"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- MODAL EDITAR -->
  <div class="modal fade" id="modificar-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <form @submit.prevent>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            <h4>Editar mensagem</h4>
            <br />
            <div class="form-group">
              <label>Nome da mensagem</label>
              <input class="form-control" v-model="mensagem_selecionada.nome" />
            </div>

            <div class="form-group" v-if="usuario.tipo == 'Cliente Gmed' && mensagem_selecionada.tag == 'Agendados'">
              <label for="exampleInputEmail1">Mensagem</label>
              <br />
              <div class="row">
                <div style="margin-bottom: 2px" class="col-md-12 col-sm-12 col-12">
                  <textarea class="form-control" placeholder="Digite sua mensagem" v-model="mensagem_selecionada.mensagem" rows="4" cols="40" />
                </div>
                <div class="div-left col-md-4 col-sm-6 col-12">
                  <button @click="addTagEdit('<NomePaciente>')" class="tags btn btn-sm">Nome Paciente</button>
                </div>
                <div class="div-center col-md-4 col-sm-6 col-12">
                  <button @click="addTagEdit('<Data>')" class="tags btn btn-sm">Data Atendimento</button>
                </div>
                <div class="div-right col-md-4 col-sm-6 col-12">
                  <button @click="addTagEdit('<NomeMedico>')" class="tags btn btn-sm">Nome Médico</button>
                </div>
                <div class="div-left col-md-4 col-sm-6 col-12">
                  <button @click="addTagEdit('<Procedimento>')" class="tags btn btn-sm">Procedimento</button>
                </div>
                <div class="div-center col-md-4 col-sm-6 col-12">
                  <button @click="addTagEdit('<Horario>')" class="tags btn btn-sm">Horário</button>
                </div>
                <div class="div-right col-md-4 col-sm-6 col-12">
                  <button @click="addTagEdit('<Convenio>')" class="tags btn btn-sm">Convenio</button>
                </div>
                <div class="div-left col-md-4 col-sm-6 col-12">
                  <button @click="addTagEdit('<PrimeiroHorario>')" class="tags btn btn-sm">Primeiro Horário</button>
                </div>
              </div>
            </div>

            <div class="form-group" v-else>
              <label for="exampleInputEmail1">Mensagem</label>
              <br />
              <div class="row">
                <div style="margin-bottom: 2px" class="col-md-12 col-sm-12 col-12">
                  <textarea class="form-control" placeholder="Digite sua mensagem" v-model="mensagem_selecionada.mensagem" rows="4" cols="40" />
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="exampleSelectRounded0">Tag</label>
              <select v-model="mensagem_selecionada.tag" class="custom-select rounded-0">
                <option value=""></option>
                <option value="Aniversariantes">Aniversariantes</option>
                <option value="PDI">PDI</option>
                <option value="PDI 15 dias">PDI 15 dias</option>
                <option value="Agendados">Agendados</option>
                <option value="Agendados 1 dia antes">Agendados 1 dia</option>
                <option value="Agendados 2 dias antes">Agendados 2 dias</option>
                <option value="Agendados 3 dias antes">Agendados 3 dias</option>
                <option value="Agendados 7 dias antes">Agendados 7 dias</option>
                <option value="Atendidos da semana">Atendidos da semana</option>
                <option value="Exames 1 dia depois">Exames 1 dia depois</option>
                <option value="Exames 1 dia depois - RETORNO">Exames 1 dia depois - RETORNO</option>
              </select>
            </div>

            <div class="form-group">
              <label for="exampleSelectRounded0">Tipo</label>
              <select v-model="mensagem_selecionada.tipo" class="custom-select rounded-0">
                <option value="texto">Texto</option>
                <option value="imagem">Imagem</option>
                <option value="video">Vídeo</option>
                <option value="voz">Voz</option>
              </select>
            </div>

            <div class="form-group">
              <label>Url</label>
              <input class="form-control" v-model="mensagem_selecionada.url" />
            </div>

            <div class="form-group">
              <label for="exampleSelectRounded0">Funil</label>
              <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="mensagem_selecionada.funil_id">
                <option selected value=""></option>
                <option v-for="funil in funis" :key="funil.id" :value="funil.id">
                  {{ funil.nome }}
                </option>
              </select>
            </div>

            <button style="margin: 8px" @click="update()" class="btn btn-primary btn-sm">Modificar</button>

            <button @click="confirmaDelete()" class="btn btn-danger btn-sm">Deletar</button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!-- MODAL NOVA MENSAGEM -->
  <div class="modal fade" id="nova-mensagem-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <form @submit.prevent>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            <h4>Nova mensagem</h4>
            <br />
            <div class="form-group">
              <label>Nome da mensagem</label>
              <input class="form-control" placeholder="Digite o nome da mensagem" v-model="nome" />
            </div>

            <div class="form-group" v-if="usuario.tipo == 'Cliente Gmed' && tag == 'Agendados'">
              <label for="exampleInputEmail1">Mensagem</label>
              <br />
              <div class="row">
                <div style="margin-bottom: 2px" class="col-md-12 col-sm-12 col-12">
                  <textarea class="form-control" placeholder="Digite sua mensagem" v-model="mensagem" rows="4" cols="40" />
                </div>
                <div class="div-left col-md-4 col-sm-6 col-12">
                  <button @click="addTag('<NomePaciente>')" class="tags btn btn-sm">Nome Paciente</button>
                </div>
                <div class="div-center col-md-4 col-sm-6 col-12">
                  <button @click="addTag('<Data>')" class="tags btn btn-sm">Data Atendimento</button>
                </div>
                <div class="div-right col-md-4 col-sm-6 col-12">
                  <button @click="addTag('<NomeMedico>')" class="tags btn btn-sm">Nome Médico</button>
                </div>
                <div class="div-left col-md-4 col-sm-6 col-12">
                  <button @click="addTag('<Procedimento>')" class="tags btn btn-sm">Procedimento</button>
                </div>
                <div class="div-center col-md-4 col-sm-6 col-12">
                  <button @click="addTag('<Horario>')" class="tags btn btn-sm">Horário</button>
                </div>
                <div class="div-right col-md-4 col-sm-6 col-12">
                  <button @click="addTag('<Convenio>')" class="tags btn btn-sm">Convenio</button>
                </div>
              </div>
            </div>

            <div class="form-group" v-else>
              <label for="exampleInputEmail1">Mensagem</label>
              <br />
              <div class="row">
                <div style="margin-bottom: 2px" class="col-md-12 col-sm-12 col-12">
                  <textarea class="form-control" placeholder="Digite sua mensagem" v-model="mensagem" rows="4" cols="40" />
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="exampleSelectRounded0">Tag</label>
              <select v-model="tag" class="custom-select rounded-0">
                <option value=""></option>
                <option value="Aniversariantes">Aniversariantes</option>
                <option value="PDI">PDI</option>
                <option value="PDI 15 dias">PDI 15 dias</option>
                <option value="Agendados">Agendados</option>
                <option value="Agendados 1 dia antes">Agendados 1 dia</option>
                <option value="Agendados 2 dias antes">Agendados 2 dias</option>
                <option value="Agendados 3 dias antes">Agendados 3 dias</option>
                <option value="Agendados 7 dias antes">Agendados 7 dias</option>
                <option value="Laboratorio">Laboratorio</option>
                <option value="Atendidos da semana">Atendidos da semana</option>
                <option value="Exames 1 dia depois">Exames 1 dia depois</option>
                <option value="Exames 1 dia depois - RETORNO">Exames 1 dia depois - RETORNO</option>
              </select>
            </div>

            <div class="form-group">
              <label for="exampleSelectRounded0">Tipo</label>
              <select v-model="tipo" class="custom-select rounded-0">
                <option value="texto">Texto</option>
                <option value="imagem">Imagem</option>
                <option value="video">Vídeo</option>
                <option value="voz">Voz</option>
              </select>
            </div>

            <div class="form-group">
              <label>Url</label>
              <input class="form-control" v-model="url" />
            </div>

            <div class="form-group">
              <label for="exampleSelectRounded0">Funil</label>
              <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="funil_selecionado">
                <option selected value=""></option>
                <option v-for="funil in funis" :key="funil" :value="funil">{{ funil.nome }}</option>
              </select>
            </div>

            <button @click="cadastrar()" class="btn btn-primary btn-sm">Cadastrar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [wppController, sweetAlert, Swal],

  components: { Loading },

  props: ["mensagens_padrao", "usuario", "funis"],

  data() {
    return {
      container: {},
      nome: "",
      email: "",
      senha: "",
      isLoading: false,
      mensagem: "",
      tag: "",
      url: "",
      tipo: "",
      isLoading: false,
      mensagem_padrao: false,
      usuario_selecionado: "",
      nome_selecionado: "",
      tag_selecionada: "",
      mensagem_selecionada: "",
      mensagem_padrao_selecionada: "",
      id_selecionado: "",
      tipo_selecionado: "",
      url_selecionado: "",
      funil_selecionado: "",
      mensagem_selecionada: "",
    };
  },

  mounted() {
    console.log("MOUNTEDD");
    console.log(this.usuario);
  },

  methods: {
    confirmaDelete() {
      Swal.fire({
        title: "Deseja apagar sua mensagem?",
        text: "Sua Ação não poderá ser desfeita",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, deletar!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deletado!", "Sua mensagem foi deletada", "Successo");
          this.delete();
        }
      });
    },

    selecionarMensagem(mensagemselecionada) {
      console.log(mensagemselecionada);

      this.mensagem_selecionada = mensagemselecionada;
    },

    findFunil(id) {
      let indice = this.funis.findIndex((element) => element["id"] == id);

      return indice;
    },

    formatDate(date) {
      return moment(date).format("DD-MM-yyyy - HH:mm:ss");
    },

    addTag(tag) {
      this.mensagem = this.mensagem + tag;
    },

    addTagEdit(tag) {
      this.mensagem_selecionada = this.mensagem_selecionada + tag;
    },

    cadastrar() {
      let data = {
        nome: this.nome,
        usuario_id: this.usuarios,
        mensagem: this.mensagem,
        mensagem_padrao: this.mensagem_padrao,
        tag: this.tag,
        id: this.id,
        url: this.url,
        tipo: this.tipo,
        funil_id: this.funil_selecionado.id,
      };

      console.log("CRIANDO");
      console.log(data);

      axios
        .post(`/mensagens-padrao/create`, data)
        .then((response) => {
          console.log("Usuário criado!");
          console.log(response);

          this.showSuccessMessage("Mensagem criada!");

          window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    update() {
      let data = {
        nome: this.mensagem_selecionada.nome,
        mensagem: this.mensagem_selecionada.mensagem,
        mensagem_padrao: this.mensagem_selecionada.mensagem_padrao,
        tag: this.mensagem_selecionada.tag,
        id: this.mensagem_selecionada.id,
        url: this.mensagem_selecionada.url,
        tipo: this.mensagem_selecionada.tipo,
        funil_id: this.mensagem_selecionada.funil_id,
      };

      console.log("ATUALIZANDO");
      console.log(data);

      axios
        .post(`/mensagens-padrao/update`, data)
        .then((response) => {
          console.log("Usuário criado!");
          console.log(response);

          this.showSuccessMessage("Mensagem criada!");

          window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    delete() {
      let data = {
        //usuario_id: this.usuario_selecionado,
        id: this.id_selecionado,
      };

      console.log("DELETANDO...");
      console.log(data);
      axios
        .post(`/mensagens-padrao/delete`, data)
        .then((response) => {
          console.log("Usuário criado!");
          console.log(response);

          this.showSuccessMessage("Mensagem deletada!");

          window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    validateEmail() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        return true;
      } else {
        return false;
      }
    },

    generateKey() {
      return Math.random().toString(36).slice(2);
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}

.div-right {
  padding-left: 0px;
}

.div-center {
  padding-right: 0px;
  padding-left: 0px;
}

.div-left {
  padding-right: 0px;
}

@media (max-width: 600px) {
  .div-right {
    padding: 1px 1px 0px 1px;
  }

  .div-center {
    padding: 1px 1px 0px 1px;
  }

  .div-left {
    padding: 1px 1px 0px 1px;
  }
}

.tags {
  width: 100%;
  border-style: solid;
  border-color: black;
  color: white;
  background-color: gray;
  border-width: 2px;
  margin: 0.5px 0.5px 2px 0.5px;
  padding: 0px;
}
</style>
