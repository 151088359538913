<template>
  <div id="app" class="app">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

    <meta charset="utf8mb4" />
    <div class="form_wrapper" style="margin-top: -2%">
      <div class="form_container">
        <form @submit.prevent>
          <div class="row clearfix">
            <div class="col_half">
              <label>De</label>
              <div class="input_field">
                <input id="dataInicio" name="dataInicio" type="date" v-model="dataInicio" />
              </div>
            </div>
            <div class="col_half">
              <label>Até</label>
              <div class="input_field">
                <input id="dataFim" type="date" v-model="dataFim" />
              </div>
            </div>
          </div>
          <div class="row clearfix">
            <div class="col_three">
              <div class="input_field">
                <button v-on:click="buscarEnvios(7)" :class="period === 7 ? 'button-select-date-focused' : 'button-select-date'" id="dataFim">
                  7 dias
                </button>
              </div>
            </div>
            <div class="col_three">
              <div class="input_field">
                <button v-on:click="buscarEnvios(30)" :class="period === 30 ? 'button-select-date-focused' : 'button-select-date'" id="dataFim">
                  30 dias
                </button>
              </div>
            </div>
            <div class="col_three">
              <div class="input_field">
                <button v-on:click="buscarEnvios(60)" :class="period === 60 ? 'button-select-date-focused' : 'button-select-date'" id="dataFim">
                  60 dias
                </button>
              </div>
            </div>
          </div>
          <!--
          <div class="row clearfix">
            <div class="col_full">
              <div class="input_field">
                <button
                  v-on:click="calculatePDI()"
                  v-if="!showPdi"
                  class="button-select-date-focused"
                  id="pdi"
                >
                  Exibir somente PDI
                </button>
                <button
                  v-on:click="hidePdi()"
                  v-if="showPdi"
                  class="button-select-date-focused"
                  id="dataFim"
                >
                  Exibir todos os Envios
                </button>
              </div>
            </div>
          </div>
          -->

          <button class="button" type="button" v-on:click="searchEnviadasRelatorio()">Buscar</button>
        </form>
      </div>
    </div>

    <!-- FILTROS-->
    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="form-group">
          <label>Usuário</label>
          <select class="custom-select rounded-0" v-model="usuario_selecionado" @change="buscarContainers()">
            <option v-for="user in users" :key="user" :value="user">
              {{ user.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-sm-12 col-md-3">
        <div class="form-group">
          <label>Conexão</label>
          <select class="custom-select rounded-0" v-model="container_selecionado" @change="searchEnviadasRelatorio()">
            <option value=""></option>
            <option v-for="container in containers" :key="container" :value="container">
              {{ container.nome }}
            </option>
          </select>
        </div>
      </div>
      <!--
      <div class="col-sm-12 col-md-3">
        <div class="form-group">
          <label>Buscar por nome do envio</label>
          <input class="form-control" v-model="nome" />
        </div>
      </div>
      -->
    </div>

    <!-- TOTAIS -->
    <div class="row">
      <div class="col-md-4 col-sm-6 col-12">
        <div class="info-box">
          <span class="info-box-icon bg-info"><i class="far fa-envelope"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">Mensagens enviadas</span>
            <span class="info-box-number">{{ total_enviados }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="info-box">
          <span class="info-box-icon bg-success"><i class="fas fa-comments"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">Mensagens respondidas</span>
            <span class="info-box-number">{{ total_respostas }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="info-box">
          <span class="info-box-icon bg-danger"><i class="far fa-calendar-alt"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">Números inválidos</span>
            <span class="info-box-number">{{ total_invalidos }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- GRÁFICO -->
    <div class="chart-container">
      <canvas id="myChart"></canvas>
    </div>

    <!-- TABELA-->
    <table v-if="!isLoading && envios && envios.length" style="margin-top: 20px">
      <thead>
        <tr>
          <th scope="col" style="width: 30%">Nome</th>
          <th scope="col">Criado em</th>
          <th scope="col">Envios</th>
          <th scope="col">Respondidos</th>
          <th scope="col">Números inválidos</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="envio in envios" :key="envio.id">
          <td data-label="Nome" style="color: black">
            {{ envio.nome }}
          </td>
          <td data-label="Data" style="color: black">
            {{ formatDate(envio.created_at) }}
          </td>
          <td data-label="Enviados" style="color: black">
            {{ envio.calculo_enviados }}
          </td>
          <td data-label="Respondidos" style="color: black">
            {{ envio.calculo_respondidos }}
          </td>
          <td data-label="Números inválidos">
            {{ envio.calculo_invalidos }}
          </td>
        </tr>
      </tbody>
    </table>

    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import sweetAlert from "../controller/sweetAlert";

export default {
  name: "ShowMessages",

  props: ["users"],

  components: { Loading, sweetAlert },

  data() {
    return {
      messages: [],
      apiId: 0,
      listaSim: [],
      listaNao: [],
      listaOutros: [],
      listaRespondidos: [],
      listaSemResposta: [],
      listaNaoEnviados: [],
      dataEnvio: "",
      totalEnviadas: 0,
      container: {},
      resend: false,
      envio_selecionado: "",
      dataInicio: "",
      dataFim: "",
      period: "7",
      total_respostas: 0,
      total_enviados: 0,
      total_invalidos: 0,
      total_contatos: 0,
      showPdi: false,
      enviosPDI: [],
      total_sim: 0,
      total_nao: 0,
      total_outros: 0,
      total_pdi: 0,
      envios: [],
      isLoading: false,
      envio_selecionado: "",
      nome: "",
      arrayLabels: [],
      arrayValores: [],
      containers: [],
      myChart: "",
      user_selecionado: "",
      container_selecionado: "",
      relatorio_mensagens: "",
      usuario_selecionado: "",
    };
  },

  mounted() {
    this.envios = this.my_envios;

    console.log("Exibindo agora");
    console.log(this.my_container);
    console.log(this.envios);

    //BUSCANDO DATAS
    this.dataFim = new Date();
    this.dataInicio = new Date();
    this.dataInicio.setDate(this.dataInicio.getDate() - 7);

    this.dataInicio = this.formatSelectedDate(this.dataInicio);
    this.dataFim = this.formatSelectedDate(this.dataFim);

    //this.teste();
    //this.separarListas();
  },

  methods: {
    buscarContainers() {
      let data = {
        user_id: this.usuario_selecionado.id,
        inicio: 0,
        tamanho: 1000,
      };

      this.container_selecionado = null;

      this.searchEnviadasRelatorio();

      console.log("Buscando containers");
      console.log(data);

      axios
        .post(`/admin/connections/search`, data)
        .then((response) => {
          this.containers = response.data.containers;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    searchEnviadasRelatorio() {

      this.isLoading = true;

      if (this.myChart != "") {
        this.myChart.destroy();
        this.arrayValores = [];
        this.arrayLabels = [];
      }

      //CALCULANDO DIFERENCA DE DIAS

      let dataInicioDiff = moment(this.dataInicio);
      let dataFimDiff = moment(this.dataFim);

      var duration = moment.duration(dataFimDiff.diff(dataInicioDiff));
      var dias = duration.asDays();

      let aux_dataInicio = this.formatSelectedDate(this.dataInicio);
      let aux_dataFim = this.formatDateToSearch(this.dataFim);

      let data = {
        data_inicio: aux_dataInicio,
        data_fim: aux_dataFim,
        user_id: this.usuario_selecionado.id,
        quantidade: dias,
      };

      if (this.container_selecionado) {
        data.container_id = this.container_selecionado.id;
      }

      console.log("\n\nBuscando agora");
      console.log(data);

      axios
        .post(`/admin/relatorio/conexao/search`, data)
        .then((response) => {

          
      this.isLoading = false;

          console.log("Relatório envios");
          console.log(response.data.envios);
          console.log(response.data.relatorio_mensagens);

          this.envios = response.data.envios;

          this.total_enviados = 0;
          this.total_respostas = 0;
          this.total_invalidos = 0;

          for (let i = 0; i < this.envios.length; i++) {
            this.total_enviados += Number(this.envios[i].calculo_enviados);
            this.total_respostas += Number(this.envios[i].calculo_respondidos);
            this.total_invalidos += Number(this.envios[i].calculo_invalidos);
          }

          this.relatorio_mensagens = response.data.relatorio_mensagens;

          console.log("Status connection component ");
          console.log(this.relatorio_mensagens);

          let ctx = document.getElementById("myChart");

          let labels = ["22/06", "23/06", "24/06", "25/06", "26/06", "27/06", "28/06"];

          //RELATORIO

          let hoje = new Date();

          hoje.setDate(hoje.getDate() - dias);

          for (let i = 0; i <= dias; i++) {
            let data_buscar_array = moment(hoje).format("yyyy-MM-DD");

            //BUSCAR NO ARRAY
            let mensagens = 0;

            for (let j = 0; j < this.relatorio_mensagens.length; j++) {
              if (this.relatorio_mensagens[j].data_do_envio == data_buscar_array) {
                mensagens = this.relatorio_mensagens[j].qtd_mensagens;
              }
            }

            this.arrayValores.push(mensagens);

            this.arrayLabels.push(this.formatStringDate(hoje));

            hoje.setDate(hoje.getDate() + 1);
          }

          let data = {
            labels: this.arrayLabels,
            datasets: [
              {
                label: "Mensagens enviadas",
                data: this.arrayValores,
                borderColor: "#c73636",
                backgroundColor: "#40b860",
                pointStyle: "circle",
                pointRadius: 4,
                pointHoverRadius: 7,
              },
            ],
          };

          let config = {
            type: "line",
            data: data,
            options: {
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                title: {
                  display: false,
                  text: (ctx) => "Point Style: " + ctx.chart.data.datasets[0].pointStyle,
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            },
          };

          this.myChart = new Chart(ctx, config);
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    formatStringDate(date) {
      return moment(date).format("DD-MM");
    },

    ExportExcel(type, fn, dl) {
      var elt = this.$refs.exportable_table;
      var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(wb, fn || ("Resposta do envio " + this.envio_selecionado.id + "." || "SheetJSTableExport.") + (type || "xlsx"));
    },

    copiar(lista) {
      let that = this;

      let texto = "";

      for (let i = 0; i < lista.length; i++) {
        //console.log(lista[i].numero);
        texto = texto + lista[i].numero + "\n";
      }

      console.log(texto);

      this.$copyText(texto).then(
        function (e) {
          console.log(e);
          //that.showSuccessMessage("Lista copiada!");
        },
        function (e) {
          alert("Can not copy");
          console.log(e);
        }
      );
    },

    separarListas() {
      if (this.envios.length == 0) {
        this.isLoading = false;
      } else {
        for (let i = 0; i < this.envios.length; i++) {
          this.envios[i].envio.listaSemResposta = [];
          this.envios[i].envio.listaRespondidos = [];
          this.envios[i].envio.listaNaoEnviados = [];

          this.total_contatos = this.total_contatos + this.envios[i].envio.total_contatos;

          this.total_enviados = this.total_enviados + this.envios[i].envio.total_enviados;

          for (let j = 0; j < this.envios[i].numeros.length; j++) {
            //CASO A MENSAGEM TENHA SIDO RESPONDIDA
            if (this.envios[i].numeros[j].status_resposta && this.envios[i].numeros[j].status_resposta == "RESPONDIDA") {
              let json_respostas = this.validateJSON(this.envios[i].numeros[j].respostas);

              if (json_respostas) {
                this.envios[i].numeros[j].respostas = json_respostas;

                this.envios[i].envio.listaRespondidos.push(this.envios[i].numeros[j]);

                this.total_respostas = this.total_respostas + 1;
              }

              //CASO A MENSAGEM AINDA NÃO TENHA SIDO ENVIADA
            } else if (this.envios[i].numeros[j].status_envio == "AGUARDANDO") {
              this.envios[i].envio.listaNaoEnviados.push(this.envios[i].numeros[j]);
            } else {
              this.envios[i].envio.listaSemResposta.push(this.envios[i].numeros[j]);
            }
          }
        }

        console.log("Finalizado!!!!");
        console.log(this.envios);

        this.isLoading = false;
      }
    },

    validateJSON(json) {
      try {
        let jsonValido = JSON.parse(json);

        if (jsonValido) {
          return jsonValido;
        } else {
          console.log("JSON Inválido: ");
          console.log(json);

          return false;
        }
      } catch (e) {
        console.log("JSON Inválido: ");
        console.log(json);
        return false;
      }
    },

    formatarRespostasNumero(respostas) {
      let texto = "";

      console.log("Respostas");
      console.log(respostas);

      for (let i = 0; i < respostas.length; i++) {
        if (respostas[i].texto) {
          texto =
            texto +
            "<b style='padding: 1px 3px; background: #94C2ED; border-radius: 7px; color:white; font-weight:400;'>" +
            respostas[i].texto +
            "</b><br/>";
        } else {
          texto =
            texto +
            "<b style='padding: 1px 3px; background: #86BB71; border-radius: 7px; color:white; font-weight:400;'>Mídia: " +
            respostas[i].mimetype +
            "</b><br/>";
        }
      }

      return texto;
    },

    formatarDadosNumero(dados) {
      let texto = "";

      console.log("Dados");
      console.log(dados);

      for (var prop in dados) {
        texto = texto + "<b>" + prop + "</b>: " + dados[prop] + "<br/>";
      }

      //console.log("retornando");
      //console.log(texto);

      return texto;
    },

    formatarNumerosInvalidos() {
      if (!this.envio_selecionado.numeros_invalidos && this.envio_selecionado.numeros) {
        let array_invalidos = [];

        let json_meta_dados = undefined;

        if (this.envio_selecionado.meta_dados) {
          json_meta_dados = this.validateJSON(this.envio_selecionado.meta_dados);
          console.log("Meta dados validados");
        } else {
          console.log("Daados inválidos");
        }

        console.log("JSON");
        console.log(json_meta_dados);

        for (let i = 0; i < this.envio_selecionado.numeros.length; i++) {
          if (this.envio_selecionado.numeros[i].status_envio == "NÚMERO INVÁLIDO") {
            let numero = {
              numero: this.envio_selecionado.numeros[i].numero,
              status_envio: this.envio_selecionado.numeros[i].status_envio,
            };

            if (json_meta_dados) {
              let matchJSON = this.buscarNumeroNosMetaDados(json_meta_dados, numero.numero);

              if (matchJSON) {
                numero.dados = matchJSON;
              }
            }

            array_invalidos.push(numero);
          }
        }

        this.envio_selecionado.numeros_invalidos = array_invalidos;
      }
    },

    validateJSON(json) {
      try {
        let jsonValido = JSON.parse(json);

        if (jsonValido) {
          return jsonValido;
        } else {
          return false;
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },

    buscarNumeroNosMetaDados(json, numero) {
      console.log("BUSCANDO MATCH PARA " + numero);

      for (let i = 0; i < json.length; i++) {
        if (!json[i].buscado) {
          let telefone = "";

          if (json[i].Telefone) {
            telefone = json[i].Telefone;
          } else {
            telefone = json[i].telefone;
          }

          if (telefone) {
            telefone = telefone.replace(/[^Z0-9]/g, "");
            telefone = telefone.slice(telefone.length - 8);

            console.log(telefone);

            //VERIFICANDO COM 'INCLUDES' POIS O CÓDIGO ADICIONA O 55 NO NUMERO E NÃO NO JSON
            if (numero.includes(telefone)) {
              json[i].buscado = true;
              return json[i];
            }
          }
        }
      }

      console.log("Não achou");
    },

    selecionarEnvio(envio) {
      console.log("Envio selecionado");
      console.log(envio);
      this.envio_selecionado = envio.envio;

      this.formatarNumerosInvalidos();
    },

    teste() {
      console.log("teste " + this.envios.length);

      if (this.envios.length == 0) {
        this.isLoading = false;
      } else {
        for (let i = 0; i < this.envios.length; i++) {
          this.envios[i].envio.listaSemResposta = [];
          this.envios[i].envio.listaSim = [];
          this.envios[i].envio.listaNao = [];
          this.envios[i].envio.listaOutros = [];
          this.envios[i].envio.listaNaoEnviados = [];

          this.total_contatos = this.total_contatos + this.envios[i].envio.total_contatos;

          this.total_enviados = this.total_enviados + this.envios[i].envio.total_enviados;

          this.separateLists(this.envios[i]);
        }

        console.log("Finalizado!!!!");
        this.isLoading = false;
      }
    },

    hidePdi() {
      this.showPdi = false;
    },

    calculatePDI() {
      this.enviosPDI = [];
      this.showPdi = true;
      this.total_sim = 0;
      this.total_nao = 0;
      this.total_outros = 0;
      this.total_pdi = 0;

      console.log("CALCULANDO PDI " + this.showPdi);

      for (let i = 0; i < this.envios.length; i++) {
        if (this.envios[i].nome.includes("PDI")) {
          this.enviosPDI.push(this.envios[i]);
        }
      }

      for (let i = 0; i < this.enviosPDI.length; i++) {
        this.total_sim = this.total_sim + this.enviosPDI[i].listaSim.length;
        this.total_nao = this.total_nao + this.enviosPDI[i].listaNao.length;
        this.total_outros = this.total_outros + this.enviosPDI[i].listaOutros.length;
        this.total_pdi = this.total_pdi + this.enviosPDI[i].total_contatos;
      }
    },

    back() {
      this.resend = false;
    },

    showRespondidos(envio) {
      this.contacts = envio.listaOutros;

      //ADICIONANDO LISTA SIM
      for (let i = 0; i < envio.listaSim.length; i++) {
        this.contacts.push(envio.listaSim[i]);
      }

      //ADICIONANDO LISTA NAO
      for (let i = 0; i < envio.listaNao.length; i++) {
        this.contacts.push(envio.listaNao[i]);
      }

      this.resend = true;

      this.envio_selecionado = envio;
    },

    showSendTable(lista, envio) {
      this.resend = true;
      this.contacts = lista;
      this.envio_selecionado = envio;
    },

    buscarEnvios(number) {
      this.period = number;
      console.log(this.period);

      this.total_contatos = 0;
      this.total_enviados = 0;
      this.total_invalidos = 0;
      this.total_respostas = 0;

      this.total_sim = 0;
      this.total_nao = 0;
      this.total_outros = 0;
      this.total_pdi = 0;

      if (number > 0) {
        console.log("Entrou no IF");

        //DEFININDO DATA DE HOJE
        this.dataFim = new Date();
        this.dataFim = this.formatSelectedDate(this.dataFim);

        //DEFININDO DATA DE ACORDO COM O PERIODO
        let dataHoje = new Date();
        dataHoje.setDate(dataHoje.getDate() - number);
        this.dataInicio = this.formatSelectedDate(dataHoje);
      }

      let aux_dataInicio = this.formatSelectedDate(this.dataInicio);
      let aux_dataFim = this.formatDateToSearch(this.dataFim);

      let data = {
        data_inicio: aux_dataInicio,
        data_fim: aux_dataFim,
      };

      if (this.nome) {
        data.nome = this.nome.toUpperCase();
      }

      /*

      let url = `/database/send/list-sends/date`;


      if (this.showPdi) {
        url = `/database/send/list-sends/date/PDI`;
      }

      console.log("BUSCANDO NA URL ");
      console.log(url);
      */

      console.log("Buscando");
      console.log(data);

      this.isLoading = true;

      axios
        .post(`/send/search`, data)
        .then((response) => {
          console.log("Retorno do Get Envios");
          console.log(response.data);
          this.envios = response.data.envios;
          console.log("Envios - " + this.envios.length);

          if (this.envios.length == 0) {
            console.log("Entrou no IF ");

            this.isLoading = false;

            console.log("Loading? " + this.isLoading);
          } else {
            //this.teste();
            this.separarListas();
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    stopLoading() {
      let that = this;

      var myTimerPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject;

          that.loading = false;

          if (that.showPdi) {
            that.calculatePDI();
          }

          resolve();
        }, 3000);
      });
      myTimerPromise;
    },

    separateLists(meu_envio) {
      let envio = meu_envio.envio;
      let mensagens = meu_envio.mensagens;

      for (let i = 0; i < mensagens.length; i++) {
        if (mensagens[i].resposta1 != null && mensagens[i].resposta2 != null) {
          this.total_respostas = this.total_respostas + 1;

          if (envio.nome.includes("PDI")) {
            this.total_pdi = this.total_pdi + 1;
          }

          //ANALISANDO RESPOSTA 1 E 2
          if (mensagens[i].resposta1.toUpperCase().includes("SIM") || mensagens[i].resposta2.toUpperCase().includes("SIM")) {
            this.inserirNaLista(envio, envio.listaSim, mensagens[i]);
            this.total_sim = this.total_sim + 1;
          } else if (mensagens[i].resposta1.toUpperCase().includes("NÃO") || mensagens[i].resposta2.toUpperCase().includes("NÃO")) {
            this.inserirNaLista(envio, envio.listaNao, mensagens[i]);
            this.total_nao = this.total_nao + 1;
          } else {
            this.inserirNaLista(envio, envio.listaOutros, mensagens[i]);
          }
          //ANALISANDO SOMENTE RESPOSTA 1
        } else if (mensagens[i].resposta1 != null) {
          this.total_respostas = this.total_respostas + 1;

          if (mensagens[i].resposta1.toUpperCase().includes("SIM")) {
            this.inserirNaLista(envio, envio.listaSim, mensagens[i]);
            this.total_sim = this.total_sim + 1;
          } else if (mensagens[i].resposta1.toUpperCase().includes("NÃO")) {
            this.inserirNaLista(envio, envio.listaNao, mensagens[i]);
            this.total_nao = this.total_nao + 1;
          } else {
            this.inserirNaLista(envio, envio.listaOutros, mensagens[i]);
          }
        } else if (mensagens[i].status_da_resposta == "Número inválido") {
          this.total_invalidos = this.total_invalidos + 1;
          this.inserirNaLista(envio, envio.listaNaoEnviados, mensagens[i]);
        } else {
          this.inserirNaLista(envio, envio.listaSemResposta, mensagens[i]);
        }
      }
    },

    inserirNaLista(envio, lista, valor) {
      let adicionadoNaLista01 = this.verificarLista(envio.listaSim, valor);
      let adicionadoNaLista02 = this.verificarLista(envio.listaNao, valor);
      let adicionadoNaLista03 = this.verificarLista(envio.listaSemResposta, valor);
      let adicionadoNaLista04 = this.verificarLista(envio.listaOutros, valor);

      if (!adicionadoNaLista01 && !adicionadoNaLista02 && !adicionadoNaLista03 && !adicionadoNaLista04) {
        lista.push(valor);
      }
    },

    verificarLista(lista, valor) {
      let jaAdicionado = false;

      for (let i = 0; i < lista.length; i++) {
        if (lista[i].numero == valor.numero) {
          jaAdicionado = true;
        }
      }

      return jaAdicionado;
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },

    formatSelectedDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },

    formatDateToSearch(date) {
      return moment(date).format("yyyy-MM-DD 23:59:59");
    },
  },
};
</script>

<style scoped>
.message {
  padding: 1px 3px;
  background: #94c2ed;
  border-radius: 7px;
  color: white;
  font-weight: 400;
}

.numero {
  background: #b83312;
  border-radius: 7px;
  font-size: 12px;
  padding: 0px 2px 0px 2px;
  color: white;
}

a {
  cursor: pointer;
}

label {
  font-weight: normal;
  color: gray;
}

.stati {
  background: #fff;
  width: 12em;
  height: 4em;
  padding: 1em;
  margin: 1em 0;
  /* Safari */
  box-shadow: 0px 0.2em 0.3em rgb(0, 0, 0, 0.8);
}

.stati.bg-orange {
  background: rgb(243, 156, 18);
  color: white;
}

.stati.bg-nephritis {
  background: rgb(39, 174, 96);
  color: white;
}

.stati.bg-peter_river {
  background: rgb(52, 152, 219);
  color: white;
}

.stati.bg-green_sea {
  background: rgb(22, 160, 133);
  color: white;
}

.stati div {
  width: calc(100% - 3.5em);
  display: block;
  float: right;
  text-align: right;
}

.stati fa {
  font-size: 3.5em;
}

.stati div {
  width: calc(100% - 3.5em);
  display: block;
  float: right;
  text-align: right;
}

.stati div b {
  font-size: 2.2em;
  width: 100%;
  padding-top: 0px;
  margin-top: -0.2em;
  margin-bottom: -0.2em;
  display: block;
}

.stati div span {
  font-size: 1em;
  width: 100%;
  color: rgb(0, 0, 0, 0.8);
  display: block;
}

.stati.left div {
  float: left;
  text-align: left;
}

.stati.bg-turquoise {
  background: rgb(26, 188, 156);
  color: white;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table tr:nth-child(even) {
  background: #fff;
}

table tr:nth-child(odd) {
  background: #eee;
}

table.pdi tr:nth-child(even) {
  background: #fff;
}

table.pdi tr:nth-child(odd) {
  background: #80f0af;
}

table caption {
  font-size: 1.2em;
  margin: 0.1em 0 0.5em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.1em;
}

table th,
table td {
  padding: 0.2em;
  text-align: center;
  font-size: 0.9em;
}

table th {
  font-size: 0.7em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    padding: 0.4em;
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.9em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

.cursor {
  cursor: pointer;
}

.button-voltar {
  background-color: #b8ab39;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.button-select-date {
  background-color: white;
  color: black;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  width: 100%;
  margin: 4px 2px;
  cursor: pointer;
}

.button-select-date-focused {
  background-color: white;
  color: black;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  width: 100%;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.9);
  -moz-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.9);
  box-shadow: 0 0 3px 1px rgba(255, 169, 0, 0.9);
}

.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17;
  border-radius: 5px;
  width: 100%;
  margin: 4px 2px;
  cursor: pointer;
}

.button-exit {
  background-color: #a67e21;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

body {
  font-family: Verdana, Geneva, sans-serif;
  font-size: 14px;
  background: #f2f2f2;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
}

.form_wrapper {
  width: 500px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 15px;
  margin: 0% auto 0;
  position: relative;
  z-index: 1;
}

.form_container {
  padding: 15px;
}

.form_wrapper h2 {
  font-size: 1.5em;
  line-height: 1.5em;
  margin: 0;
}

.form_wrapper .title_container {
  text-align: center;
  margin: -15px -15px 15px;
  padding: 15px 0;
}

.form_wrapper h3 {
  font-size: 1.1em;
  font-weight: normal;
  line-height: 1.5em;
  margin: 0;
}

.form_wrapper .row {
  margin: 10px -15px;
}

.form_wrapper .row > div {
  padding: 0 15px;
  box-sizing: border-box;
}

.form_wrapper .col_half {
  width: 50%;
  float: left;
}

.form_wrapper .col_full {
  width: 100%;
  float: left;
}

.form_wrapper .col_three {
  width: 33%;
  float: left;
}

.form_wrapper .col_four {
  width: 25%;
  float: left;
}

.form_wrapper label {
  display: block;
  margin: 0 0 5px;
}

.form_wrapper .input_field,
.form_wrapper .textarea_field {
  position: relative;
}

.form_wrapper .input_field > span,
.form_wrapper .textarea_field > span {
  position: absolute;
  left: 0;
  top: 0;
  color: #333;
  height: 100%;
  text-align: center;
  width: 20px;
}

.form_wrapper .textarea_field > span {
  max-height: 35px;
}

.input_field {
  font-size: 12px;
}

.form_wrapper .input_field > span > i,
.form_wrapper .textarea_field > span > i {
  padding-top: 5px;
}

.form_wrapper input[type="text"],
.form_wrapper input[type="date"],
.form_wrapper input[type="email"],
.form_wrapper input[type="tel"],
textarea {
  width: 100%;
  padding: 6px 6px 6px 35px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  outline: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form_wrapper textarea {
  height: 8em;
}

.form_wrapper input[type="text"]:focus,
.form_wrapper input[type="date"]:focus,
.form_wrapper input[type="email"]:focus,
.form_wrapper input[type="tel"]:focus,
textarea:focus {
  -webkit-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
  -moz-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
  box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
  border: 1px solid #f5ba1a;
}

.form_wrapper input[type="submit"] {
  height: 50px;
  line-height: 50px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-size: 1.2em;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form_wrapper input[type="submit"]:hover,
.form_wrapper input[type="submit"]:focus {
  background: #daa106;
}

.credit {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 15px;
  color: #f5ba1a;
}

.credit a {
  color: #daa106;
}

@media (max-width: 600px) {
  .form_wrapper .col_half,
  .form_wrapper .col_three,
  .form_wrapper .col_four {
    width: 100%;
    float: none;
  }

  .form_wrapper label {
    margin: 10px 0;
  }
}
</style>
