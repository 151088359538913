<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

  <div
    @keydown.esc="cancelModal"
    @click.self="cancelModal"
    class="modal fade"
    id="edit-user-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <form @submit.prevent>
      <div class="modal-dialog" style="text-align: left">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            <h4>Editar usuário {{ user.id }}</h4>
            <div class="form-group">
              <label for="email">Nome</label>
              <input id="nome" type="text" class="form-control" v-model="formData.name" autocomplete="name" />

              <span style="color: red" v-for="error in v$.name.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="email">Email</label>
              <input id="email" type="email" class="form-control" v-model="formData.email" autocomplete="email" />

              <span style="color: red" v-for="error in v$.email.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">Porta</label>
              <input id="porta" type="porta" class="form-control" v-model="formData.porta" autocomplete="porta" />

              <span style="color: red" v-for="error in v$.porta.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">Servidor</label>
              <input id="porta" type="porta" class="form-control" v-model="formData.url" autocomplete="porta" />

              <span style="color: red" v-for="error in v$.url.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="user_type">Api Name (Integrações)</label>
              <input class="form-control" id="cnpj" type="text" v-model="formData.api_name" />
            </div>

            <div class="form-group">
              <label for="user_type">Tipo</label>
              <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="formData.tipo">
                <option value="Cliente Gmed">Cliente Gmed</option>
                <option value="Cliente Feegow">Cliente Feegow</option>
                <option value="Cliente Disparador">Cliente Disparador</option>
                <option value="Cliente Grupos">Cliente Grupos</option>
                <option value="Admin Indicazap">Admin Indicazap</option>
              </select>

              <span style="color: red" v-for="error in v$.tipo.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="user_type">Status</label>
              <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="formData.acesso_negado">
                <option :value="0">Ativo</option>
                <option :value="1">Bloqueado</option>
              </select>

              <span style="color: red" v-for="error in v$.acesso_negado.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="user_type">Visualizar apenas conexão</label>
              <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="formData.somente_conexao">
                <option :value="0">Não</option>
                <option :value="1">Sim</option>
              </select>

              <span style="color: red" v-for="error in v$.acesso_negado.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="user_type">Conexões</label>
              <input class="form-control" id="cnpj" type="text" v-model="formData.qtd_conexoes" />

              <span style="color: red" v-for="error in v$.qtd_conexoes.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">Nova senha</label>
              <input id="senha" type="senha" class="form-control" v-model="senha" autocomplete="senha" />
            </div>

            <button @click="update()" class="btn btn-success btn-sm">Salvar</button>

            <button
              id="close_edit_user"
              style="margin-left: 5px"
              class="btn btn-danger btn-sm"
              data-toggle="modal"
              data-target="#edit-user-modal"
              @click="setEditarUsuario(false)"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import useVueLidate from "@vuelidate/core";
import { required } from "../locales/i18n.js";
import { computed, reactive } from "vue";

export default {
  mixins: [wppController, sweetAlert, Swal],

  components: { Loading },

  props: ["user", "setEditarUsuario", "usuarioEditado"],

  data() {
    return {
      nome: "",
      email: "",
      senha: "",
      isLoading: false,
      my_container: {},
    };
  },

  setup() {
    const formData = reactive({
      name: "",
      email: "",
      porta: "",
      url: "",
      api_name: "",
      tipo: "",
      nova_senha: "",
      acesso_negado: "",
      qtd_conexoes: "",
      somente_conexao: "",
    });

    const rules = computed(() => {
      return {
        name: { required },
        email: { required },
        porta: { required },
        url: { required },
        tipo: { required },
        acesso_negado: { required },
        qtd_conexoes: { required },
        somente_conexao: { required },
      };
    });

    const v$ = useVueLidate(rules, formData);

    return { formData, v$ };
  },

  mounted() {
    this.formData.name = this.user.name;
    this.formData.email = this.user.email;
    this.formData.porta = this.user.porta;
    this.formData.url = this.user.url;
    this.formData.api_name = this.user.api_name;
    this.formData.tipo = this.user.tipo;
    this.formData.acesso_negado = this.user.acesso_negado;
    this.formData.qtd_conexoes = this.user.qtd_conexoes;
    this.formData.somente_conexao = this.user.somente_conexao;
  },

  methods: {
    cancelModal() {
      this.setEditarUsuario(false);
    },

    async update() {
      const result = await this.v$.$validate();

      if (result) {
        this.isLoading = true;

        let data = {
          user_id: this.user.id,
          nome: this.formData.name,
          senha: this.formData.nova_senha,
          email: this.formData.email,
          porta: this.formData.porta,
          api_name: this.formData.api_name,
          tipo: this.formData.tipo,
          url: this.formData.url,
          acesso_negado: this.formData.acesso_negado,
          qtd_conexoes: this.formData.qtd_conexoes,
          somente_conexao: this.formData.somente_conexao,
        };

        console.log("Editando agora");
        console.log(data);

        axios
          .post(`/admin/users/update`, data)
          .then((response) => {
            this.isLoading = false;

            this.showSuccessMessage("Usuário atualizado!");
            this.usuarioEditado();
          })
          .catch((error) => {
            this.showErrorMessageWithButton("Ops..", error.response.data);
            console.log(error.response.data);
          });
      }
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
