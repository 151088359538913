<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

  <div v-show="!ocultar_busca">
    <div style="text-align:center" class="col_full">
      <label style="font-size:25px;">Buscando {{ tipo_busca }} </label>
    </div>
    <br />

    <div class="row">

      <div class="col-12 col-sm-12 col-md-12"
        v-if="tipo_busca == 'atendidos_por_medico' && usuario.api_name == 'policlinica9'">
        <div class="form-group">
          <label for="exampleSelectRounded0">Buscar médicos</label>
          <select v-model="medico_selecionado" class="custom-select rounded-0" id="exampleSelectRounded0"
            @change="selecionarMedico()">
            <option v-for="medico in medicos" :key="medico" :value="medico">
              {{ medico.nome }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-4">
        <div class="form-group">
          <label for="exampleSelectRounded0">Tipo de busca</label>
          <select v-model="tipo_busca" class="custom-select rounded-0" id="exampleSelectRounded0" @change="resetar()">
            <option value="aniversariantes">Aniversariantes</option>
            <option value="agendados">Agendados</option>
            <option value="atendidos">Atendidos</option>
            <option value="nao_compareceram">Não compareceram</option>
            <option v-if="usuario.api_name == 'econ' || usuario.api_name == 'policlinica9'" value="atendidos_por_medico">
              Atendidos Por Médico</option>
          </select>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-2">
        <label v-if="tipo_busca == 'atendidos' || tipo_busca == 'atendidos_por_medico'">De</label>
        <label v-else>Data</label>
        <input
          style="display: block;  width: 100%;  padding: 0.375rem 0.75rem;  font-size: 0.9rem; font-weight: 400; line-height: 1.6;"
          id="dataInicio" name="dataInicio" type="date" v-model="dataInicio" />
      </div>

      <div class="col-12 col-sm-12 col-md-2">
        <label>Até</label>
        <input
          style="display: block;  width: 100%;  padding: 0.375rem 0.75rem;  font-size: 0.9rem; font-weight: 400; line-height: 1.6;"
          id="dataInicio" name="dataInicio" type="date" v-model="dataFim"
          :disabled="tipo_busca != 'atendidos' && tipo_busca != 'atendidos_por_medico'" />
      </div>

      <div class="col-12 col-sm-12 col-md-4" v-if="tipo_busca == 'atendidos_por_medico'">
        <div class="form-group">
          <label for="exampleInputEmail1">Id do médico</label>
          <input class="form-control" id="exampleInputEmail1" placeholder="Digite o id do médico" v-model="id_medico" />
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-12">
        <div class="form-group">
          <button class="btn btn-info" type="button" v-on:click="buscar()">Buscar</button>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-8">
        <div class="form-group" v-if="!contatos == ''">
          <h6 style="font-weight: bold">Total de contatos: {{ contatos_atuais.length }}</h6>
          <textarea v-model="contatos" rows="4" cols="40" style="width:100%" />
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-12 col-md-8">
      <div class="form-group">
        <div v-if="tipo_busca == 'agendados' && lista_medicos.length > 0" class="form-check">
          <input @change="selecionarFiltroMedico()" v-model="filtrar_por_medico" type="checkbox" class="form-check-input"
            id="exampleCheck1" />
          <label class="form-check-label" for="exampleCheck1">Filtrar por Médico?</label>
        </div>
      </div>
    </div>

    <div v-if="filtrar_por_medico">
      <div class="col-12 col-sm-12 col-md-8">
        <div class="form-check" v-for="(medico, index) in lista_medicos" :key="index" :value="medico">
          <input class="form-check-input" type="checkbox" :id="medico" :value="medico" v-model="medicos_selecionados" />
          <label class="form-check-label"> {{ medico }} </label>
        </div>
      </div>
      <br />
      <div class="col-12 col-sm-12 col-md-12">
        <div class="form-group">

          <!-- <h3> {{ string_medicos_filtrados }}</h3>-->

          <button style="background-color: #2c3e50" class="btn btn-info" type="button" @click="filter()">
            Filtrar
          </button>
        </div>
      </div>
      <br />
    </div>

    <!-- REMOVER REPETIDOS-->
    <div class="col-12 col-sm-12 col-md-8" v-if="tipo_busca == 'atendidos' && buscado">
      <div class="form-group">
        <div class="form-check">
          <input @click="removerRepetidos()" v-model="remover_repetidos" type="checkbox" class="form-check-input"
            id="exampleCheck1" />
          <label class="form-check-label" for="exampleCheck1">Remover repetidos</label>
        </div>
      </div>
    </div>

    <div v-if="buscado">
      <div class="col-12 col-sm-12 col-md-6">
        <div class="form-group">
          <label>Mensagens padrão</label>
          <select v-model="mensagem_padrao" class="custom-select">
            <option v-for="mensagem in mensagens_padrao" :key="mensagem" :value="mensagem"> {{ mensagem.nome }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <div v-if="tipo_busca == 'agendados' && lista_medicos.length > 0" class="form-check">
            <input v-model="nao_redirecionar" type="checkbox" class="form-check-input" id="exampleCheck1" />
            <label class="form-check-label" for="exampleCheck1">Não redirecionar</label>
          </div>
        </div>

        <div class="form-group">
          <div v-if="mensagem_padrao != ''" class="form-check">
            <input v-model="editar_antes_de_enviar" type="checkbox" class="form-check-input" id="exampleCheck1" />
            <label class="form-check-label" for="exampleCheck1">Editar antes de criar</label>
          </div>
        </div>

        <div class="form-group">
          <button style="background-color: #2c3e50" class="btn btn-info" type="button" @click="criarEnvio()">
            Criar Envio
          </button>
        </div>
      </div>
    </div>

    <br /> <br />
  </div>

  <div v-if="criar_envio">
    <create-send v-show="editar_antes_de_enviar" :usuario="usuario" :mensagens_padrao="mensagens_padrao" :funis="funis"
      :my_container="container" :contatos_para_envio="contatos_atuais_string" :id_mensagem_padrao="mensagem_padrao.id"
      :nao_redirecionar="nao_redirecionar" :aux_meta_dados="aux_meta_dados" :editar="editar_antes_de_enviar"
      :cancelar="cancelar" />
  </div>
</template>

<script>
import sweetAlert from "../controller/sweetAlert";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [sweetAlert],

  components: { Loading },

  props: ["container", "funis", "relatorio_mensagens", "mensagens_padrao", "usuario"],

  data() {
    return {
      tipo_busca: "aniversariantes",
      dataInicio: "",
      dataFim: "",
      contatos: "",
      array_contatos: [],
      dados_consulta: "",
      percorre_medicos: "",
      contatos1: "",
      indice: "",
      mensagem_padrao: "",
      isLoading: false,
      lista_medicos: [],
      filtrar_por_medico: false,
      buscado: false,
      contatos_para_envio: [''],
      medicos_selecionados: [],
      contatos_backup: [],
      contatos_atuais: [],
      criar_envio: false,
      contatos_atuais_string: "",
      meta_dados: "",
      aux_meta_dados: "",
      nao_redirecionar: false,
      id_medico: "",
      editar_antes_de_enviar: false,
      ocultar_busca: false,
      string_medicos_filtrados: "",
      remover_repetidos: false,
      medicos: "",
      medico_selecionado: ""
    };
  },

  mounted() {

    //DEFININDO DATA DE HOJE
    this.dataInicio = new Date();
    this.dataInicio = this.formatSelectedDate(this.dataInicio);

    //DEFININDO DATA DE HOJE
    this.dataFim = new Date();
    this.dataFim = this.formatSelectedDate(this.dataFim);

    this.url = this.usuario.api_name;

    this.buscarMedicos();
  },

  methods: {

    selecionarMedico() {
      this.id_medico = this.medico_selecionado.id
    },

    buscarMedicos() {

      let url = process.env.MIX_VUE_APP_ENDPOINT_GM + `/medicos/${this.url}`;

      axios
        .get(url)
        .then((response) => {
          console.log(" listados!");
          console.log(response.data);

          this.medicos = response.data.contatos;

        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    resetar() {
      this.editar_antes_de_enviar = false;
    },

    cancelar() {
      console.log("Cancelando");
      this.editar_antes_de_enviar = false;
      this.ocultar_busca = false;
      this.criar_envio = false;
    },

    removerRepetidos() {

      if (this.remover_repetidos) {
        console.log("Não Removendo");

        this.trataContatos(this.array_contatos);

        //console.log(this.contatos);
      } else {

        console.log("Removendo");

        this.aux_meta_dados = [];
        let new_list = [];
        let adicionados = [];

        for (let i = 0; i < this.array_contatos.length; i++) {
          if (!adicionados.includes(this.array_contatos[i].phone)) {
            new_list.push(this.array_contatos[i]);
            adicionados.push(this.array_contatos[i].phone);
            this.aux_meta_dados.push(this.array_contatos[i]);
          } else {
            console.log("Já adicionado");
          }
        }

        this.trataContatos(new_list);

        //console.log(this.contatos);
      }
    },

    filter() {
      this.criar_envio = false;

      let new_list = [];

      this.string_medicos_filtrados = "";
      this.lista_de_contatos2 = "";
      this.aux_meta_dados = [];

      for (let i = 0; i < this.array_contatos.length; i++) {
        for (let j = 0; j < this.medicos_selecionados.length; j++) {
          if (this.array_contatos[i].NomeMedico == this.medicos_selecionados[j]) {
            new_list.push(this.array_contatos[i]);
            this.aux_meta_dados.push(this.array_contatos[i]);
          }

          this.string_medicos_filtrados = this.medicos_selecionados[j] + ", ";
        }
      }

      console.log("Dados");
      console.log(this.aux_meta_dados);
      console.log(this.medicos_selecionados);

      this.string_medicos_filtrados = this.contatos.slice(0, -2);

      this.trataContatos(new_list);

    },

    selecionarFiltroMedico() {
      this.contatos_para_envio = [];
    },

    criarEnvio() {
      if (this.contatos_atuais.length <= 0) {
        this.showErrorWithButton("Ops...", "Lista de contatos vazia!");
      } else {

        if (this.mensagem_padrao == "") {
          this.editar_antes_de_enviar = true;
        }

        console.log("EDIT " + this.editar_antes_de_enviar);

        this.isLoading = true;

        let contatos = "";

        for (let i = 0; i < this.contatos_atuais.length; i++) {
          contatos = contatos + this.contatos_atuais[i].phone;

          if (i + 1 < this.contatos_atuais.length) {
            contatos = contatos + ",";
          }
        }

        this.contatos_atuais_string = contatos;

        this.criar_envio = false;
        this.ocultar_busca = true;

        setTimeout(() => {
          this.criar_envio = true;
          this.isLoading = false;
        }, 200);

      }
    },

    buscar() {

      this.isLoading = true;

      if (this.dataInicio == "") {
        this.showErrorMessageWithButton("Ops...", "Digite uma data");
      } else {

        this.lista_medicos = [];

        let url = "";

        if (this.tipo_busca == "agendados") {
          url = process.env.MIX_VUE_APP_ENDPOINT_GM + `/${this.tipo_busca}/${this.url}/${this.formatData(this.dataInicio)} `
        } else if (this.tipo_busca == "aniversariantes") {
          url = process.env.MIX_VUE_APP_ENDPOINT_GM + `/${this.tipo_busca}/${this.url}/${this.getDia(this.dataInicio)}/${this.getMes(this.dataInicio)}`;
        } else if (this.tipo_busca == "atendidos") {
          url = process.env.MIX_VUE_APP_ENDPOINT_GM + `/${this.tipo_busca}/${this.url}/${this.formatData(this.dataInicio)}/${this.formatData(this.dataFim)}`;
        } else if (this.tipo_busca == "atendidos_por_medico") {
          url = process.env.MIX_VUE_APP_ENDPOINT_GM + `/atendidos/medico/${this.id_medico}/${this.url}/${this.formatData(this.dataInicio)}/${this.formatData(this.dataFim)}`;
        } else if (this.tipo_busca == "nao_compareceram") {
          url = process.env.MIX_VUE_APP_ENDPOINT_GM + `/nao_compareceram/${this.url}/${this.formatData(this.dataInicio)}`;
        }

        console.log(url);

        axios
          .get(url)
          .then((response) => {
            console.log(this.tipo_busca + " listados!");
            console.log(response.data);
            this.dados_consulta = response.data.contatos;

            let contatos = response.data.contatos;

            if (this.tipo_busca == "nao_compareceram") {
              contatos = this.filtrarFaltosos(response.data);
            }

            this.array_contatos = contatos;
            this.contacts_backup = contatos;

            this.isLoading = false;

            this.trataContatos(contatos);
            this.trataJSON(contatos);

            //this.percorre();
            this.primeiro();
            this.buscado = true;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      }
    },

    filtrarFaltosos(dados) {

      console.log("Filtrando faltosos");

      let arrayFaltosos = [];

      for (let i = 0; i < dados.agendados.length; i++) {
        let indice = this.findAtendido(dados.atendidos, dados.agendados[i].Telefone);

        //CASO O AGENDADO NÃO ESTEJA NO ARRAY DE ATENDIDOS
        if (indice < 0) {
          arrayFaltosos.push(dados.agendados[i]);

          console.log("Não compareceu");
          console.log(dados.agendados[i]);
        }
      }

      return arrayFaltosos;
    },

    findAtendido(array, telefone) {

      console.log("Buscando " + telefone);

      for (let i = 0; i < array.length; i++) {
        if (array[i].Telefone && array[i].Telefone.includes(telefone)) {
          return i;
        }
      }

      return -1;
    },

    primeiro() {

      if (this.tipo_busca == "agendados") {
        for (let i = 0; i < this.dados_consulta.length; i++) {

          let indice = this.findMedico(this.dados_consulta[i].NomeMedico);

          this.addListaMedicos(this.dados_consulta[i].NomeMedico);

          let primeiroHorario = this.dados_consulta[indice].Horario;

          this.dados_consulta[i].PrimeiroHorario = primeiroHorario;
        }
      }

    },

    trataContatos(contatos) {

      this.contatos_atuais = [];
      this.contatos = "";

      for (let i = 0; i < contatos.length; i++) {

        if (!contatos[i].Telefone) {
          contatos[i].Telefone = "0000"
        }

        //LIMPANDO CONTATOS

        //REMOVENDO TODOS OS CARACTERES, EXCETO NÚMEROS
        contatos[i].phone = contatos[i].Telefone.replace(/[^Z0-9]/g, "");

        //SUBSTITUINDO BARRAS POR VÍRGULAS
        contatos[i].phone = this.replaceAll(contatos[i].phone, "/", ",");

        //ADICIONANDO DDD
        if (contatos[i].phone.length < 10) {
          contatos[i].phone = "84" + contatos[i].phone;
        }

        //REMOVENDO DDD DUPLICADO
        if (contatos[i].phone.substr(0, 4) == "8484") {
          contatos[i].phone = this.replaceAll(
            contatos[i].phone,
            "8484",
            "84"
          );
        }

        this.contatos = this.contatos + contatos[i].phone;

        if (i + 1 < contatos.length) {
          this.contatos = this.contatos + ",";
        }

        this.contatos_atuais.push(contatos[i]);

        //ADICIONANDO CÓDIGO DO PAÍS
        //this.contatos[i].phone = "55" + this.contatos[i].phone;

      }

      //REMOVENDO ÚLTIMA VÍRGULA
      this.contatos = this.contatos.slice(0, -1);

    },

    addListaMedicos(nome_medico) {
      let adicionado = false;

      for (let i = 0; i < this.lista_medicos.length; i++) {
        if (this.lista_medicos[i] == nome_medico) {
          adicionado = true;
        }
      }

      if (!adicionado) {
        this.lista_medicos.push(nome_medico);
      }
    },

    findMedico(nome) {
      let indice = this.dados_consulta.findIndex(element => element['NomeMedico'] == nome);

      return indice;
    },

    formatData(date) {
      return moment(date).format("yyyyMMDD");
    },

    trataJSON(contatos) {
      for (let i = 0; i < contatos.length; i++) {

        if (contatos[i].NomePaciente) {
          contatos[i].NomePaciente = contatos[i].NomePaciente.replace(/[^a-zA-Z záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]/g, '');

          //REMOVENDO TABS E ESPAÇOS EM BRANCO
          contatos[i].NomePaciente = contatos[i].NomePaciente.replace(/(^[,\s]+)|([,\s]+$)/g, '')
        } else if (contatos[i].Nome) {

          contatos[i].Nome = contatos[i].Nome.replace(/[^a-zA-Z záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]/g, '');

          //REMOVENDO TABS E ESPAÇOS EM BRANCO
          contatos[i].Nome = contatos[i].Nome.replace(/(^[,\s]+)|([,\s]+$)/g, '')
        }

        if (contatos[i].Telefone) {
          //REMOVENDO TODOS CARACTERES EXCETO NÚMEROS
          contatos[i].Telefone = contatos[i].Telefone.replace(/([^\d])+/gim, '')

          //REMOVENDO TABS E ESPAÇOS EM BRANCO
          contatos[i].Telefone = contatos[i].Telefone.replace(/(^[,\s]+)|([,\s]+$)/g, '')
        }

      }

      this.meta_dados = contatos;
      this.aux_meta_dados = contatos;
      console.log("META");
      console.log(this.meta_dados);
    },

    findPaciente(nome) {
      let indice = this.dados_consulta.findIndex(element => element['NomeMedico'] == nome);

      return indice;
    },

    replaceAll(string, search, replace) {
      return string.split(search).join(replace);
    },

    getDia(date) {
      return moment(date).format("DD");
    },

    getMes(date) {
      return moment(date).format("MM");
    },

    formatSelectedDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },

    formatStringDate(date) {
      return moment(date).format("DD-MM");
    },
  },
};
</script>

