<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

  <div>
    <div style="text-align:center" class="col_full">
      <label style="font-size:25px;">Buscando {{ tipo_busca }} </label>
    </div>
    <br />

    <div class="row">
      <div class="col-12 col-sm-12 col-md-4">
        <div class="form-group">
          <label for="exampleSelectRounded0">Tipo de busca</label>
          <select v-model="tipo_busca" class="custom-select rounded-0" id="exampleSelectRounded0">
            <option value="agendados">Agendados</option>
            <option value="atendidos">Atendidos</option>
          </select>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-4">
        <label>Data</label>
        <input style=" display: block; 
                    width: 100%; 
                    padding: 0.375rem 0.75rem; 
                    font-size: 0.9rem;
                    font-weight: 400;
                    line-height: 1.6;" id="dataInicio" name="dataInicio" type="date" v-model="dataInicio" />
      </div>

      <div class="col-12 col-sm-12 col-md-12">
        <div class="form-group">
          <button class="btn btn-info" type="button" v-on:click="buscar()">Buscar</button>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-8">
        <div class="form-group" v-if="!contatos == ''">
          <h6 style="font-weight: bold">Total de contatos: {{ contatos_atuais.length }}</h6>
          <textarea v-model="contatos" rows="4" cols="40" style="width:100%" />
        </div>
      </div>
    </div>

    <!--
      <div class="col-12 col-sm-12 col-md-8">
        <div class="form-group">
          <div v-if="tipo_busca == 'agendados' && lista_medicos.length > 0" class="form-check">
            <input @change="selecionarFiltroMedico()" v-model="filtrar_por_medico" type="checkbox"
              class="form-check-input" id="exampleCheck1" />
            <label class="form-check-label" for="exampleCheck1">Filtrar por Médico?</label>
          </div>
        </div>
      </div>
      -->

    <div v-if="filtrar_por_medico">
      <div class="col-12 col-sm-12 col-md-8">
        <div class="form-check" v-for="(medico, index) in lista_medicos" :key="index" :value="medico">
          <input class="form-check-input" type="checkbox" :id="medico" :value="medico" v-model="medicos_selecionados" />
          <label class="form-check-label"> {{ medico }} </label>
        </div>
      </div>
      <br />
      <div class="col-12 col-sm-12 col-md-12">
        <div class="form-group">
          <button style="background-color: #2c3e50" class="btn btn-info" type="button" @click="filter()">
            Filtrar
          </button>
        </div>
      </div>
      <br />
    </div>

    <div v-if="buscado">
      <div class="col-12 col-sm-12 col-md-6">
        <div class="form-group">
          <label>Mensagens padrão</label>
          <select v-model="mensagem_padrao" class="custom-select">
            <option v-for="mensagem in mensagens_padrao" :key="mensagem" :value="mensagem"> {{ mensagem.nome }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <div v-if="tipo_busca == 'agendados' && lista_medicos.length > 0" class="form-check">
            <input v-model="nao_redirecionar" type="checkbox" class="form-check-input" id="exampleCheck1" />
            <label class="form-check-label" for="exampleCheck1">Não redirecionar</label>
          </div>
        </div>

        <div class="form-group">
          <button style="background-color: #2c3e50" class="btn btn-info" type="button" @click="criarEnvio()">
            Criar Envio
          </button>
        </div>
      </div>
    </div>

    <div v-if="criar_envio">
      <create-send v-show="false" :usuario="usuario" :mensagens_padrao="mensagens_padrao" :funis="funis"
        :my_container="container" :contatos_para_envio="contatos_atuais_string" :id_mensagem_padrao="mensagem_padrao.id"
        :nao_redirecionar="nao_redirecionar" :aux_meta_dados="meta_dados" />
    </div>

    <br /> <br />
  </div>
</template>

<script>
import sweetAlert from "../controller/sweetAlert";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [sweetAlert],

  components: { Loading },

  props: ["container", "funis", "relatorio_mensagens", "mensagens_padrao", "usuario"],

  data() {
    return {
      tipo_busca: "agendados",
      dataInicio: "",
      dataFim: "",
      contatos: "",
      array_contatos: [],
      dados_consulta: "",
      percorre_medicos: "",
      contatos1: "",
      indice: "",
      mensagem_padrao: "",
      isLoading: false,
      lista_medicos: [],
      filtrar_por_medico: false,
      buscado: false,
      contatos_para_envio: [''],
      medicos_selecionados: [],
      contatos_backup: [],
      contatos_atuais: [],
      criar_envio: false,
      contatos_atuais_string: "",
      meta_dados: ""
    };
  },

  mounted() {
    console.log("aviso")
    console.log(this.mensagens_padrao[0].mensagem)

    //DEFININDO DATA DE HOJE
    this.dataInicio = new Date();
    this.dataInicio = this.formatSelectedDate(this.dataInicio);

    //DEFININDO DATA DE HOJE
    this.dataFim = new Date();
    this.dataFim = this.formatSelectedDate(this.dataFim);

    this.url = this.usuario.api_name;
  },

  methods: {

    filter() {
      this.criar_envio = false;

      let new_list = [];

      this.lista_de_contatos2 = "";

      for (let i = 0; i < this.array_contatos.length; i++) {
        for (let j = 0; j < this.medicos_selecionados.length; j++) {
          if (this.array_contatos[i].NomeMedico == this.medicos_selecionados[j]) {
            new_list.push(this.array_contatos[i]);
          }
        }
      }

      this.trataContatos(new_list);

    },

    selecionarFiltroMedico() {
      this.contatos_para_envio = [];
    },

    criarEnvio() {
      if (this.contatos_atuais.length <= 0) {
        this.showErrorWithButton("Ops...", "Lista de contatos vazia!");
      } else if (!this.mensagem_padrao || this.mensagem_padrao == "") {
        this.showErrorWithButton("Ops...", "Selecione uma mensagem padrão!");
      } else {

        this.isLoading = true;

        let contatos = "";

        for (let i = 0; i < this.contatos_atuais.length; i++) {
          contatos = contatos + this.contatos_atuais[i].phone;

          if (i + 1 < this.contatos_atuais.length) {
            contatos = contatos + ",";
          }
        }

        this.contatos_atuais_string = contatos;

        this.criar_envio = false;

        setTimeout(() => {
          this.criar_envio = true;
          this.isLoading = false;
        }, 200);

      }
    },

    buscar() {
      if (this.dataInicio == "") {
        this.showErrorMessageWithButton("Ops...", "Digite uma data");
      } else {

        this.isLoading = true;

        this.lista_medicos = [];

        let url = "";

        if (this.tipo_busca == "agendados") {
          url = process.env.MIX_VUE_APP_ENDPOINT_FE + `/${this.tipo_busca}/${this.url}/${this.formatData(this.dataInicio)}`
        } else if (this.tipo_busca == "aniversariantes") {
          url = process.env.MIX_VUE_APP_ENDPOINT_FE + `/${this.tipo_busca}/${this.url}/${this.getDia(this.dataInicio)}/${this.getMes(this.dataInicio)}`;
        } else if (this.tipo_busca == "atendidos") {
          url = process.env.MIX_VUE_APP_ENDPOINT_FE + `/${this.tipo_busca}/${this.url}/${this.formatData(this.dataInicio)}/${this.formatData(this.dataInicio)}`;
        }

        console.log(url);

        axios
          .get(url)
          .then((response) => {
            console.log(response.data);

            this.dados_consulta = response.data.contatos;

            this.array_contatos = response.data.contatos;
            this.contacts_backup = response.data.contatos;

            this.trataContatos(response.data.contatos);
            this.trataJSON(this.dados_consulta);

            //this.percorre();
            this.primeiro();
            this.buscado = true;

            this.isLoading = false;

          })
          .catch((error) => {
            console.log(error.data);
            this.showErrorMessage("Ops...", error);
            this.isLoading = false;
          });
      }
    },

    trataJSON(contatos) {

      let new_json = [];

      for (let i = 0; i < contatos.length; i++) {

        if (contatos[i].nome) {
          contatos[i].nome = contatos[i].nome.replace(/[^a-zA-Z záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]/g, '');

          //REMOVENDO TABS E ESPAÇOS EM BRANCO
          contatos[i].nome = contatos[i].nome.replace(/(^[,\s]+)|([,\s]+$)/g, '')
        } else if (contatos[i].nome) {

          contatos[i].nome = contatos[i].nome.replace(/[^a-zA-Z záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]/g, '');

          //REMOVENDO TABS E ESPAÇOS EM BRANCO
          contatos[i].nome = contatos[i].nome.replace(/(^[,\s]+)|([,\s]+$)/g, '')
        }

        if (contatos[i].celulares[0]) {
          //REMOVENDO TODOS CARACTERES EXCETO NÚMEROS
          contatos[i].celulares[0] = contatos[i].celulares[0].replace(/([^\d])+/gim, '')

          //REMOVENDO TABS E ESPAÇOS EM BRANCO
          contatos[i].celulares[0] = contatos[i].celulares[0].replace(/(^[,\s]+)|([,\s]+$)/g, '')
        }

        let new_contato = {
          id: contatos[i].id,
          nome: contatos[i].nome,
          telefone: contatos[i].celulares[0],
          nascimento: contatos[i].nascimento,
          sexo: contatos[i].sexo,
          documento: contatos[i].documento,
        }

        new_json.push(new_contato);

      }

      this.meta_dados = new_json;

      console.log(this.meta_dados);
    },

    trataContatos(contatos) {

      this.contatos_atuais = [];
      this.contatos = "";

      for (let i = 0; i < contatos.length; i++) {

        //REMOVENDO TODOS OS CARACTERES, EXCETO NÚMEROS
        contatos[i].phone = contatos[i].celulares[0].replace(/[^Z0-9]/g, "");

        console.log(i);

        this.contatos = this.contatos + contatos[i].phone;

        if (i + 1 < contatos.length) {
          this.contatos = this.contatos + ",";
        }

        let json_contato = {
          nome: contatos[i].nome,
          phone: contatos[i].phone,
        }

        this.contatos_atuais.push(json_contato);
      }

      this.contatos = this.contatos.slice(0, -1);

    },

    primeiro() {

      if (this.tipo_busca == "agendados") {
        for (let i = 0; i < this.dados_consulta.length; i++) {

          let indice = this.findMedico(this.dados_consulta[i].NomeMedico);

          this.addListaMedicos(this.dados_consulta[i].NomeMedico);

          let primeiroHorario = this.dados_consulta[indice].Horario;

          this.dados_consulta[i].primeiro = primeiroHorario;
        }
      }

    },

    addListaMedicos(nome_medico) {
      let adicionado = false;

      for (let i = 0; i < this.lista_medicos.length; i++) {
        if (this.lista_medicos[i] == nome_medico) {
          adicionado = true;
        }
      }

      if (!adicionado) {
        this.lista_medicos.push(nome_medico);
      }
    },

    findMedico(nome) {
      let indice = this.dados_consulta.findIndex(element => element['NomeMedico'] == nome);

      return indice;
    },

    formatData(date) {
      return moment(date).format("DD-MM-yyyy");
    },

    replaceAll(string, search, replace) {
      return string.split(search).join(replace);
    },

    getDia(date) {
      return moment(date).format("DD");
    },

    getMes(date) {
      return moment(date).format("MM");
    },

    formatSelectedDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },

    formatStringDate(date) {
      return moment(date).format("DD-MM");
    },
  },
};
</script>

