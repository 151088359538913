<template>
  <h1>Hello From VueJS! LIST!</h1>
  <button @click="show()" type="button"  class="btn btn-block btn-primary">Click</button>
</template>

<script>
export default {
  data() {
    return {};
  },

  mounted() {},

  methods: {
    show(essay) {
      console.log("Botão clicado");
    },
  },
};
</script>
