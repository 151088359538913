<template>
  <footer class="main-footer" style="bottom: 0; left: 0; position: fixed; right: 0; z-index: 1032; width: 100%">
    &copy; All rights reserved.
    <b style="margin-left: 5px">v1.1.2111</b>
    <span style="margin-left: 5px">
      Api:
      <b>{{ api_version }}</b>
    </span>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      api_version: "",
    };
  },

  mounted() {
    this.getApiVersion();
  },

  methods: {
    getApiVersion() {
      axios
        .get(process.env.MIX_VUE_APP_ENDPOINT + `3333/api_version`)
        .then((response) => {
          this.api_version = "v" + response.data.api_version;
        })
        .catch((error) => {
          this.isLoading = false;

          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },
  },
};
</script>
