import { createRouter, createWebHistory } from 'vue-router'

import show from '../components/backoffice/show.vue'
import list from '../components/backoffice/list.vue'

const routes = [

    {
        path: '/',
        component: show
    },

    {
        path: '/list',
        component: list
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router