<template>
  <div @click="firstClick" @click.self="firstClick">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

    <div class="row" style="padding-top: 20px; font-size: 12px">
      <div class="col-sm-12 col-md-2">
        <label>Notificação sonora</label>
        <select class="custom-select rounded-0" v-model="notificacao_sonora" style="font-size: 12px">
          <option :value="false">Desativada</option>
          <option :value="true">Ativada</option>
        </select>
      </div>
    </div>

    <!--
    <input type="checkbox" name="my-checkbox" checked data-bootstrap-switch v-model="notificacao_sonora" @change="notificacao_sonora = !notificacao_sonora" />
    -->

    <br />
    <!-- FILTRAR USUÁRIOS-->
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Filtrar leads</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label for="endereco_estado">De</label>

              <input v-model="dataInicio" type="datetime-local" class="form-control" />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label for="endereco_estado">Até</label>

              <input v-model="dataFim" type="date" class="form-control" />
            </div>
          </div>

          <div class="col-sm-4">
            <div class="form-group">
              <label for="endereco_estado">Enviado para</label>

              <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="enviado_para" @change="searchLeads">
                <option value=""></option>
                <option v-for="parceiro in parceiros" :key="parceiro.id" :value="parceiro.id">{{ parceiro.nome }}</option>
              </select>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label for="endereco_estado">Origem</label>

              <select class="custom-select rounded-0" v-model="origem_selecionada" @change="searchLeads()">
                <option value="importacao">Importação</option>
                <option value="formulario">Formulário</option>
                <option value="qualificacao">Qualificação</option>
                <option value="redisparo">Redisparo</option>
                <option value="SP">SP</option>
              </select>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label for="endereco_estado">Nome/número</label>

              <input type="text" name="search" class="form-control" v-model="filtro" />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label
                for="estado_civil"
                class="input-group-text my-group-text"
                style="color: black; margin-left: 0px; background-color: white !important; border: none"
              >
                Somente pendentes
              </label>
              <input
                style="margin-left: 10px; height: 25px; width: 25px; margin-top: 5px"
                v-model="somente_pendentes"
                type="checkbox"
                @change="searchLeads()"
              />
            </div>
          </div>
          <div class="col-sm-12" v-if="origem_selecionada == 'formulario'">
            <div class="form-group">
              <label for="endereco_estado">Formulário</label>

              <select class="custom-select rounded-0" v-model="formulario_selecionado" @change="searchLeads()">
                <option value=""></option>
                <option v-for="formulario in formularios" :value="formulario" :key="formulario">{{ formulario.nome }}</option>
              </select>
            </div>
          </div>

          <div class="col-sm-12">
            <button type="submit" class="btn btn-primary" @click="hiddenSearchLeads" id="hiddenSearch" hidden>Play</button>
            <button type="submit" class="btn btn-primary" @click="searchLeads">Buscar</button>
            <button type="submit" class="btn btn-default" @click="limparFiltros" style="margin-left: 5px">Limpar filtros</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box">
          <span class="info-box-icon bg-success"><i class="fas fa-solid fa-users"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">Total de leads</span>
            <span class="info-box-number">{{ total }}</span>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box">
          <span class="info-box-icon bg-info"><i class="fa-solid fa-building"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">PJ</span>
            <span class="info-box-number">{{ total_pj }}</span>
          </div>
        </div>
      </div>
    </div>

    <admin-create-lead :setCriarContainer="setCriarContainer" :containerCriado="containerCriado" />

    <button data-toggle="modal" data-target="#create-lead-modal" @click="criar_lead = true" type="button" class="btn btn-success">
      <i style="margin-right: 5px" class="fa fa-plus"></i>
      Cadastrar lead
    </button>

    <button class="btn btn-info" @click="ExportExcel('xlsx')">
      <i class="fa-solid fa-download" style="margin-right: 5px"></i>
      Baixar Excel xlsx
    </button>
    <!-- TABELA USUÁRIOS-->
    <div class="card card-primary">
      <div class="card-header">
        <h3 class="card-title">
          Leads
          <span class="badge badge-warning" style="margin-left: 5px">{{ leads.length }}</span>

          <!--
          <button style="margin-left: 15px" type="button" class="btn btn-primary" @click="atualizarLeads">
            Atualizar
            <i style="margin-left: 5px" class="fa-solid fa-rotate-right"></i>
          </button>
          -->
        </h3>
      </div>
      <div class="card-body p-0">
        <table class="table">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Telefone</th>
              <th>Cnpj</th>
              <th>Vidas</th>
              <th>Criação</th>
              <th>Origem</th>
              <th>Repetições</th>
              <th>Última data</th>
              <th>Enviado</th>
              <th>Enviado para</th>
              <th>Hora envio</th>
              <th style="width: 100px">Editar</th>
              <th style="width: 100px">Deletar</th>
              <th style="width: 100px">Copiar</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="container in leads" :key="container">
              <td data-label="Nome">{{ container.nome }}</td>
              <td data-label="Telefone">{{ container.number }}</td>
              <td data-label="Cnpj">{{ container.cnpj }}</td>
              <td data-label="Vidas">{{ container.qtd_vidas }}</td>
              <td data-label="Criação">{{ formatDate(container.created_at) }}</td>
              <td data-label="Origem">
                <p v-if="container.origem == 'importacao'">Importação</p>
                <p v-if="container.origem == 'formulario'">{{ container.formulario.nome }}</p>
                <p v-if="container.origem == 'qualificacao'">Qualificação</p>
                <p v-if="container.origem == 'redisparo'">Redisparo</p>
                <p v-if="container.origem == 'SP'">SP</p>
              </td>
              <td data-label="Repetições">{{ container.qtd_repeticoes }}</td>
              <td data-label="Último envio">
                <p v-if="container.ultima_data">{{ formatDate(container.ultima_data) }}</p>
                <p v-else>-</p>
              </td>

              <td data-label="Enviado">
                <p v-if="container.enviado == 1" style="color: green">Enviado</p>
                <p v-if="container.enviado != 1" style="color: red">Não enviado</p>
              </td>

              <td data-label="Enviado para">
                {{ container.enviado_para }}

                <select
                  :disabled="container.parceiro_id != null"
                  class="custom-select rounded-0"
                  id="exampleSelectRounded0"
                  v-model="container.parceiro_id"
                  @change="marcarEnviado(container)"
                >
                  <option v-for="parceiro in parceiros" :key="parceiro.id" :value="parceiro.id">{{ parceiro.nome }}</option>
                </select>
              </td>

              <td data-label="Hora envio">
                {{ formatDate(container.enviado_em) }}
              </td>

              <td>
                <button
                  data-toggle="modal"
                  data-target="#edit-lead-modal"
                  @click="selecionarContainer(container), (editar_lead = true)"
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  Editar
                </button>
              </td>

              <td>
                <button
                  data-toggle="modal"
                  data-target="#acoes-modal"
                  @click="confirmarDelete(container)"
                  type="button"
                  class="btn btn-danger btn-sm"
                >
                  Deletar
                </button>
              </td>
              <td>
                <button @click="copiarLead(container)" type="button" class="btn btn-success btn-sm">Copiar</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card-footer clearfix">
        <!-- TOTAIS  -->
        <div style="font-size: 13px" class="float-left" aria-live="polite">
          Exibindo do {{ inicio + 1 }} ao {{ inicio + leads.length }}
          <p>Total {{ total }}</p>
        </div>

        <p class="card-title" style="font-size: 12px; color: black; margin-right: 5px; margin-left: 10px">Por página</p>

        <select @change="alterarQtdPagina()" class="float-left card-titlecustom-select rounded-0" style="font-size: 12px" v-model="qtd_por_pagina">
          <option :value="1">1</option>
          <option :value="2">2</option>
          <option :value="7">7</option>
          <option :value="15">15</option>
          <option :value="25">25</option>
          <option :value="50">50</option>
          <option :value="75">75</option>
          <option :value="100">100</option>
          <option :value="200">200</option>
          <option :value="250">250</option>
        </select>

        <div class="card-tools">
          <div class="input-group input-group-sm" style="width: 150px"></div>
        </div>

        <!-- PAGINAÇÃO  -->
        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item"><a class="page-link" @click="selecionarPagina(pagina_atual - 1)">&laquo;</a></li>

          <li v-for="pagina in paginas" :key="pagina" :value="pagina" class="page-item">
            <a :class="pagina === pagina_atual ? 'page-link current-page' : 'stopped'" class="page-link" @click="selecionarPagina(pagina)">
              {{ pagina }}
            </a>
          </li>

          <li class="page-item"><a class="page-link" @click="selecionarPagina(pagina_atual + 1)">&raquo;</a></li>
        </ul>
      </div>
    </div>

    <div class="row" style="padding-top: 20px; font-size: 12px; padding-bottom: 25px">
      <div class="col-sm-12 col-md-12" v-for="automation in automations_controll" :key="automation" :value="automation">
        <label>Última linha excel {{ automation.id }}</label>

        <div class="input-group mb-3">
          <input id="porta" type="porta" class="form-control" v-model="automation.last_row_excel_leads" autocomplete="porta" />

          <div class="input-group-prepend">
            <button type="submit" class="btn btn-success" @click="updateExcelRows(automation)">Salvar</button>
          </div>
        </div>
      </div>
    </div>

    <table class="table" ref="exportable_table" v-show="false">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Telefone</th>
          <th>Cnpj</th>
          <th>Vidas</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="container in leads" :key="container">
          <td data-label="Nome">{{ container.nome }}</td>
          <td data-label="Telefone">{{ container.numero }}</td>
          <td data-label="Cnpj">{{ container.cnpj }}</td>
          <td data-label="Vidas">{{ container.qtd_vidas }}</td>
        </tr>
      </tbody>
    </table>

    <admin-edit-lead
      v-if="editar_lead"
      :lead="lead_selecionado"
      :parceiros="parceiros"
      :setEditarContainer="setEditarContainer"
      :containerEditado="containerEditado"
    />
  </div>
</template>

<script>
import moment from "moment";
import wppController from "../../controller/wppController";
import sendController from "../../controller/sendController";
import sweetAlert from "../../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [wppController, sendController, sweetAlert, Swal],

  components: { Loading },

  props: ["users", "parceiros"],

  data() {
    return {
      container: {},
      nome: "",
      email: "",
      senha: "",
      isLoading: false,
      lead_selecionado: "",
      leads: [],
      acoes_usuario: [],
      acao_selecionada: "",
      filtro: "",
      paginas: [],
      inicio: 0,
      total: 0,
      qtd_por_pagina: 25,
      pagina_atual: 1,
      editar_lead: false,
      criar_lead: false,
      user_selecionado: "",
      origem_selecionada: "",
      telefone_teste: "",
      mensagem_teste_envio: "",
      enviado_para: "",
      notificacao_sonora: false,
      is_first_click: true,
      ultima_linha_excel: "",
      somente_pendentes: false,
      dataInicio: "",
      dataFim: "",
      total_pj: "",
      formularios: [],
      formulario_selecionado: "",
      automations_controll: "",
    };
  },

  mounted() {
    //BUSCANDO DATAS
    this.dataFim = new Date();
    this.dataInicio = new Date();

    this.dataInicio = moment(this.dataInicio).format("yyyy-MM-DD 00:00:00");
    this.dataFim = moment(this.dataFim).format("yyyy-MM-DD");

    this.searchLeads();
    this.searchFormularios();
  },

  methods: {
    ExportExcel(type, fn, dl) {
      var elt = this.$refs.exportable_table;
      var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(wb, fn || ("Leads " + "." || "SheetJSTableExport.") + (type || "xlsx"));
    },

    searchFormularios() {
      let data = {
        inicio: this.inicio,
        tamanho: 10000,
      };

      console.log("Buscando");
      console.log(data);

      axios
        .post(`/admin/formularios/search`, data)
        .then((response) => {
          this.formularios = response.data.formularios;
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    marcarEnviado(lead) {
      let data = {
        parceiro_id: lead.parceiro_id,
        lead_id: lead.id,
      };

      axios
        .post(`/admin/lead/update_enviado`, data)
        .then((response) => {
          this.showSuccessMessage("Lead atualizado!");

          let indice = this.leads.findIndex((element) => element["id"] == lead.id);
          this.leads[indice].enviado = 1;
          this.leads[indice].enviado_em = new Date();
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    atualizarLeads() {
      this.isLoading = true;

      axios
        .post(process.env.MIX_VUE_APP_SEACH_LEADS_EXCEL + `/update`)
        .then((response) => {
          setTimeout(() => {
            this.searchLeads();
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    copiarLead(lead) {
      let that = this;

      this.$copyText(this.formatarMensagem(lead)).then(
        function (e) {
          console.log(e);
          that.showSuccessMessage("Dados copiados!");
        },
        function (e) {
          alert("Can not copy");
          console.log(e);
        }
      );
    },
    formatarMensagem(lead) {
      let mensagem =
        "*Nome:* " + lead.nome + "\n\n" + "*Telefone:* " + lead.number + "\n\n" + "*CNPJ:* " + lead.cnpj + "\n\n" + "*Vidas:* " + lead.qtd_vidas;

      return mensagem;
    },

    updateExcelRows(automation) {
      let html = "Tem certeza que deseja <b style='font-size:20px'>alterar </b> essa informação?";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!",
        })
        .then((result) => {
          if (result.value) {
            let data = {
              id: automation.id,
              last_row_excel_leads: automation.last_row_excel_leads,
            };

            axios
              .post(`/admin/automation/updateExcelRows`, data)
              .then((response) => {
                this.showSuccessMessage("Linha atualizada");
              })
              .catch((error) => {
                console.log(error);
                this.showErrorMessageWithButton("Ops..", error.response.data);
                console.log(error.response.data);
              });
          }
        });
    },

    firstClick() {
      if (this.is_first_click) {
        this.notificacao_sonora = true;
        this.is_first_click = false;
      }
    },

    limparFiltros() {
      this.enviado_para = "";
      this.filtro = "";
      this.origem_selecionada = "";

      this.searchLeads();
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },

    getFormatedCnpj(cnpj) {
      if (cnpj == 1) {
        return "Sim";
      } else {
        return "Não";
      }
    },

    getFormatedText(title) {
      if (title && title.length > 18) {
        return title.substring(0, 18) + "...";
      } else {
        return title;
      }
    },

    hiddenSearchLeads() {
      this.$swal.fire({
        toast: true,
        icon: "success",
        title: "Novo lead importado",
        position: "top-end",
        showConfirmButton: false,
        padding: "1.5em",
        timer: 5000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      if (this.notificacao_sonora) {
        console.log("Notificação ativada");
        let btn = document.getElementById("audiotag1");
        if (btn) {
          console.log("Deu play no áudio");
          btn.play();
        } else {
          console.log("Não achou o btn audio");
        }
      } else {
        console.log("Notificação desativada");
      }

      console.log("NEW LEAD");

      //AGUARDANDO 2 SEGUNDOS PARA BUSCAR OS LEADS
      setTimeout(() => {
        this.searchLeads();
      }, 2000);
    },

    alterarQtdPagina() {
      this.pagina_atual = 1;
      this.inicio = 0;

      this.searchLeads();
    },

    containerCriado() {
      this.setCriarContainer(false);

      this.searchLeads();
    },

    setCriarContainer(value) {
      let btn = document.getElementById("close_create_container");
      if (btn) {
        btn.click();
      }

      this.criar_lead = value;
    },

    containerEditado() {
      this.setEditarContainer(false);

      this.searchLeads();
    },

    setEditarContainer(value) {
      let btn = document.getElementById("close_edit_container");
      if (btn) {
        btn.click();
      }

      this.editar_lead = value;
    },

    searchLeads() {
      let aux_dataInicio = this.formatSelectedDate(this.dataInicio);
      let aux_dataFim = this.formatDateToSearch(this.dataFim);

      let data = {
        data_inicio: aux_dataInicio,
        data_fim: aux_dataFim,
        enviado_para: this.enviado_para,
        filtro: this.filtro,
        origem: this.origem_selecionada,
        inicio: this.inicio,
        tamanho: this.qtd_por_pagina,
        somente_pendentes: this.somente_pendentes,
      };

      if (this.origem_selecionada == "formulario" && this.formulario_selecionado) {
        data.formulario_id = this.formulario_selecionado.id;
      }

      console.log("Buscando");
      console.log(data);

      axios
        .post(`/admin/leads/search`, data)
        .then((response) => {
          this.leads = response.data.leads;
          this.total = response.data.total;

          this.total_pj = response.data.total_pj;

          if (!this.total_pj) {
            this.total_pj = 0;
          }

          this.isLoading = false;

          console.log("leads");
          console.log(this.leads);
          console.log(response.data);

          this.automations_controll = response.data.automation_controll;

          /*
          if (response.data.automation_controll) {
            this.ultima_linha_excel = response.data.automation_controll.last_row_excel_leads;
          } else {
            this.ultima_linha_excel = "";
          }
            */

          /*
          this.total_pj = 0;

          for (let i = 0; i < this.leads.length; i++) {
            if (this.leads[i].cnpj && this.leads[i].cnpj.toLowerCase() == "sim") {
              this.total_pj++;
            }
          }
            */

          this.pagination(response.data);
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    selecionarPagina(pagina) {
      if (pagina <= this.paginas.length && pagina > 0) {
        this.inicio = this.qtd_por_pagina * (pagina - 1);
        this.pagina_atual = pagina;
        this.searchLeads();
      }
    },

    pagination(data) {
      this.paginas = [];

      let length = data.total / this.qtd_por_pagina;
      let i = 0;

      //DEFININDO MÁXIMO E MÍNIMO DE PÁGINAS
      if (length < 0) {
        length = 0;
      } else if (length > 10) {
        if (this.pagina_atual >= 10) {
          let minimo = this.pagina_atual - 10;

          if (length - this.pagina_atual > 10) {
            length = this.pagina_atual + 10;
            i = minimo;
          } else {
            i = this.pagina_atual - 10 + (length - this.pagina_atual);
          }
        } else {
          length = 10;
        }
      }

      for (i; i < length; i++) {
        this.paginas.push(i + 1);
      }
    },

    selecionarContainer(usuario) {
      this.lead_selecionado = usuario;
    },

    addAcao() {
      this.acoes_usuario.push(this.acao_selecionada.acao);
    },

    confirmarDelete(container) {
      let html = "Deseja <b style='color: red'> deletar</b> o lead " + "<b style='font-size:25px'>" + container.nome + "?";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!",
        })
        .then((result) => {
          if (result.value) {
            this.deletar(container);
          }
        });
    },

    deletar(container) {
      let data = {
        id: container.id,
      };

      axios
        .post(`/admin/lead/delete`, data)
        .then((response) => {
          this.showSuccessMessage("Conexão deletada");

          this.searchLeads();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    update() {
      let data = {
        id: this.lead_selecionado.id,
        acoes_personalizadas: JSON.stringify(this.acoes_usuario),
      };

      axios
        .post(`/admin/leads/update/acoes`, data)
        .then((response) => {
          this.showSuccessMessage("Ações atualizadas!");

          window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    formatSelectedOnlyDate(date) {
      return moment(date).format("yyyy-MM-DD 00:00:00");
    },

    formatSelectedDate(date) {
      return moment(date).format("yyyy-MM-DD HH:mm:ss");
    },

    formatDateToSearch(date) {
      return moment(date).format("yyyy-MM-DD 23:59:59");
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
