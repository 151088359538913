<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

  <br />
  <!-- FILTRAR USUÁRIOS-->
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Filtrar conexões</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <label for="endereco_estado">Usuário</label>

            <select class="custom-select rounded-0" v-model="user_selecionado" @change="searchContainers()">
              <option value=""></option>
              <option v-for="user in users" :key="user" :value="user">
                {{ user.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="endereco_estado">Nome/email</label>

            <input type="text" name="search" class="form-control" v-model="filtro" />
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="endereco_estado">Número conectado</label>

            <input type="text" name="search" class="form-control" v-model="numero_conectado" />
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="endereco_estado">Nome da conexão</label>

            <input type="text" name="search" class="form-control" v-model="nome_container" />
          </div>
        </div>
        <div class="col-sm-12">
          <button type="submit" class="btn btn-primary" @click="searchContainers">Buscar</button>
        </div>
      </div>
    </div>
  </div>

  <admin-create-container
    v-if="criar_container"
    :users="users"
    :user_selecionado="user_selecionado"
    :setCriarContainer="setCriarContainer"
    :containerCriado="containerCriado"
  />

  <button
    data-toggle="modal"
    data-target="#create-container-modal"
    @click="selecionarContainer(container), (criar_container = true)"
    type="button"
    class="btn btn-success"
  >
    Nova conexão
    <i style="margin-left: 5px" class="fa fa-plus"></i>
  </button>
  <!-- TABELA USUÁRIOS-->
  <div class="card card-primary">
    <div class="card-header">
      <h3 class="card-title">
        Conexões
        <span class="badge badge-warning" style="margin-left: 5px">{{ containers.length }}</span>
      </h3>
    </div>
    <div class="card-body p-0">
      <table class="table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Usuário</th>
            <th>Número</th>
            <th>Tags</th>
            <th>Última atualização</th>
            <th>Informações</th>
            <th style="width: 120px">Gerenciar</th>
            <th style="width: 100px">Editar</th>
            <th style="width: 100px">Teste</th>
            <th style="width: 100px">Deletar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="container in containers" :key="container">
            <td data-label="Nome">{{ container.nome }}</td>
            <td data-label="Usuário">{{ container.usuario }}</td>
            <td data-label="Número">{{ container.numero_conectado }}</td>
            <td data-label="Tags">
              <span
                v-for="(tag, index) in container.tags_formatadas"
                :style="'margin-right:5px;color: white;background-color:' + tag.cor"
                class="badge"
              >
                {{ tag.tag }}
              </span>
            </td>

            <td data-label="Último status">
              <span v-if="container.ultima_atualizacao_status">
                {{ formatDate(container.ultima_atualizacao_status) }}
              </span>
              <span style="font-weight: bold" v-if="container.ultima_atualizacao_status">
                (há {{ calcularValor(container.ultima_atualizacao_status) }})
              </span>
            </td>

            <td>
              <button
                data-toggle="modal"
                data-target="#informacoes-modal"
                @click="exibirInformacoes(container)"
                type="button"
                class="btn btn-primary btn-sm"
              >
                Informações
              </button>
            </td>

            <td>
              <a :href="'/user/connection/' + container.id" target="_blank" class="btn btn-warning btn-sm">Gerenciar</a>
            </td>

            <td>
              <button
                data-toggle="modal"
                data-target="#edit-container-modal"
                @click="selecionarContainer(container), (editar_container = true)"
                type="button"
                class="btn btn-primary btn-sm"
              >
                Editar
              </button>
            </td>
            <td>
              <button
                data-toggle="modal"
                data-target="#enviar-teste-modal"
                @click="selecionarContainer(container), (mensagem_teste_envio = '')"
                type="button"
                class="btn btn-success btn-sm"
              >
                Teste
              </button>
            </td>

            <td>
              <button data-toggle="modal" data-target="#acoes-modal" @click="confirmarDelete(container)" type="button" class="btn btn-danger btn-sm">
                Deletar
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="card-footer clearfix">
      <!-- TOTAIS  -->
      <div style="font-size: 13px" class="float-left" aria-live="polite">
        Exibindo do {{ inicio + 1 }} ao {{ inicio + containers.length }}
        <p>Total {{ total }}</p>
      </div>

      <p class="card-title" style="font-size: 12px; color: black; margin-right: 5px; margin-left: 10px">Por página</p>

      <select @change="alterarQtdPagina()" class="float-left card-titlecustom-select rounded-0" style="font-size: 12px" v-model="qtd_por_pagina">
        <option :value="1">1</option>
        <option :value="2">2</option>
        <option :value="7">7</option>
        <option :value="15">15</option>
        <option :value="25">25</option>
      </select>

      <div class="card-tools">
        <div class="input-group input-group-sm" style="width: 150px"></div>
      </div>

      <!-- PAGINAÇÃO  -->
      <ul class="pagination pagination-sm m-0 float-right">
        <li class="page-item"><a class="page-link" @click="selecionarPagina(pagina_atual - 1)">&laquo;</a></li>

        <li v-for="pagina in paginas" :key="pagina" :value="pagina" class="page-item">
          <a :class="pagina === pagina_atual ? 'page-link current-page' : 'stopped'" class="page-link" @click="selecionarPagina(pagina)">
            {{ pagina }}
          </a>
        </li>

        <li class="page-item"><a class="page-link" @click="selecionarPagina(pagina_atual + 1)">&raquo;</a></li>
      </ul>
    </div>
  </div>

  <!-- MODAL INFORMAÇÕES -->
  <div class="modal fade" id="informacoes-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <form @submit.prevent>
      <div class="modal-dialog" style="text-align: left">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            <h4>Informações</h4>
            <br />
            <div class="form-group">
              <label>Comentários</label>
              <textarea class="form-control" rows="3" v-model="container_selecionado.comentarios"></textarea>

              <div class="p2" v-if="array_tags && array_tags.length > 0">
                <br />
                <label for="user_type">Tags atuais</label>
                <br />
                <span v-for="(tag, index) in array_tags" :style="'margin-right:5px;color: white;background-color:' + tag.cor" class="badge">
                  {{ tag.tag }}
                  <i class="fa fa-trash" style="margin-left: 5px; cursor: pointer" @click="removerTag(index)"></i>
                </span>
                <br />
                <br />
              </div>
              <br />
              <label for="user_type">Adicionar Tag padrão</label>

              <div class="input-group mb-3">
                <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="tag_padrao_option" :style="'color:' + tag.cor">
                  <option v-for="tag in array_tags_padrao" :value="tag" :style="'color:' + tag.cor">{{ tag.tag }}</option>
                </select>

                <input id="dataFim" type="datetime-local" v-model="data_tag" class="form-control" />

                <div class="input-group-prepend">
                  <button type="button" class="btn btn-success" @click="addTagPadrao()">Adicionar</button>
                </div>
              </div>

              <label for="user_type">Adicionar Tag personalizada</label>

              <div class="input-group mb-3">
                <input type="text" class="form-control" v-model="tag" />
                <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="cor_tag" :style="'color:' + cor_tag">
                  <option value="#FF0000" style="color: #ff0000">Vermelho</option>
                  <option value="#1453db" style="color: #1453db">Azul</option>
                  <option value="#e0be12" style="color: #e0be12">Amarelho</option>
                  <option value="#32a61b" style="color: #32a61b">Verde</option>
                </select>
                <input id="dataFim" type="datetime-local" v-model="data_tag" class="form-control" />

                <div class="input-group-prepend">
                  <button type="button" class="btn btn-success" @click="addTag()">Adicionar</button>
                </div>
              </div>

              <label for="user_type">Histórico</label>

              <table class="table">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Usuário</th>
                    <th>Deletar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(historico, index) in array_historico" :key="historico">
                    <td data-label="Tag">{{ historico.tag }}</td>
                    <td data-label="Data">{{ formatDate(historico.data) }}</td>
                    <td>
                      <i @click="deletarHistorico(index)" class="fa fa-trash" style="margin-left: 5px; cursor: pointer"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="form-group">
              <h6 style="font-weight: bold">{{ mensagem_teste_envio }}</h6>
            </div>
            <br />
            <button data-toggle="modal" @click="salvarInformacoes" type="button" class="btn btn-success btn-sm">Salvar</button>
            <button id="close_modal_info" data-dismiss="modal" type="button" style="margin-left: 5px" class="btn btn-danger btn-sm">Fechar</button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!-- MODAL ENVIO TESTE -->
  <div class="modal fade" id="enviar-teste-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <form @submit.prevent>
      <div class="modal-dialog" style="text-align: left">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            <h4>Enviar teste</h4>
            <br />
            <div class="form-group">
              <label for="user_type">Telefone</label>
              <input id="nome" type="text" class="form-control" v-model="telefone_teste" autocomplete="name" />
            </div>

            <div class="form-group">
              <h6 style="font-weight: bold">{{ mensagem_teste_envio }}</h6>
            </div>
            <br />
            <button data-toggle="modal" @click="enviarTeste" type="button" class="btn btn-success btn-sm">Teste</button>
            <button data-toggle="modal" data-target="#enviar-teste-modal" type="button" style="margin-left: 5px" class="btn btn-danger btn-sm">
              Fechar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <admin-edit-container
    v-if="editar_container"
    :container="container_selecionado"
    :setEditarContainer="setEditarContainer"
    :containerEditado="containerEditado"
  />
</template>

<script>
import moment from "moment";
import wppController from "../../controller/wppController";
import sendController from "../../controller/sendController";
import sweetAlert from "../../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [wppController, sendController, sweetAlert, Swal],

  components: { Loading },

  props: ["users"],

  data() {
    return {
      container: {},
      nome: "",
      email: "",
      senha: "",
      isLoading: false,
      container_selecionado: "",
      containers: [],
      acoes_usuario: [],
      acao_selecionada: "",
      filtro: "",
      paginas: [],
      inicio: 0,
      total: 0,
      qtd_por_pagina: 25,
      pagina_atual: 1,
      editar_container: false,
      criar_container: false,
      user_selecionado: "",
      telefone_teste: "",
      mensagem_teste_envio: "",
      tag: "",
      cor_tag: "#FF0000",
      array_tags: [],
      array_historico: [],
      data_tag: "",
      tag_padrao_option: "",
      ultima_atualizacao_status: "",
      numero_conectado: "",
      nome_container: "",
      array_tags_padrao: [],
    };
  },

  mounted() {
    this.searchContainers();

    this.data_tag = moment(new Date()).format("yyyy-MM-DD HH:mm:ss");
    this.formatSelectedDate(this.data_tag);

    this.array_tags_padrao.push({ tag: "OK", cor: "#32a61b" });
    this.array_tags_padrao.push({ tag: "Aquecendo", cor: "#e0be12" });
    this.array_tags_padrao.push({ tag: "Banido", cor: "#ff0000" });
    this.array_tags_padrao.push({ tag: "Aguardando", cor: "#1453db" });
    this.array_tags_padrao.push({ tag: "Reaquecendo", cor: "#e0be12" });
  },

  methods: {
    calcularValor(created_at) {
      let dataReferencia = moment(new Date());
      let data = moment(created_at);

      var duration = moment.duration(dataReferencia.diff(data));
      var hours = duration.asHours();

      if (hours < 1) {
        var min = duration.asMinutes();
        return ~~min + " min.";
      } else if (hours >= 24) {
        var days = duration.asDays();

        let tempo = " dias";
        if (days < 2) {
          tempo = " dia";
        }

        return ~~days + tempo;
      } else {
        let tempo = " horas";
        if (hours < 2) {
          tempo = " hora";
        }

        return ~~hours + tempo;
      }
    },

    salvarInformacoes() {
      let data = {
        container_id: this.container_selecionado.id,
        historico: JSON.stringify(this.array_historico),
        tags: JSON.stringify(this.array_tags),
        ultima_atualizacao_status: moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
        comentarios: this.container_selecionado.comentarios,
      };

      if (this.user_selecionado) {
        data.user_id = this.user_selecionado.id;
      }

      console.log(data);

      axios
        .post(`/admin/containers/atualizar_informacoes`, data)
        .then((response) => {
          this.showSuccessMessageWithButton("Sucesso", "Informações atualizadas");

          let indice = this.find(this.container_selecionado.id);

          this.containers[indice].tags = JSON.stringify(this.array_tags);
          this.containers[indice].tags_formatadas = this.array_tags;
          this.containers[indice].historico = JSON.stringify(this.array_historico);
          this.containers[indice].comentarios = this.container_selecionado.comentarios;

          let btn = document.getElementById("close_modal_info");
          if (btn) {
            btn.click();
          }
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    find(id) {
      let indice = this.containers.findIndex((element) => element.id == id);
      return indice;
    },

    exibirInformacoes(container) {
      this.container_selecionado = container;

      let arr = JSON.parse(this.container_selecionado.tags);

      if (!arr) {
        arr = [];
      }

      let arr_historico = JSON.parse(this.container_selecionado.historico);

      if (!arr_historico) {
        arr_historico = [];
      }

      this.array_tags = arr;
      this.array_historico = arr_historico;
    },

    deletarHistorico(index) {
      let arr_historico = JSON.parse(this.container_selecionado.historico);

      if (arr_historico) {
        arr_historico.splice(index, 1);

        this.container_selecionado.historico = JSON.stringify(arr_historico);
        this.array_historico = arr_historico;
      }
    },

    addHistorico(tag) {
      console.log("Historico");

      this.array_historico.push(tag);
      this.array_historico = this.array_historico.sort(this.compare);

      this.container_selecionado.historico = JSON.stringify(this.array_historico);
    },

    removerTag(index) {
      let arr = JSON.parse(this.container_selecionado.tags);

      if (arr) {
        arr.splice(index, 1);

        this.container_selecionado.tags = JSON.stringify(arr);
        this.array_tags = arr;
      }
    },

    compare(a, b) {
      let data1 = new Date(a.data);
      let data2 = new Date(b.data);

      console.log("Comparando");

      if (data1 > data2) {
        return -1;
      }
      if (data1 < data2) {
        return 1;
      }
      return 0;
    },

    addTag() {
      if (this.tag) {
        let arr = JSON.parse(this.container_selecionado.tags);

        if (!arr) {
          arr = [];
        }

        let tag = { tag: this.tag, cor: this.cor_tag, data: moment(this.data_tag).format("yyyy-MM-DD HH:mm:ss") };
        arr.push(tag);
        this.addHistorico(tag);
        this.ultima_atualizacao_status = moment(new Date()).format("YYYY/MM/DD HH:mm:ss");

        this.array_tags = arr;
        this.tag = "";

        this.container_selecionado.tags = JSON.stringify(arr);
      }
    },

    addTagPadrao() {
      if (this.tag_padrao_option) {
        console.log("Adicionando");
        console.log(this.tag_padrao_option);

        let arr = JSON.parse(this.container_selecionado.tags);

        if (!arr) {
          arr = [];
        }

        if (typeof this.tag_padrao_option == "string") {
          this.tag_padrao_option = JSON.parse(this.tag_padrao_option);
        }

        this.tag_padrao_option.data = moment(this.data_tag).format("yyyy-MM-DD HH:mm:ss");

        arr.push(this.tag_padrao_option);
        this.addHistorico(this.tag_padrao_option);
        this.ultima_atualizacao_status = moment(new Date()).format("YYYY/MM/DD HH:mm:ss");

        this.array_tags = arr;
        this.tag_padrao_option = "";

        this.container_selecionado.tags = JSON.stringify(arr);
      }
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },

    enviarTeste() {
      console.log(this.container_selecionado);

      if (this.telefone_teste) {
        this.telefone_teste = this.telefone_teste.replace(/[^Z0-9]/g, "");

        //ADICIONANDO 55 CASO NÃO TENHA
        if (this.telefone_teste.substr(0, 2) != "55") {
          this.telefone_teste = "55" + this.telefone_teste;
        }

        this.mensagem_teste_envio = "Enviando agora...";

        let porta = this.container_selecionado.porta;

        if (!porta) {
          porta = this.container_selecionado.porta_usuario;
        }
        this.validarNumero(this.telefone_teste, this.container_selecionado.chave_api, porta, (validNumber, timeout) => {
          if (validNumber) {
            this.enviar("texto", validNumber, "Teste", "", this.container_selecionado.chave_api, porta, (timeout, phone, sucess) => {
              if (timeout) {
                this.mensagem_teste_envio = "Verifique a aba Conexão...";
              } else {
                //Verificando se foi enviado com sucesso
                console.log("Checking status...");

                if (sucess) {
                  console.log("Success");
                  //Prosseguir envio
                  if (phone) {
                    this.mensagem_teste_envio = "Mensagem enviada...";
                  } else {
                    this.mensagem_teste_envio = "Ops... Falha no envio";
                  }
                } else {
                  this.mensagem_teste_envio = "Falha no envio";
                }
              }
            });
          } else {
            if (timeout) {
              this.mensagem_teste_envio = "Falha ao validar número, conexão instável...";
            } else {
              this.mensagem_teste_envio = "WhatsApp inválido...";
            }
          }
        });
      }
    },

    alterarQtdPagina() {
      this.pagina_atual = 1;
      this.inicio = 0;

      this.searchContainers();
    },

    containerCriado() {
      this.setCriarContainer(false);

      this.searchContainers();
    },

    setCriarContainer(value) {
      let btn = document.getElementById("close_create_container");
      if (btn) {
        btn.click();
      }

      this.criar_container = value;
    },

    containerEditado() {
      this.setEditarContainer(false);

      this.searchContainers();
    },

    setEditarContainer(value) {
      let btn = document.getElementById("close_edit_container");
      if (btn) {
        btn.click();
      }

      this.editar_container = value;
    },

    searchContainers() {
      let data = {
        filtro: this.filtro,
        inicio: this.inicio,
        tamanho: this.qtd_por_pagina,
        numero_conectado: this.numero_conectado,
        nome_container: this.nome_container,
      };

      if (this.user_selecionado) {
        data.user_id = this.user_selecionado.id;
      }

      axios
        .post(`/admin/connections/search`, data)
        .then((response) => {
          this.containers = response.data.containers;
          this.total = response.data.total;

          this.pagination(response.data);

          for (let i = 0; i < this.containers.length; i++) {
            this.containers[i].tags_formatadas = JSON.parse(this.containers[i].tags);
          }

          console.log("containers");
          console.log(this.containers);
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    selecionarPagina(pagina) {
      if (pagina <= this.paginas.length && pagina > 0) {
        this.inicio = this.qtd_por_pagina * (pagina - 1);
        this.pagina_atual = pagina;
        this.searchContainers();
      }
    },

    pagination(data) {
      this.paginas = [];

      let length = data.total / this.qtd_por_pagina;
      let i = 0;

      //DEFININDO MÁXIMO E MÍNIMO DE PÁGINAS
      if (length < 0) {
        length = 0;
      } else if (length > 10) {
        if (this.pagina_atual >= 10) {
          let minimo = this.pagina_atual - 10;

          if (length - this.pagina_atual > 10) {
            length = this.pagina_atual + 10;
            i = minimo;
          } else {
            i = this.pagina_atual - 10 + (length - this.pagina_atual);
          }
        } else {
          length = 10;
        }
      }

      for (i; i < length; i++) {
        this.paginas.push(i + 1);
      }
    },

    selecionarContainer(usuario) {
      this.container_selecionado = usuario;
    },

    addAcao() {
      this.acoes_usuario.push(this.acao_selecionada.acao);
    },

    confirmarDelete(container) {
      let html =
        "Deseja <b style='color: red'> deletar</b> a conexão " +
        "<b style='font-size:25px'>" +
        container.nome +
        "</b><br/> do usuário <b>" +
        container.usuario +
        "</b>?";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!",
        })
        .then((result) => {
          if (result.value) {
            this.deletar(container);
          }
        });
    },

    formatSelectedDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },

    deletar(container) {
      let data = {
        id: container.id,
      };

      axios
        .post(`/user/connection/delete`, data)
        .then((response) => {
          this.showSuccessMessage("Conexão deletada");

          this.searchContainers();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    update() {
      let data = {
        id: this.container_selecionado.id,
        acoes_personalizadas: JSON.stringify(this.acoes_usuario),
      };

      axios
        .post(`/admin/containers/update/acoes`, data)
        .then((response) => {
          this.showSuccessMessage("Ações atualizadas!");

          window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}

.modal-content {
  width: 1100px;
  margin-left: -250px;
}

@media screen and (max-width: 1200px) {
  .modal-content {
    width: 800px;
    margin-left: -20px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 1100px) {
  .modal-content {
    width: 600px;
    margin-left: -20px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 960px) {
  .modal-content {
    width: 750px;
    margin-left: -120px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 800px) {
  .modal-content {
    width: 600px;
    margin-left: -50px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 600px) {
  .modal-content {
    width: 100%;
    margin-left: 0;
    margin-bottom: 100px;
  }
}
</style>
