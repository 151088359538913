<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12">
        <div class="card card-success">
          <div class="card-header">
            <h3 class="card-title">Resposta Automática</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="exampleInputEmail1">Status:</label>
                  <select :class="funil_selecionado.ativo === 1 ? 'custom-select rounded-0 green' : 'custom-select rounded-0 red'
                    " id="exampleSelectRounded0" v-model="funil_selecionado.ativo">
                    <option :value="0" style="color: red">Desativada</option>
                    <option :value="1" style="color: green">Ativa</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button @click="updateFunil()" class="btn btn-sm btn-success">Salvar</button>
          </div>
        </div>
      </div>
      <br />
      <br />
      <!--
      <div class="col-md-12">
        <button @click="addMessage()" class="btn btn-sm btn-primary">
          Adicionar Mensagem
          <span class="badge badge-warning">{{ mensagens.length }}</span>
        </button>
      </div>
      <br />
      <br />

      <div class="col-md-3" v-for="mensagem in mensagens" :key="mensagem.id">
        <div class="card card-primary">
          <div class="card-header">
            <h3 class="card-title">Resposta <b v-if="mensagens.length > 1">{{ mensagem.id }}</b>
            </h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="exampleSelectRounded0">Tipo de mensagem</label>
                  <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="mensagem.tipo_envio">
                    <option value="texto">Texto</option>
                    <option value="imagem">Imagem</option>
                    <option value="video">Video</option>
                    <option value="link">Link</option>
                    <option value="voz">Voz</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="exampleInputEmail1">Tempo para envio (em segundos)</label>
                  <input class="form-control" id="exampleInputEmail1" placeholder="Ex: 10" v-model="mensagem.tempo" />
                </div>

                <div v-if="mensagem.tipo_envio != 'voz'">
                  <div class="form-group">
                    <label>Mensagem</label>
                    <textarea v-bind:class="{
                      'form-control': !mensagem.message_error,
                      'form-control is-invalid': mensagem.message_error,
                    }" @blur="validateInput(mensagem)" rows="3" placeholder="Digite a mensagem que deseja enviar ..."
                      v-model="mensagem.texto"></textarea>
                  </div>
                </div>

                <div v-if="mensagem.tipo_envio == 'imagem'">
                  <div class="form-group">
                    <label for="imageToSend">Url da imagem</label>
                    <textarea v-bind:class="{
                      'form-control': !mensagem.url_error,
                      'form-control is-invalid': mensagem.url_error,
                    }" @blur="validateInput(mensagem)" placeholder="Url da imagem a ser enviada" v-model="mensagem.url"
                      id="imageToSend" rows="3" />
                    <a href="/admin/gallery" style="font-size: 15px">
                      Não possui url? Busque na sua galeria.</a>
                  </div>
                  <div class="form-group">
                    <div v-if="mensagem.url" class="form-check">
                      <input v-model="mensagem.preview" type="checkbox" class="form-check-input" id="preview" />
                      <label class="form-check-label" for="preview">Previsualizar imagem?
                      </label>
                    </div>
                  </div>
                  <div v-if="mensagem.preview" class="form-check">
                    <img id="qrCode" :src="mensagem.url" style="
                                                                              min-width: 200px;
                                                                              min-height: 180px;
                                                                              max-width: 200px;
                                                                              max-height: 180px;
                                                                            " />
                    <br />
                    <br />
                  </div>
                </div>

                <div v-if="mensagem.tipo_envio == 'video'">
                  <div class="form-group">
                    <label for="imageToSend">Url do vídeo</label>

                    <textarea v-bind:class="{
                      'form-control': !mensagem.url_error,
                      'form-control is-invalid': mensagem.url_error,
                    }" @blur="validateInput(mensagem)" placeholder="URL do vídeo a ser enviado" v-model="mensagem.url"
                      id="imageToSend" rows="3" />

                    <a href="/admin/gallery" style="font-size: 15px">
                      Não possui url? Busque na sua galeria.</a>
                  </div>
                  <div class="form-group">
                    <div v-if="mensagem.url" class="form-check">
                      <input v-model="mensagem.preview" type="checkbox" class="form-check-input" id="preview" />
                      <label class="form-check-label" for="preview">Previsualizar vídeo?
                      </label>
                    </div>
                  </div>
                  <div v-if="mensagem.preview" class="form-check">
                    <video v-if="mensagem.preview" controls width="250" height="150">
                      <source :src="mensagem.url" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>

                <div v-if="mensagem.tipo_envio == 'voz'">
                  <div class="form-group">
                    <label for="imageToSend">Url </label>
                    <br />
                    <textarea v-bind:class="{
                      'form-control': !mensagem.url_error,
                      'form-control is-invalid': mensagem.url_error,
                    }" @blur="validateInput(mensagem)" placeholder="Url do áudio a ser enviado" v-model="mensagem.url"
                      id="imageToSend" rows="4" cols="40" />
                    <a href="/admin/gallery" style="font-size: 15px">
                      Não possui url? Busque na sua galeria.</a>
                    <video v-if="mensagem.url" controls style="width: 100%; max-width: 300px; margin-top: 5%" width="300"
                      height="50" poster="{{ asset('/dist/img/whats-logo.png') }}">
                      <source :src="mensagem.url" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>

                <div v-if="mensagem.tipo_envio == 'link'">
                  <div class="form-group">
                    <label for="imageToSend">Url:</label>
                    <input placeholder="Url a ser enviada" style="width: 100%" type="text" id="phone" nome="phone"
                      v-model="mensagem.url" v-bind:class="{
                        'form-control': !mensagem.url_error,
                        'form-control is-invalid': mensagem.url_error,
                      }" @blur="validateInput(mensagem)" />
                  </div>
                </div>
                <div>
                  <button @click="deleteMessage(mensagem)" class="btn btn-outline-danger btn-sm btn-block">
                    <i class="fa fa-trash"></i>
                    Deletar Mensagem
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    -->
    </div>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: ["funil", "my_container"],

  mixins: [wppController, sweetAlert, Swal],

  components: { Loading },

  data() {
    return {
      container: {},
      nome_envio: null,
      mesage_to_send: "",
      url_to_send: null,
      isConnected: false,
      send_to_list: false,
      add_to_funil: false,
      list_break: "CSV",
      listToSend: null,
      contacts: [],
      preview: false,
      tipo_envio: "texto",
      mensagens: [],
      resposta_padrao: "",
      funil_selecionado: "",
      isLoading: false
    };
  },

  mounted() {
    this.container = this.my_container;

    console.log(this.funil);

    this.funil_selecionado = this.funil.funil;

    this.inicializarMensagens();
  },

  methods: {

    getId() {

    },

    inicializarMensagens() {
      console.log("Inicializando");
      for (let i = 0; i < this.funil.mensagens.length; i++) {
        console.log("Mensagem " + i);
        this.mensagens.push(this.converterMensagem(this.funil.mensagens[i]));
      }

      console.log(this.mensagens);
    },

    converterMensagem(mensagem_banco) {
      let mensagem = {
        nome: mensagem_banco.nome,
        id: mensagem_banco.id,
        texto: mensagem_banco.texto,
        tipo_envio: mensagem_banco.tipo_media,
        url: mensagem_banco.url,
        tag: mensagem_banco.tag,
        tempo: mensagem_banco.dif_minutos,
        aguardar_resposta: mensagem_banco.aguardar_resposta == 1,
        resposta_padrao: mensagem_banco.resposta_padrao,
        resposta_esperada: JSON.parse(mensagem_banco.resposta_esperada)
      };

      return mensagem;
    },

    editarNome(mensagem) {
      mensagem.editar_nome = !mensagem.editar_nome;
    },

    salvar() {
      console.log(this.mensagens);
    },

    addRespostaEsperada(mensagem) {
      mensagem.resposta_esperada.push({
        resposta_esperada: "",
        resposta: "",
        proxima_tag: "",
      });
    },

    addMessage() {
      let mensagem = {
        nome: "Mensagem " + (parseInt(this.mensagens.length, 10) + 1),
        id: this.mensagens.length + 1,
        tipo_envio: "texto",
        tag: this.getTag(),
        tempo: 0,
        aguardar_resposta: true,
        resposta_padrao: "",
        resposta_esperada: [{
          resposta_esperada: "",
          resposta: "",
          proxima_tag: "",
          encerrar_funil: false
        }],
        nova: true
      };

      this.mensagens.push(mensagem);
    },

    getTag() {
      return (
        this.container.url + "-" + new Date().getTime() + "#msg" + this.mensagens.length
      );
    },

    deleteMessage(mensagem) {
      let html = "Deseja deletar esta mensagem?";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!"
        })
        .then(result => {
          if (result.value) {
            this.deletar(mensagem);
          }
        });
    },

    deletar(mensagem) {

      if (!mensagem.nova) {
        let data = {
          mensagem_id: mensagem.id,
        };

        console.log("Dados do funil");
        console.log(data);

        axios
          .post(`/funil/delete-message`, data)
          .then((response) => {
            this.sucessoDelete(mensagem);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log("ELSE");
        this.sucessoDelete(mensagem);
      }
    },

    sucessoDelete(mensagem) {
      const index = this.mensagens.indexOf(mensagem);

      if (index > -1) {
        this.mensagens.splice(index, 1);
      }

      let aux = 1;

      //REORDENANDO ARRAY
      this.mensagens.forEach((element) => {
        element.id = aux;
        aux++;
      });

      this.showSuccessMessage("Mensagem deletada!");
    },

    deleteRespostaEsperada(resposta_esperada, indice) {

      let html = "Deseja deletar esta resposta esperada?";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!"
        })
        .then(result => {
          if (result.value) {
            resposta_esperada.splice(indice, 1);

            let aux = 1;

            //REORDENANDO ARRAY
            resposta_esperada.forEach((element) => {
              element.id = aux;
              aux++;
            });
          }
        });

    },

    validateResposta(resposta) {
      if (!resposta.resposta) {
        resposta.message_error = true;
      } else {
        resposta.message_error = false;
      }
    },

    validateInput(mensagem) {
      if (mensagem.tipo_envio == "texto" && !mensagem.texto) {
        mensagem.message_error = true;
      } else {
        mensagem.message_error = false;
      }

      if (mensagem.tipo_envio != "texto" && !mensagem.url) {
        mensagem.url_error = true;
      } else {
        mensagem.url_error = false;
      }
    },

    validateFields() {
      if (this.mensagens.length <= 0) {
        this.showErrorMessageWithButton("Ops...", "Você precisa adicionar uma mensagem!");
      } else {
        this.mesage_to_send = this.mensagens[0].mensagem;
        this.url_to_send = this.mensagens[0].url;
        this.tipo_envio = this.mensagens[0].tipo_envio;

        if (!this.nome_envio) {
          this.showErrorMessageWithButton("Ops...", "O nome do envio é obrigatório!");
        } else if (this.hasInputError()) {
          this.showErrorMessageWithButton(
            "Ops...",
            "Preencha todos os campos corretamente!"
          );
        } else if (!this.listToSend) {
          this.showErrorMessageWithButton(
            "Ops...",
            "A lista de contatos não pode ficar em branco!"
          );
        } else if (this.mensagens.length <= 1) {
          this.showErrorMessageWithButton(
            "Ops...",
            "A sequência precisa ter pelo menos 2 mensagens!"
          );
        } else {
          this.sendToList();
        }
      }
    },

    hasInputError() {
      let hasError = false;

      for (let i = 0; i < this.mensagens.length; i++) {
        if (this.mensagens[i].tipo_envio == "texto" && !this.mensagens[i].mensagem) {
          this.mensagens[i].message_error = true;
          hasError = true;
        }

        if (this.mensagens[i].tipo_envio != "texto" && !this.mensagens[i].url) {
          this.mensagens[i].url_error = true;
          hasError = true;
        }
      }

      return hasError;
    },

    sendToList() {
      this.contacts = [];

      let arrayContatos = [];

      console.log("ListBreak");
      console.log(this.list_break);

      if (this.list_break == "CSV") {
        arrayContatos = this.listToSend.split(",");
      } else if (this.list_break == "Excel") {
        arrayContatos = this.listToSend.split("\n");
      } else if (this.list_break == "JSON") {
        if (this.validateJSON(this.listToSend)) {
          arrayContatos = this.extractJSON();
        } else {
          this.showErrorWithButton(
            "Ops...",
            null,
            "JSON Inválido! Confira os dados e tente novamente.<br/><br/>" +
            " <div style='font-size: 12px'>Ficou com dúvidas? Você pode validar o seu JSON!<br/>" +
            "<br/><a style='color:green' target='_blank' href='https://jsonformatter.curiousconcept.com/'> Valide aqui o seu JSON </a>" +
            "</div>"
          );

          return 0;
        }
      }

      console.log(arrayContatos);

      for (let i = 0; i < arrayContatos.length; i++) {
        //FIX UNDEFINED ISSUE
        if (!arrayContatos[i]) {
          arrayContatos[i] = "84";
        }

        //REMOVENDO TODOS OS CARACTERES, EXCETO NÚMEROS
        let newPhone = "55" + arrayContatos[i].replace(/[^Z0-9]/g, "");

        let isOnTheList = false;

        for (let i = 0; i < this.contacts.length; i++) {
          if (this.contacts[i].phone == newPhone) {
            isOnTheList = true;
          }
        }
        if (!isOnTheList) {
          this.contacts.push({
            phone: newPhone,
          });
        }
      }

      console.log("Contatos limpos");
      console.log(this.contacts);

      this.index = 0;

      this.contEnviados = 0;

      this.confirmarEnvio(
        "Confirmação",
        "Foram encontrados " +
        arrayContatos.length +
        " contato(s) deseja prosseguir com o envio?",
        () => {
          this.criarEnvio();
        }
      );
    },

    updateFunil() {

      this.isLoading = true;

      let mensagens_criar = [];
      let mensagens = [];

      for (let i = 0; i < this.mensagens.length; i++) {
        if (this.mensagens[i].nova) {
          mensagens_criar.push(this.mensagens[i]);
        } else {
          mensagens.push(this.mensagens[i]);
        }
      }

      let data = {
        id_funil: this.funil_selecionado.id,
        nome: this.funil_selecionado.nome,
        ativo: this.funil_selecionado.ativo,
        mensagens: mensagens,
        mensagens_criar: mensagens_criar
      };

      console.log("Dados do funil");
      console.log(data);

      axios
        .post(`/funil/edit`, data)
        .then((response) => {
          console.log("Funil criado!");
          console.log(response);

          this.showSuccessMessage("Funil editado!");

          this.isLoading = false;

          //window.location.reload();

          callback(response.data.funil_id);
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    addMessageFlow() {
      for (let i = 0; i < this.mensagens.length; i++) {
        //CASO TENHA SÓ 1 MENSAGEM OU CASO SEJA O ÚLTIMO ELEMENTO DO ARRAY
        if (this.mensagens.length == 1 || i + 1 == this.mensagens.length) {
          this.mensagens[i].resposta_esperada = [
            {
              proxima_tag: "*",
              encerrar_funil: "true",
              //teste: "teste"
            },
          ];
        } else {
          this.mensagens[i].resposta_esperada = [
            {
              proxima_tag: this.mensagens[i + 1].tag,
              encerrar_funil: "false",
              //teste: "teste"
            },
          ];
        }
      }
    },

    validateJSON(json) {
      console.log("VALIDANDO JSON");

      try {
        let jsonValido = JSON.parse(json);

        console.log("JS");
        console.log(jsonValido);

        if (jsonValido) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },

    extractJSON() {
      console.log("Listando json");
      console.log(this.listToSend);

      let json = JSON.parse(this.listToSend);

      console.log(json[0]);

      let arrayContatos = [];

      for (let i = 0; i < json.length; i++) {
        arrayContatos.push(json[i].Telefone);
      }

      return arrayContatos;
    },
  },
};
</script>

<style scoped>
.green {
  color: green;
  font-weight: bold;
}

.red {
  color: red;
  font-weight: bold;
}

.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
