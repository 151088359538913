<template>
  <div class="chart-container">
    <canvas id="myChart"></canvas>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import moment from "moment";
import Chart from "chart.js/auto";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  mixins: [wppController],

  props: ["usuario"],

  data() {
    return {
      chartData: "",
      arrayMensagens: [],
      arrayLabels: [],
      arrayValores: [],
      relatorio_mensagens: [],
      myChart: "",
    };
  },

  mounted() {
    if (this.usuario.reset_password == 1) {
      Swal.fire({
        title: "Modifique sua senha",
        input: "text",
        text: "modifique sua senha para continuar",
        confirmButtonText: "Confirmar",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          this.update(result.value);

          Swal.fire({
            text: "senha alterada com sucesso",
          });
          console.log(result.value);
        }
      });
    }

    this.searchEnviadasRelatorio();
  },

  methods: {
    searchEnviadasRelatorio() {
      let data = {
        user_id: this.usuario.id,
      };

      axios
        .post(`/user/mensagens/search_relatorio_enviadas`, data)
        .then((response) => {
          this.relatorio_mensagens = response.data.relatorio_mensagens;

          console.log("Status connection component ");
          console.log(this.relatorio_mensagens);

          const ctx = document.getElementById("myChart");

          const labels = ["22/06", "23/06", "24/06", "25/06", "26/06", "27/06", "28/06"];

          const data = {
            labels: this.arrayLabels,
            datasets: [
              {
                label: "Mensagens enviadas",
                data: this.arrayValores,
                borderColor: "#c73636",
                backgroundColor: "#40b860",
                pointStyle: "circle",
                pointRadius: 4,
                pointHoverRadius: 7,
              },
            ],
          };

          const config = {
            type: "line",
            data: data,
            options: {
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                title: {
                  display: false,
                  text: (ctx) => "Point Style: " + ctx.chart.data.datasets[0].pointStyle,
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            },
          };

          //RELATORIO
          //DIA DE HOJE -6 DIAS
          let dias = 6;
          let hoje = new Date();

          hoje.setDate(hoje.getDate() - dias);

          for (let i = 0; i <= dias; i++) {
            let data_buscar_array = moment(hoje).format("yyyy-MM-DD");

            //BUSCAR NO ARRAY
            let mensagens = 0;

            for (let j = 0; j < this.relatorio_mensagens.length; j++) {
              if (this.relatorio_mensagens[j].data_do_envio == data_buscar_array) {
                mensagens = this.relatorio_mensagens[j].qtd_mensagens;
              }
            }

            this.arrayValores.push(mensagens);

            this.arrayLabels.push(this.formatStringDate(hoje));

            hoje.setDate(hoje.getDate() + 1);
          }

          if (this.myChart != "") {
            this.myChart.destroy();
          }

          this.myChart = new Chart(ctx, config);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    update(novaSenha) {
      let data = {
        senha: novaSenha,
        user_id: this.usuario.id,
      };
      axios
        .post(`/user/update`, data)
        .then((response) => {
          console.log("Usuário criado!");
          console.log(response);

          this.showSuccessMessage("Usuário atualizado!");

          window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    relatorio() {
      //DIA DE HOJE -6 DIAS
      let dias = 6;
      let hoje = new Date();

      hoje.setDate(hoje.getDate() - dias);

      for (let i = 0; i <= dias; i++) {
        this.buscarData(hoje);
        hoje.setDate(hoje.getDate() + 1);
      }

      console.log("Array mensagens");
      console.log(this.arrayLabels);
      console.log(this.arrayValores);
    },

    buscarData(data) {
      this.arrayValores.push(this.buscarNoArray(this.formatSelectedDate(data)));
      this.arrayLabels.push(this.formatStringDate(data));
    },

    buscarNoArray(data) {
      let mensagens = 0;

      for (let i = 0; i < this.relatorio_mensagens.length; i++) {
        if (this.relatorio_mensagens[i].data_do_envio == data) {
          mensagens = this.relatorio_mensagens[i].qtd_mensagens;
        }
      }

      return mensagens;
    },

    getStatus() {},

    formatSelectedDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },

    formatStringDate(date) {
      return moment(date).format("DD-MM");
    },
  },
};
</script>

<style>
canvas {
  border: 1px dotted red;
}

.chart-container {
  position: relative;
  width: 100%;
  height: 30vh;
  max-height: 400px;
}
</style>
