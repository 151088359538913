<template>
  <br />
  <!-- FILTROS -->
  <div class="card">
    <div class="card-header">
      <h5 class="card-title" id="accordion">Configurações</h5>
    </div>
    <!--<div id="collapse1" class="collapse" data-parent="#accordion"> -->
    <div class="card-body">
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text my-group-text" style="font-weight: bold">Tempo mín. envio</span>
              </div>
              <input class="form-control" id="descricao" @keyup="validateTime" v-model="minTime" type="number" style="text-transform: uppercase" />
            </div>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text my-group-text" style="font-weight: bold">Tempo max. envio</span>
              </div>
              <input class="form-control" id="descricao" @keyup="validateTime" v-model="maxTime" type="number" style="text-transform: uppercase" />
            </div>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text my-group-text" style="font-weight: bold">Tempo mín. resposta</span>
              </div>
              <input
                class="form-control"
                id="descricao"
                @keyup="validateTime"
                v-model="minTimeResposta"
                type="number"
                style="text-transform: uppercase"
              />
            </div>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text my-group-text" style="font-weight: bold">Tempo max. resposta</span>
              </div>
              <input
                class="form-control"
                id="descricao"
                @keyup="validateTime"
                v-model="maxTimeResposta"
                type="number"
                style="text-transform: uppercase"
              />
            </div>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="form-group">
            <button @click="validateFields()" class="btn btn-success">
              Iniciar aquecimento
              <i style="margin-left: 5px" class="fa-solid fa-fire fire"></i>
            </button>
          </div>
        </div>
      </div>
      <!--  </div> -->
    </div>
  </div>

  <!-- CONEXÕES -->
  <div class="col-md-12">
    <div class="card card-primary">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <label for="exampleSelectRounded0">Selecione as conexões para aquecimento</label>

            <table>
              <thead>
                <tr>
                  <th style="width: 5%"><input v-model="allSelected" type="checkbox" v-on:click="selectAll()" /></th>
                  <th scope="col" style="width: 30%">conexões</th>
                  <th scope="col">ID</th>
                  <th scope="col">Tags</th>
                  <th scope="col">Status</th>
                  <th scope="col">Enviadas</th>
                  <th scope="col">Recebidas</th>
                  <!-- <th scope="col" style="width: 30%">Conexões</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="container in auxContainers"
                  :key="container"
                  :value="container"
                  :class="{ enviando: remetente.id == container.id, recebendo: destinatario.id == container.id }"
                >
                  <td data-label="Região">
                    <input
                      @change="checkConnectedNumber(container)"
                      type="checkbox"
                      :id="container.id"
                      :value="container"
                      v-model="envios_selecionados"
                    />
                  </td>
                  <td data-label="Nome">
                    {{ container.nome }}
                  </td>
                  <td data-label="Nome">
                    {{ container.id }}
                  </td>                  
                  <td data-label="Tags">
                    <span v-for="(tag, index) in container.tags" :style="'margin-right:5px;color: white;background-color:' + tag.cor" class="badge">
                      {{ tag.tag }}
                    </span>
                  </td>
                  <td data-label="Status">
                    <connection-status :my_container="container" :usuario="container.usuario" />
                  </td>
                  <td data-label="Enviado">
                    {{ container.enviados }}
                  </td>
                  <td data-label="Recebido">
                    {{ container.recebidos }}
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- AVISOS E MENSAGENS -->
            <br />
            <h4 style="font-size: 15px">Loop: {{ loop }}</h4>
            <h4 style="font-size: 15px">Passo: {{ parcial }} de {{ totalMatriz }}</h4>
            <div style="font-size: 15px" v-html="messageRemetente"></div>
            <h4 style="font-size: 15px; font-weight: bold">{{ message }}</h4>
            <br />
            <div v-if="messageResposta" style="font-size: 15px" v-html="messageResposta"></div>
            <h4 v-if="messageRespostaTempo" style="font-size: 15px; font-weight: bold">{{ messageRespostaTempo }}</h4>

            <br />
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label>Script de conversa</label>
              <button v-if="editar_script_conversa" @click="editarScript()" class="btn btn-success btn-sm" style="margin-left: 5px">Salvar</button>
              <button v-if="editar_script_conversa" @click="editar_script_conversa = false" class="btn btn-danger btn-sm" style="margin-left: 5px">
                Cancelar
              </button>
              <button v-if="!editar_script_conversa" @click="editar_script_conversa = true" class="btn btn-primary btn-sm" style="margin-left: 5px">
                Editar
              </button>
              <textarea
                :disabled="!editar_script_conversa"
                class="form-control"
                rows="20"
                placeholder="Digite a mensagem que deseja enviar ..."
                style="min-height: 100%"
                v-model="script_conversa"
              ></textarea>
              <span style="font-size: 12px">Total de mensagens: {{ conversa.length }}</span>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>

    <!-- LOGS DE ERRO -->
    <div class="card card-primary" v-if="logsErro && logsErro.length">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12">
            <label for="exampleSelectRounded0">Logs de erro</label>
            <table>
              <thead>
                <tr>
                  <th scope="col" style="width: 10%">Logs</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="log in logsErro" :key="logs" :value="logs">
                  <td data-label="Logs">
                    <div v-html="log"></div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
          </div>
        </div>
      </div>
    </div>

    <!-- LOGS DE ATIVIDADE -->
    <div class="card card-primary" v-if="logs && logs.length">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12">
            <label for="exampleSelectRounded0">Logs de atividade</label>
            <table>
              <thead>
                <tr>
                  <th scope="col" style="width: 10%">Remetente</th>
                  <th scope="col" style="width: 10%">Destinatário</th>
                  <th scope="col" style="width: 30%">Mensagem</th>
                  <th scope="col" style="width: 30%">Resposta</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="logs in logs" :key="logs" :value="logs">
                  <td data-label="Remetente">
                    {{ logs.remetente }}
                  </td>
                  <td data-label="Destinatário">
                    {{ logs.destinatario }}
                  </td>
                  <td data-label="Mensagem">
                    {{ logs.mensagem }}
                  </td>
                  <td data-label="Resposta">
                    {{ logs.resposta }}
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import sendController from "../controller/sendController";

export default {
  props: [
    "my_container",
    "blacklists",
    "containers",
    "usuario",
    "funis",
    "mensagens_padrao",
    "contatos_para_envio",
    "id_mensagem_padrao",
    "aux_list_break",
    "nao_redirecionar",
    "aux_meta_dados",
    "editar",
    "cancelar",
  ],

  mixins: [wppController, sweetAlert, Swal, sendController],

  data() {
    return {
      container: {},
      nome_envio: null,
      mesage_to_send: "",
      url_to_send: null,
      isConnected: false,
      send_to_list: false,
      add_to_funil: false,
      list_break: "CSV",
      listToSend: null,
      contacts: [],
      preview: false,
      funil_selecionado: "",
      tipo_envio: "",
      mensagem_selecionada: "",
      envio_para_grupos: 0,
      blacklist_selecionada: "",
      contador_blacklist: 0,
      dont_remove_duplicated: false,
      envios_selecionados: [],
      resultadoParAPar: [],
      resultadoEmbaralhado: [],
      remetente: [],
      destinatario: [],
      message: "",
      messageRemetente: "",
      messageResposta: "",
      minTime: 3,
      maxTime: 5,
      loop: 0,
      parcial: 0,
      totalMatriz: 0,
      auxContainers: [],
      qtdenviado: [],
      qtdrecebido: [],
      arrayContainers: [],
      logs: [],
      logsErro: [],
      editar_script_conversa: false,
      script_conversa: "",
      conversa: "",
      cont_logs: 0,
      cont_logs_erro: 0,
      timeToSendResposta: 0,
      messageRespostaTempo: 0,
      maxTimeResposta: 5,
      minTimeResposta: 3,
      allSelected: false,
      qtd_loops_simulacao: 2,
    };
  },

  mounted() {
    //this.validarUsuario();

    if (this.containers) {
      this.container = this.containers[0];
    } else {
      this.container = this.my_container;
    }
    this.auxContainers = this.containers;

    for (let i = 0; i < this.auxContainers.length; i++) {
      console.log("entrei no laço");
      this.auxContainers[i].enviados = 0;
      this.auxContainers[i].recebidos = 0;
      this.auxContainers[i].usuario = { porta: this.auxContainers[i].user_porta };  
      this.auxContainers[i].tags = JSON.parse(this.auxContainers[i].tags);
    }

    console.log("Con 6");
    console.log(this.auxContainers[5]);

    this.script_conversa = this.usuario.conversas_aquecimento;
    this.conversa = this.script_conversa.split(/\r?\n/);
  },

  methods: {
    checkConnectedNumber(connection) {
      if (!connection.numero_conectado || connection.numero_conectado == "") {
        let indice = this.findInSelectedContainers(connection);

        console.log("Indice");
        console.log(indice);

        if (indice >= 0) {
          this.envios_selecionados.splice(indice, 1);
        }

        this.showErrorMessageWithButton("Ops...", "Esta conexão não possui telefone definido.");
      }
    },

    validateTime() {
      if (this.minTime > this.maxTime) {
        this.maxTime = this.minTime;
      }

      if (this.minTime < 0) {
        this.minTime = 0;
      }

      if (this.minTimeResposta > this.maxTimeResposta) {
        this.maxTimeResposta = this.minTimeResposta;
      }

      if (this.minTimeResposta < 0) {
        this.minTimeResposta = 0;
      }
    },

    selectAll() {
      if (!this.allSelected) {
        for (let i = 0; i < this.auxContainers.length; i++) {
          if (this.auxContainers[i].numero_conectado && this.auxContainers[i].numero_conectado != "") {
            if (!this.envios_selecionados.includes(this.auxContainers[i])) {
              this.envios_selecionados.push(this.auxContainers[i]);
            }
          }
        }

        this.allSelected = true;
      } else {
        for (let i = 0; i < this.auxContainers.length; i++) {
          let indice = this.findInSelectedContainers(this.auxContainers[i]);

          if (indice >= 0) {
            this.envios_selecionados.splice(indice, 1);
          }
        }

        this.allSelected = false;
      }
    },

    findInSelectedContainers(id) {
      let indice = this.envios_selecionados.findIndex((element) => element == id);

      return indice;
    },

    editarScript() {
      this.isLoading = true;

      let data = {
        conversas_aquecimento: this.script_conversa,
      };

      axios
        .post(`/user/aquecimento/update_conversas`, data)
        .then((response) => {
          this.isLoading = false;

          this.conversa = this.script_conversa.split(/\r?\n/);
          console.log(this.conversa);

          this.editar_script_conversa = false;

          this.showSuccessMessage("Script atualizado!");
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    validarUsuario() {
      let html = "Insira sua senha para acessar o aquecedor";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          input: "password",
          allowOutsideClick: false,
          allowEscapeKey: false,
          inputAttributes: {
            autocapitalize: "off",
            required: false,
          },
          inputValidator: (value) => {
            if (!value) {
              return "Insira sua senha!";
            } else if (value != "123") {
              return "Senha incorreta!";
            }
          },
        })
        .then((result) => {
          if (result.isDismissed) {
            this.redirect("/#");
          }
        });
    },

    preparaCombinacao() {
      this.loop = this.loop + 1;
      this.parcial = 0;
      this.resultadoParAPar = this.combinacaoParAPar(this.envios_selecionados);
      console.log(this.resultadoParAPar);
      this.resultadoEmbaralhado = this.embaralharArray(this.resultadoParAPar);
      this.totalMatriz = this.resultadoEmbaralhado.length;
      console.log(this.resultadoEmbaralhado);
      this.remetente = this.resultadoEmbaralhado[0][0];
      this.destinatario = this.resultadoEmbaralhado[0][1];
      this.preparaSenderReceiver(0);
      //this.simularPreparaSenderReceiver(0);
    },

    simularPreparaSenderReceiver(indice) {
      let sender = this.resultadoEmbaralhado[indice][0];
      let receiver = this.resultadoEmbaralhado[indice][1];

      console.log("SENDER");
      console.log(sender.nome);
      console.log(receiver.nome);

      this.logsErro.push("Enviando de " + sender.nome + " para " + receiver.nome);

      if (indice + 1 < this.resultadoEmbaralhado.length) {
        this.simularPreparaSenderReceiver(indice + 1);
      } else {
        if (this.qtd_loops_simulacao > 0) {
          console.log("\n\nPróximo loop");
          this.logsErro.push("Próximo loop");
          this.qtd_loops_simulacao--;
          this.preparaCombinacao();
        } else {
          console.log("Fim simulação");
        }
      }
    },

    preparaSenderReceiver(indice) {
      //CRIANDO CONDICIONAL PARA RESETAR O INDICE, UMA VEZ QUE O WAIT RODA PRIMEIRO E MUDA O INDICE PRA 1
      console.log("INDICE ATUAL");
      console.log(indice);
      let sender = this.resultadoEmbaralhado[indice][0];
      let receiver = this.resultadoEmbaralhado[indice][1];
      this.remetente = sender; //serve apenas para exibição na tela
      this.destinatario = receiver; //serve apenas para exibição na tela
      console.log("ID ANTES DE CHAMAR O METODO");
      console.log(this.remetente.id);
      console.log(this.destinatario.id);

      console.log("SENDER");
      console.log(this.remetente);
      console.log(this.destinatario);

      this.parcial = this.parcial + 1;

      let newTelefone = this.destinatario.numero_conectado;

      let indice_conversa = this.sortearIndicePar();
      console.log("Indice sorteado");
      console.log(indice_conversa);

      let mensagem = this.conversa[indice_conversa];
      let resposta = this.conversa[indice_conversa + 1];

      this.wait(() => {
        console.log("Retornou no WAIT");

        this.message = "Enviando agora...";

        //TESTE
        //this.logs.push("Mensagem enviada de " + this.remetente.nome + " para " + this.destinatario.nome);

        /*
        this.logs.push({
          id: this.cont_logs,
          remetente: this.remetente.nome,
          destinatario: this.destinatario.nome,
          mensagem: mensagem,
          resposta: resposta,
        });

        this.cont_logs++;

        this.logs.sort(this.compare);

        if (this.logs.length >= 10) {
          this.logs.pop();
        }

        let indiceEnviado = this.findCorrelacao(this.remetente.id);
        let indiceRecebido = this.findCorrelacao(this.destinatario.id);
        console.log("INDICES ENVIADO E RECEBIDO");
        console.log(indiceEnviado);
        console.log(indiceRecebido);
        this.auxContainers[indiceEnviado].enviados = this.auxContainers[indiceEnviado].enviados + 1;
        this.auxContainers[indiceRecebido].recebidos = this.auxContainers[indiceRecebido].recebidos + 1;

        this.message = "Mensagem enviada";

        this.responder(this.destinatario, this.remetente, resposta, () => {
          this.messageRespostaTempo = "Respondido";

          if (indice + 1 < this.resultadoEmbaralhado.length) {
            this.preparaSenderReceiver(indice + 1);
          } else {
            this.preparaCombinacao();
          }
        });
*/
        //TESTE

        this.validarEEnviar(this.remetente, newTelefone, mensagem, sender.chave_api, sender.porta, () => {
          this.logs.push({
            id: this.cont_logs,
            remetente: this.remetente.nome,
            destinatario: this.destinatario.nome,
            mensagem: mensagem,
            resposta: resposta,
          });

          this.cont_logs++;

          this.logs.sort(this.compare);

          if (this.logs.length >= 10) {
            this.logs.pop();
          }

          console.log("ID DENTRO METODO");
          console.log(this.remetente.id);
          console.log(this.destinatario.id);
          let indiceEnviado = this.findCorrelacao(this.remetente.id);
          let indiceRecebido = this.findCorrelacao(this.destinatario.id);
          console.log("INDICES ENVIADO E RECEBIDO");
          console.log(indiceEnviado);
          console.log(indiceRecebido);
          this.auxContainers[indiceEnviado].enviados = this.auxContainers[indiceEnviado].enviados + 1;
          this.auxContainers[indiceRecebido].recebidos = this.auxContainers[indiceRecebido].recebidos + 1;

          this.message = "Mensagem enviada";

          this.responder(this.destinatario, this.remetente, resposta, () => {
            this.messageRespostaTempo = "Respondido";

            if (indice + 1 < this.resultadoEmbaralhado.length) {
              this.preparaSenderReceiver(indice + 1);
            } else {
              this.preparaCombinacao();
            }
          });
        });
      });
    },

    responder(remetente, destinatario, resposta, callback) {
      //INDICES ESTÃO INVERTIDOS, POIS AGORA O DESTINATARIO IRA RESPONDER AO REMETENTE
      let indiceEnviado = this.findCorrelacao(destinatario.id);
      let indiceRecebido = this.findCorrelacao(remetente.id);

      this.auxContainers[indiceEnviado].enviados = this.auxContainers[indiceEnviado].enviados + 1;
      this.auxContainers[indiceRecebido].recebidos = this.auxContainers[indiceRecebido].recebidos + 1;

      this.waitResposta(remetente, destinatario, () => {
        console.log("Wait da resposta finalizado");

        this.messageRespostaTempo = "Respondendo agora...";

        this.validarEEnviar(
          this.destinatario,
          this.remetente.numero_conectado,
          resposta,
          this.destinatario.chave_api,
          this.destinatario.porta,
          () => {
            callback();
          }
        );
      });
    },

    compare(a, b) {
      if (a.id > b.id) {
        return -1;
      }
      if (a.id < b.id) {
        return 1;
      }
      return 0;
    },

    findCorrelacao(id) {
      console.log("ID ATUAL");
      console.log(id);
      let indice = this.auxContainers.findIndex((element) => element["id"] == id);

      return indice;
    },

    validarEEnviar(remetente, numero, texto, id_api, porta, callback) {
      this.validarNumero(numero, id_api, porta, (validNumber, timeout) => {
        if (validNumber) {
          this.enviar("texto", validNumber, texto, "", id_api, porta, (timeout, phone, sucess) => {
            this.getResult(timeout, phone, sucess, callback);
          });
        } else if (timeout) {
          this.logsErro.push("Falha no envio... Verifique a conexão de <b>" + remetente.nome + "</b>");
          this.logsErro.push("Refazendo sorteio");

          this.showErrorMessage("Falha no envio... Verifique a conexão de <b>" + remetente.nome + "</b>");

          let indice = this.findInSelectedContainers(remetente);

          if (indice >= 0) {
            this.envios_selecionados.splice(indice, 1);
          }

          this.validateFields();
        } else {
          this.logsErro.push("Falha inesperada no " + remetente.nome);
        }
      });
    },

    getResult(timeout, phone, sucess, callback) {
      if (timeout) {
        this.logsErro.push("Falha no envio... Verifique a conexão de <b>" + remetente.nome + "</b>");
        this.logsErro.push("Refazendo sorteio");

        this.showErrorMessage("Falha no envio... Verifique a conexão de <b>" + remetente.nome + "</b>");

        let indice = this.findInSelectedContainers(remetente);

        if (indice >= 0) {
          this.envios_selecionados.splice(indice, 1);
        }

        this.validateFields();
      } else {
        //Verificando se foi enviado com sucesso
        console.log("Checking status...");

        if (sucess) {
          callback();
        } else {
          this.logsErro.push("Falha no envio... Verifique a conexão de <b>" + remetente.nome + "</b>");
          this.logsErro.push("Refazendo sorteio");

          this.showErrorMessage("Falha no envio... Verifique a conexão de <b>" + remetente.nome + "</b>");

          let indice = this.findInSelectedContainers(remetente);

          if (indice >= 0) {
            this.envios_selecionados.splice(indice, 1);
          }

          this.validateFields();
        }
      }
    },

    wait(callback) {
      this.timeToSend = this.getTime();

      let that = this;

      for (let i = 0; i <= this.timeToSend; i++) {
        setTimeout(function () {
          if (that.timeToSend > 0) {
            that.messageRemetente =
              "Enviando de <b style='color: #22ca80'>" +
              that.remetente.nome +
              "</b> para <b style='color: rgb(52, 52, 223)'>" +
              that.destinatario.nome +
              "</b>";
            that.message = "Próxima mensagem em " + that.timeToSend + " segundos";
            that.timeToSend -= 1;
          } else {
            callback();
          }
        }, i * 1000);
      }
    },

    waitResposta(remetente, destinatario, callback) {
      this.timeToSendResposta = this.getTimeResposta();

      let that = this;

      for (let i = 0; i <= this.timeToSendResposta; i++) {
        setTimeout(function () {
          if (that.timeToSendResposta > 0) {
            that.messageResposta =
              "Respondendo de <b style='color: rgb(52, 52, 223)'>" +
              remetente.nome +
              "</b> para <b style='color: #22ca80'>" +
              destinatario.nome +
              "</b>";
            that.messageRespostaTempo = "Respondendo em " + that.timeToSendResposta + " segundos";
            that.timeToSendResposta -= 1;
          } else {
            callback();
          }
        }, i * 1000);
      }
    },

    sorteiaFrases() {
      return this.frases[Math.floor(Math.random() * this.frases.length)];
    },

    sortearIndicePar() {
      let number = Math.floor(Math.random() * (this.conversa.length - 0 + 1)) + 0;

      if (number % 2 != 0) {
        if (number == this.conversa.length) {
          number = number - 1;
        } else {
          number = number + 1;
        }
      }

      return number;
    },

    //COMBINAÇÃO PARA OS PARES DE NUMEROS
    combinacaoParAPar(numeros) {
      var resultado = [];

      for (var i = 0; i < numeros.length; i++) {
        for (var j = 0; j < numeros.length; j++) {
          if (i !== j) {
            resultado.push([numeros[i], numeros[j]]);
          }
        }
      }

      return resultado;
    },

    // Função de embaralhamento
    embaralharArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        // Troca os elementos nas posições i e j
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },

    //FIM DA COMBINAÇÃO

    getTime() {
      let time = Math.floor(Math.random() * (this.maxTime - this.minTime + 1)) + this.minTime;

      return time;
    },

    getTimeResposta() {
      let time = Math.floor(Math.random() * (this.maxTimeResposta - this.minTimeResposta + 1)) + this.minTimeResposta;

      return time;
    },

    redirect(page) {
      window.location.href = page;
    },

    findFunil(id) {
      let indice = this.funis.findIndex((element) => element["id"] == id);

      return indice;
    },

    findMensagemPadrao(id) {
      let indice = this.mensagens_padrao.findIndex((element) => element["id"] == id);

      return indice;
    },

    validateFields() {
      this.message = "";
      this.messageRespostaTempo = "";
      this.messageResposta = "";
      this.messageRemetente = "";

      if (this.envios_selecionados == 0) {
        this.showErrorMessageWithButton("Ops...", "selecione as conexões para aquecimento!");
      } else if (this.envios_selecionados.length < 2) {
        this.showErrorMessageWithButton("Ops...", "O aquecimento precisa de pelo menos 2 conexões!");
      } else {
        this.preparaCombinacao();
      }
    },
  },
};
</script>

<style scoped>
.fire {
  font-size: 15px;
  background: rgb(254, 181, 1);
  background: linear-gradient(90deg, rgba(254, 181, 1, 1) 29%, rgba(255, 211, 2, 1) 46%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.enviando {
  background: #22ca80 !important;
  color: white !important;
}

.recebendo {
  background: rgb(52, 52, 223) !important;
  color: white !important;
}

.acompanhamento {
  margin-left: 30px;
  margin: 10px;
  padding: 10px;
  width: 100%;
}

.loop {
  color: red;
}

.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table tr:nth-child(even) {
  background: #fff;
}

table tr:nth-child(odd) {
  background: #eee;
}

table.pdi tr:nth-child(even) {
  background: #fff;
}

table.pdi tr:nth-child(odd) {
  background: #80f0af;
}

table caption {
  font-size: 1.2em;
  margin: 0.1em 0 0.5em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.1em;
}

table th,
table td {
  padding: 0.2em;
  text-align: center;
  font-size: 0.9em;
}

table th {
  font-size: 0.7em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    padding: 0.4em;
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.9em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

.cursor {
  cursor: pointer;
}
</style>
