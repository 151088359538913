<template>
  <div>
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />
    <button data-toggle="modal" data-target="#login-modal" type="button" class="btn btn-success">
      Nova conexão
      <i style="margin-left: 5px" class="fa fa-plus"></i>
    </button>

    <div class="modal fade" id="login-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <form @submit.prevent>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              <h4>Nova conexão</h4>
              <div class="form-group">
                <label for="email">Nome</label>
                <input id="nome" type="text" class="form-control" v-model="nome" autocomplete="name" />
              </div>

              <div class="form-group" v-if="user.somente_conexao == 0">
                <label for="password">Nome pm2</label>
                <input id="senha" type="senha" class="form-control" v-model="nome_pm2" autocomplete="nome_pm2" />
              </div>

              <div class="form-group" v-if="user.somente_conexao == 0">
                <label for="password">Porta</label>
                <input id="porta" type="porta" class="form-control" v-model="porta" autocomplete="porta" />
              </div>

              <button @click="validateFields()" class="btn btn-success btn-sm">
                Cadastrar
                <i style="margin-left: 5px" class="fa fa-check"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [wppController, sweetAlert, Swal],

  props: ["user"],

  components: { Loading },

  data() {
    return {
      container: {},
      nome: "",
      porta: "",
      nome_pm2: "",
      isLoading: false,
      api_name: "",
      tipo: "",
    };
  },

  mounted() {
    console.log("Nova");
    console.log(this.user);

    this.nome_pm2 = this.user.url;
    this.porta = this.user.porta;
  },

  methods: {
    validateFields() {
      if (!this.nome) {
        this.showErrorMessageWithButton("Ops...", "O campo nome é obrigatório!");
      } else if (this.nome_pm2 == "") {
        this.showErrorMessageWithButton("Ops...", "O nome PM2 não pode ficar em branco!");
      } else {
        this.create();
      }
    },

    create() {
      this.isLoading = true;

      let data = {
        user_id: this.user.id,
        nome: this.nome,
        porta: this.porta,
        nome_pm2: this.nome_pm2,
        chave: this.generateKey(),
      };

      console.log("CRIANDO");
      console.log(data);

      axios
        .post(`/user/connection/store`, data)
        .then((response) => {
          this.showSuccessMessage("Nova conexão criada!");

          window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    validateEmail() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        return true;
      } else {
        return false;
      }
    },

    generateKey() {
      return Math.random().toString(36).slice(2);
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
