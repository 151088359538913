<template>
  <div>
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />
    <button data-toggle="modal" data-target="#login-modal" type="button" class="btn btn-success">
      Nova ação<i class="nav-icon far fa-plus-square"></i>
    </button>

    <div class="modal fade" id="login-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true">
      <form @submit.prevent>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                &times;
              </button>
              <h4>Nova ação</h4>
              <br />

              <div class="form-group">
                <label>Nome</label>
                <input class="form-control" placeholder="Digite o nome da ação" v-model="nome" />
              </div>

              <button @click="cadastrar()" class="btn btn-primary btn-sm">
                Cadastrar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [wppController, sweetAlert, Swal],

  components: { Loading },

  data() {
    return {
      nome: "",
      isLoading: false,
      planilha_selecionada: ""
    };
  },

  mounted() { },

  methods: {

    addTag(tag) {
      this.mensagem = this.mensagem + tag;
    },

    cadastrar() {

      let data = {
        acao: this.nome,
      };

      console.log("CRIANDO");
      console.log(data);

      axios
        .post(`/admin/acoes/store`, data)
        .then((response) => {

          this.showSuccessMessage("Planilha criada!");

          window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },
  },
};
</script>

<style scoped>
.div-right {
  padding-left: 0px;
}

.div-center {
  padding-right: 0px;
  padding-left: 0px;
}

.div-left {
  padding-right: 0px;
}

@media (max-width: 600px) {
  .div-right {
    padding: 1px 1px 0px 1px;
  }

  .div-center {
    padding: 1px 1px 0px 1px;
  }

  .div-left {
    padding: 1px 1px 0px 1px;
  }
}

.tags {
  width: 100%;
  border-style: solid;
  border-color: black;
  color: white;
  background-color: gray;
  border-width: 2px;
  margin: 0.5px 0.5px 2px 0.5px;
  padding: 0px;
}

.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
