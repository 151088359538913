<template>
  <div class="table-responsive">
    <table id="zero_config" class="table table-striped table-bordered no-wrap">
      <thead>
        <tr>
          <th>Nome Dono</th>
          <th>Telefone</th>
          <th>Total Contatos</th>
          <th>Data de cadastro</th>
          <th>Detalhes</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="lista_contatos in listas_contatos" :key="lista_contatos" :value="lista_contatos">
          <td>{{ lista_contatos.nome_dono }}</td>

          <td>{{ lista_contatos.telefone_dono }}</td>

          <td>{{ lista_contatos.total_contatos }}</td>

          <td>
            {{ formatDate(lista_contatos.data_cadastro) }}
          </td>

          <td>
            <button @click="selecionarContato(lista_contatos)" data-toggle="modal" data-target="#detalhes-modal"
              type="button" class="btn btn-primary btn-flat">
              Detalhes <i class="fa fa-search"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div v-if="contato_selecionado" class="modal fade" id="detalhes-modal" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <form @submit.prevent>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
              &times;
            </button>
            <h4>Detalhes</h4>
            <br />
            <div class="col-lg-12">
              <div class="mb-4">
                <p>

                  <button class="btn btn-primary ml-1" type="button" data-toggle="collapse" data-target="#collapseExample"
                    aria-expanded="false" aria-controls="collapseExample">
                    Exibir Lista de contatos
                  </button>

                  <button class="btn btn-success" @click="ExportExcel('xlsx')">Baixar Excel xlsx</button>
                </p>
                <div class="collapse" id="collapseExample">
                  <div class="card card-body">
                    <table id="myTable" ref="exportable_table" class="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th style="width: 25%">Nome</th>
                          <th style="width: 25%">Telefone</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="contato in contatos" :key="contato">
                          <td data-label="Nome">{{ contato.telefone }}</td>

                          <td data-label="Telefone">{{ contato.nome }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import sweetAlert from "../controller/sweetAlert";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  mixins: [sweetAlert, Swal],

  props: ['listas_contatos'],

  data() {
    return {
      total_cadastrados: 0,
      indicacoes_recebidas: 0,
      total_positivas: 0,
      total_negativas: 0,
      contato_selecionado: "",
      contatos: []
    };
  },

  mounted() {
    console.log(this.contatos);
  },

  methods: {

    ExportExcel(type, fn, dl) {
      var elt = this.$refs.exportable_table;
      var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      return dl ?
        XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }) :
        XLSX.writeFile(wb, fn || (('Contatos ' + this.contato_selecionado.nome_dono + '.' || 'SheetJSTableExport.') + (type || 'xlsx')));
    },

    totalContatos(lista_contatos) {
      try {
        console.log("Tentando validar");
        console.log(lista_contatos);
        let jsonValido = JSON.parse(lista_contatos);

        console.log("JS Válido");
        console.log(jsonValido);

        if (jsonValido) {
          return jsonValido.length;
        } else {
          return 0;
        }
      } catch (e) {
        console.log("Erro ao validar JSON");
        console.log(e);
        return 0;
      }
    },

    formatarListaContatos(lista_contatos) {
      try {
        console.log("Tentando validar");
        console.log(lista_contatos);
        let jsonValido = JSON.parse(lista_contatos);

        console.log("JS Válido");
        console.log(jsonValido);

        if (jsonValido) {
          return jsonValido;
        } else {
          return null;
        }
      } catch (e) {
        console.log("Erro ao validar JSON");
        console.log(e);
        return null;
      }
    },

    formatarLogradouro(contato) {
      return contato.desc_tipo_logradouro + " " + contato.logradouro + " , " + contato.numero;
    },

    selecionarContato(contato) {
      this.contato_selecionado = contato;

      this.contatos = this.formatarListaContatos(contato.contatos);
    },

    getUrl(telefone) {
      return "https://api.whatsapp.com/send?phone=" + telefone;
    },

    converterCNPJ(possui_cnpj) {
      if (possui_cnpj == 0) {
        return "Não";
      } else {
        return "Sim";
      }
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },

    confirmarNegativo(lead) {
      let html = "Acrescente um comentário à sua avaliação <b style='color:red'> Negativa?</b>";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off',
            required: false
          },
          inputValidator: (value) => {
            if (!value) {
              return 'Coloque uma justificativa!'
            }
            else {
              this.confirmar(lead, value, "NEGATIVA")
            }
          }
        })
    },

    confirmarPositivo(lead) {
      let html = "Que bom! Deseja acrescentar algum comentário à sua avaliação <b style='color:green'> Positiva?</b>";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off',
          },
        })
        .then(result => {
          if (result.isConfirmed) {
            this.confirmar(lead, result.value, "POSITIVA");
          }
        });
    },

    atualizarStatus(lead) {
      let data = {
        lead_id: lead.id,
        status: lead.status_negociacao,
      }

      axios
        .post(`/user/leads/status/update`, data)
        .then((response) => {
          this.showSuccessMessage("Status atualizado!");
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    confirmar(lead, comentario, avaliacao) {
      let data = {
        lead_id: lead.id,
        comentario: comentario,
        avaliacao: avaliacao
      }

      axios
        .post(`/user/leads/avaliar`, data)
        .then((response) => {
          this.showSuccessMessage("Lead avaliado!");
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },
  },
};
</script>
