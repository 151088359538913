<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

  <div
    @click.self="cancelModal"
    class="modal fade"
    id="create-container-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <form @submit.prevent>
      <div class="modal-dialog" style="text-align: left">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            <h4>Nova conexão</h4>

            <div class="form-group">
              <label for="user_type">Usuário</label>
              <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="formData.user_id" @change="selecionarUsuario">
                <option v-for="user in users" :value="user.id" :key="users.id">{{ user.name }}</option>
              </select>

              <span style="color: red" v-for="error in v$.user_id.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="email">Nome</label>
              <input id="nome" type="text" class="form-control" v-model="formData.nome" autocomplete="name" />

              <span style="color: red" v-for="error in v$.nome.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">Servidor</label>
              <input id="porta" type="porta" class="form-control" v-model="formData.servidor" autocomplete="porta" />

              <span style="color: red" v-for="error in v$.servidor.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">Porta</label>
              <input id="porta" type="porta" class="form-control" v-model="formData.porta" autocomplete="porta" />
            </div>

            <button @click="store()" class="btn btn-success btn-sm">Salvar</button>

            <button
              id="close_create_container"
              style="margin-left: 5px"
              class="btn btn-danger btn-sm"
              data-toggle="modal"
              data-target="#create-container-modal"
              @click="setCriarContainer(false)"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import wppController from "../../controller/wppController.js";
import sweetAlert from "../../controller/sweetAlert.js";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import useVueLidate from "@vuelidate/core";
import { required } from "../../locales/i18n.js";
import { computed, reactive } from "vue";

export default {
  mixins: [wppController, sweetAlert, Swal],

  components: { Loading },

  props: ["container", "setCriarContainer", "containerCriado", "user_selecionado", "users"],

  data() {
    return {
      nome: "",
      email: "",
      senha: "",
      isLoading: false,
      my_container: {},
    };
  },

  setup() {
    const formData = reactive({
      nome: "",
      porta: "",
      servidor: "",
      user_id: "",
    });

    const rules = computed(() => {
      return {
        nome: { required },
        servidor: { required },
        user_id: { required },
      };
    });

    const v$ = useVueLidate(rules, formData);

    return { formData, v$ };
  },

  mounted() {
    if (this.user_selecionado) {
      this.formData.user_id = this.user_selecionado.id;
      this.selecionarUsuario();
    }
  },

  methods: {
    selecionarUsuario() {
      if (this.formData.user_id) {
        let indice = this.users.findIndex((element) => element["id"] == this.formData.user_id);

        this.formData.porta = this.users[indice].porta;
        this.formData.servidor = this.users[indice].url;
      }
    },

    cancelModal() {
      this.setCriarContainer(false);
    },

    async store() {
      const result = await this.v$.$validate();

      if (result) {
        this.isLoading = true;

        let data = {
          user_id: this.formData.user_id,
          nome: this.formData.nome,
          porta: this.formData.porta,
          nome_pm2: this.formData.servidor,
        };

        if (process.env.MIX_VUE_APP_MAX_CONNECTIONS) {
          data.max_connections = process.env.MIX_VUE_APP_MAX_CONNECTIONS;
        }

        axios
          .post(`/user/connection/store`, data)
          .then((response) => {
            this.isLoading = false;

            this.showSuccessMessage("Conexão criada!");
            this.containerCriado();
          })
          .catch((error) => {
            this.isLoading = false;
            this.showErrorMessageWithButton("Ops..", error.response.data);
            console.log(error.response.data);
          });
      }
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
