<template>
  <div>
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />
    <button data-toggle="modal" data-target="#login-modal" type="button" class="btn btn-success">
      Nova Mensagem <i class="nav-icon far fa-plus-square"></i>
    </button>

    <div class="modal fade" id="login-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true">
      <form @submit.prevent>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                &times;
              </button>
              <h4>Nova mensagem</h4>
              <br />

              <div class="form-group">
                <label>Usuário</label>
                <select class="form-control" v-model="usuario_selecionado" name="funil" style="width: 100%">
                  <option v-for="usuario in usuarios" :key="usuario.id" :value="usuario">
                    {{ usuario.name }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label>Nome da mensagem</label>
                <input class="form-control" placeholder="Digite o nome da mensagem" v-model="nome" />
              </div>

              <div class="form-group">
                <label for="exampleInputEmail1">Mensagem</label><br />
                <div class="row">
                  <div style="margin-bottom: 2px;" class="col-md-12 col-sm-12 col-12">
                    <textarea class="form-control" placeholder="Digite sua mensagem" v-model="mensagem" rows="4"
                      cols="40" />
                  </div>
                  <div class="div-left col-md-4 col-sm-6 col-12">
                    <button @click="addTag('<NomePaciente>')" class="tags btn btn-sm">
                      Nome Paciente
                    </button>
                  </div>
                  <div class="div-center col-md-4 col-sm-6 col-12">
                    <button @click="addTag('<Data>')" class="tags btn btn-sm">
                      Data Atendimento
                    </button>
                  </div>
                  <div class="div-right col-md-4 col-sm-6 col-12">
                    <button @click="addTag('<NomeMedico>')" class="tags btn btn-sm">
                      Nome Médico
                    </button>
                  </div>
                  <div class="div-left col-md-4 col-sm-6 col-12">
                    <button @click="addTag('<Procedimento>')" class="tags btn btn-sm">
                      Procedimento
                    </button>
                  </div>
                  <div class="div-center col-md-4 col-sm-6 col-12">
                    <button @click="addTag('<Horario>')" class="tags btn btn-sm">
                      Horário
                    </button>
                  </div>
                  <div class="div-right col-md-4 col-sm-6 col-12">
                    <button @click="addTag('<Convenio>')" class="tags btn btn-sm">
                      Convenio
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleSelectRounded0">Mensagem padrão?</label>
                <select v-model="mensagem_padrao" class="custom-select rounded-0">
                  <option :value="false">Não</option>
                  <option :value="true">Sim</option>
                </select>
              </div>
              <div class="form-group">
                <label for="exampleSelectRounded0">Tag</label>
                <select v-model="tag" class="custom-select rounded-0">
                  <option value=""></option>
                  <option value="Aniversariantes">Aniversariantes</option>
                  <option value="Atendidos">PDI</option>
                  <option value="Atendidos">PDI 15 dias</option>
                  <option value="Agendados">Agendados</option>
                  <option value="Agendados do dia">Agendados do dia</option>
                  <option value="Agendados 1 dia antes">Agendados 1 dia</option>
                  <option value="Agendados 2 dias antes">Agendados 2 dias</option>
                  <option value="Agendados 3 dias antes">Agendados 3 dias</option>
                  <option value="Agendados 7 dias antes">Agendados 7 dias</option>
                  <option value="Exames 1 dia depois">Exames 1 dia depois</option>
                  <option value="Exames 1 dia depois - RETORNO">Exames 1 dia depois - RETORNO</option>
                </select>
              </div>
              <button @click="cadastrar()" class="btn btn-primary btn-sm">
                Cadastrar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [wppController, sweetAlert, Swal],

  props: ["usuarios"],

  data() {
    return {
      container: {},
      nome: "",
      mensagem: "",
      tag: "",
      isLoading: false,
      mensagem_padrao: false,
      usuario_selecionado: ""
    };
  },

  mounted() {
    console.log("USUÁRIOS AQUI");
    console.log(this.usuarios);
  },

  methods: {

    addTag(tag) {
      this.mensagem = this.mensagem + tag;
    },

    cadastrar() {

      let data = {
        nome: this.nome,
        usuario_id: this.usuario_selecionado.id,
        mensagem: this.mensagem,
        mensagem_padrao: this.mensagem_padrao,
        tag: this.tag,
      };

      console.log("CRIANDO");
      console.log(data);

      axios
        .post(`/admin/mensagens-padrao/create`, data)
        .then((response) => {
          console.log("Usuário criado!");
          console.log(response);

          this.showSuccessMessage("Mensagem criada!");

          window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    validateEmail() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        return true;
      } else {
        return false;
      }
    },

    generateKey() {
      return Math.random().toString(36).slice(2);
    },
  },
};
</script>

<style scoped>
.div-right {
  padding-left: 0px;
}

.div-center {
  padding-right: 0px;
  padding-left: 0px;
}

.div-left {
  padding-right: 0px;
}

@media (max-width: 600px) {
  .div-right {
    padding: 1px 1px 0px 1px;
  }

  .div-center {
    padding: 1px 1px 0px 1px;
  }

  .div-left {
    padding: 1px 1px 0px 1px;
  }
}

.tags {
  width: 100%;
  border-style: solid;
  border-color: black;
  color: white;
  background-color: gray;
  border-width: 2px;
  margin: 0.5px 0.5px 2px 0.5px;
  padding: 0px;
}

.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
