<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

  <br />
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Filtrar</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <label for="endereco_estado">Página atual</label>

            <select class="custom-select rounded-0" v-model="pagina_selecionada" @change="buscarLeads()">
              <option value=""></option>
              <option v-for="pagina in paginas" :key="pagina" :value="pagina">
                {{ pagina }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group">
            <label for="endereco_estado">Leads por página</label>

            <select class="custom-select rounded-0" v-model="leads_por_pagina_selecionado" @change="buscarLeads()">
              <option value=""></option>
              <option v-for="leads in leads_por_pagina" :key="leads" :value="leads">
                {{ leads }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-12">
          <button type="submit" class="btn btn-primary" @click="searchContainers">Buscar</button>
        </div>
      </div>
    </div>
  </div>

  <button
   @click="ExportExcel('xlsx')"
    type="button"
    class="btn btn-success"
  >
  Download
  </button>
  <!-- TABELA USUÁRIOS-->
  <div class="card card-primary">
    <div class="card-header">
      <h3 class="card-title">
        Leads
      </h3>
    </div>
    <div class="card-body p-0">
      <table class="table"  ref="exportable_table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Nome</th>
            <th>Whatsapp</th>
            <th>E-mail</th>
            <th>Cnpj</th>
            <th>Adicionais</th>
            <th>stage_changed_date</th>
            <th>Criado em</th>
            <th>Atualizado em</th>
            <th>Criado recentemente?</th>
            <th>Estágio</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="lead in dados_leads" :key="lead">
            <td>{{ lead.id }}</td>
            <td>{{ lead.display_name }}</td>
            <td>{{ lead.cf_whatsapp }}</td>
            <td>{{ lead.cf_email }}</td>
            <td>{{ lead.cf_possuicnpj }}</td>
            <td>{{ lead.cf_adicionais }}</td>
            <td>
              <span>
                {{ formatDate(lead.stage_changed_date) }}
              </span>
            </td>
            <td>
              <span>
                {{ formatDate(lead.created_at) }}
              </span>
            </td>
            <td>
              <span>
                {{ formatDate(lead.updated_at) }}
              </span>
            </td>
            <td>{{ lead.was_recently_created }}</td>

            <td>
              <span v-if="lead.stage">
                {{ lead.stage.name }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import wppController from "./controller/wppController";
import sendController from "./controller/sendController";
import sweetAlert from "./controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [wppController, sendController, sweetAlert, Swal],

  components: { Loading },

  props: ["users"],

  data() {
    return {
      container: {},
      nome: "",
      email: "",
      senha: "",
      isLoading: false,
      container_selecionado: "",
      containers: [],
      acoes_usuario: [],
      acao_selecionada: "",
      filtro: "",
      paginas: [],
      inicio: 0,
      total: 0,
      qtd_por_pagina: 25,
      pagina_atual: 1,
      editar_container: false,
      criar_container: false,
      user_selecionado: "",
      telefone_teste: "",
      mensagem_teste_envio: "",
      tag: "",
      cor_tag: "#FF0000",
      array_tags: [],
      array_historico: [],
      data_tag: "",
      tag_padrao_option: "",
      ultima_atualizacao_status: "",
      numero_conectado: "",
      nome_container: "",
      array_tags_padrao: [],
      dados_leads: "",
      leads_por_pagina: [],
      pagina_selecionada: "1",
      leads_por_pagina_selecionado: "50",
    };
  },

  mounted() {
    this.buscarLeads();

    this.leads_por_pagina.push(25, 50, 100, 200, 300, 500);

    for (let i = 0; i < 50; i++) {
      this.paginas.push(i);
    }
  },

  methods: {
    ExportExcel(type, fn, dl) {
      var elt = this.$refs.exportable_table;
      var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(wb, fn || ("Leads." || "SheetJSTableExport.") + (type || "xlsx"));
    },

    buscarLeads() {
      this.isLoading = true;

      let data = {
        pagina: this.pagina_selecionada,
        leads_por_pagina: this.leads_por_pagina_selecionado,
      };

      console.log(data);

      axios
        .post(process.env.MIX_VUE_APP_ENDPOINT_CASA_NEGOCIOS + `/search`, data)
        .then((response) => {
          this.isLoading = false;

          this.dados_leads = response.data.leads.data;

          console.log(response.data);
        })
        .catch((error) => {
          this.isLoading = false;

          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}

.modal-content {
  width: 1100px;
  margin-left: -250px;
}

@media screen and (max-width: 1200px) {
  .modal-content {
    width: 800px;
    margin-left: -20px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 1100px) {
  .modal-content {
    width: 600px;
    margin-left: -20px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 960px) {
  .modal-content {
    width: 750px;
    margin-left: -120px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 800px) {
  .modal-content {
    width: 600px;
    margin-left: -50px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 600px) {
  .modal-content {
    width: 100%;
    margin-left: 0;
    margin-bottom: 100px;
  }
}
</style>
