<template>
  <br />
  <!-- general form elements -->
  <div class="card card-primary">
    <div class="card-header">
      <h3 class="card-title">Nova campanha</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="exampleInputEmail1">Nome da campanha</label>
            <input class="form-control" v-model="formData.nome" />

            <span style="color: red" v-for="error in v$.nome.$errors" :key="error.$uid">
              {{ error.$message }}
            </span>
          </div>

          <div>
            <div class="form-group">
              <label>Mensagem</label>
              <textarea class="form-control" rows="3" placeholder="Digite a mensagem que deseja enviar ..." v-model="mesage_to_send"></textarea>
            </div>
          </div>

          <div>
            <label>Telefones</label>
            <textarea class="form-control" rows="3" placeholder="Digite os telefones ..." v-model="listToSend"></textarea>

            <div class="row">
              <div class="col-1"></div>
              <div class="col-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="CSV" checked v-model="list_break" />
                  <label class="form-check-label">CSV</label>
                </div>
              </div>
              <div class="col-1"></div>
              <div class="col-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="radio1" value="Excel" v-model="list_break" />
                  <label class="form-check-label">Excel</label>
                </div>
              </div>
              <div class="col-1"></div>
              <div class="col-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="radio1" value="JSON" v-model="list_break" />
                  <label class="form-check-label">JSON</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12"><label>Intervalo entre as mensagens (segundos)</label></div>
        <div class="col-sm-3">
          <div class="form-group">
            <label class="form-check-label">De</label>
            <input class="form-control" v-model="formData.intevalo_minimo" />

            <span style="color: red" v-for="error in v$.intevalo_minimo.$errors" :key="error.$uid">
              {{ error.$message }}
            </span>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label class="form-check-label">Até</label>
            <input class="form-control" v-model="formData.intevalo_maximo" />

            <span style="color: red" v-for="error in v$.intevalo_maximo.$errors" :key="error.$uid">
              {{ error.$message }}
            </span>
          </div>
        </div>
        <div class="col-sm-12"></div>
        <div class="col-sm-6">
          <div class="form-group">
            <label>Tempo de pausa (minutos)</label>
            <input class="form-control" v-model="formData.tempo_pausa" />
            <span style="color: red" v-for="error in v$.tempo_pausa.$errors" :key="error.$uid">
              {{ error.$message }}
            </span>
          </div>
          <div class="form-group">
            <label>Envios antes da pausa</label>
            <input class="form-control" v-model="formData.qtd_envios_pausa" />
            <span style="color: red" v-for="error in v$.qtd_envios_pausa.$errors" :key="error.$uid">
              {{ error.$message }}
            </span>
          </div>
        </div>
        <!-- FORM -->
      </div>
    </div>

    <div class="card-footer">
      <button @click="validateFields()" class="btn btn-success">Criar campanha</button>
    </div>
  </div>
  <!-- /.card-  body -->
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { required } from "../locales/i18n.js";
import { computed, reactive } from "vue";
import useVueLidate from "@vuelidate/core";

export default {
  props: ["container", "user"],

  mixins: [wppController, sweetAlert, Swal],

  setup() {
    const formData = reactive({
      nome: "",
      intevalo_minimo: "",
      intevalo_maximo: "",
      qtd_envios_pausa: "",
      tempo_pausa: "",
    });

    const rules = computed(() => {
      return {
        nome: { required },
        intevalo_minimo: { required },
        intevalo_maximo: { required },
        qtd_envios_pausa: { required },
        tempo_pausa: { required },
      };
    });

    const v$ = useVueLidate(rules, formData);

    return { formData, v$ };
  },

  data() {
    return {
      dont_remove_duplicated: true,
      isLoading: false,
      listToSend: "",
      list_break: "CSV",
      mesage_to_send: "",
      blacklist_selecionada: "",
      contacts: [],
    };
  },

  mounted() {
    if (this.containers) {
      this.container = this.containers[0];
    } else {
      this.container = this.my_container;
    }

    console.log("Create Send Ready");
    console.log(this.id_mensagem_padrao);
  },

  methods: {
    selecionarMensagem() {
      if (this.mensagem_selecionada != "") {
        this.nome_envio = this.mensagem_selecionada.nome;
        this.url_to_send = this.mensagem_selecionada.url;
        this.mesage_to_send = this.mensagem_selecionada.mensagem;
        this.tipo_envio = this.mensagem_selecionada.tipo;

        if (this.aux_meta_dados) {
          this.meta_dados = JSON.stringify(this.aux_meta_dados);

          console.log("Meta no envio");
          console.log(this.aux_meta_dados);
        }

        if (this.mensagem_selecionada.funil_id) {
          let indice = this.findFunil(this.mensagem_selecionada.funil_id);

          this.funil_selecionado = this.funis[indice];
          this.add_to_funil = true;
        } else {
          this.funil_selecionado = "";
          this.add_to_funil = false;
        }
      } else {
        this.tipo_envio = "";
      }
    },

    findFunil(id) {
      let indice = this.funis.findIndex((element) => element["id"] == id);

      return indice;
    },

    findMensagemPadrao(id) {
      let indice = this.mensagens_padrao.findIndex((element) => element["id"] == id);

      return indice;
    },

    async validateFields() {
      const result = await this.v$.$validate();
      if (result) {
        this.validarListaContatos();
      }
    },

    validarListaContatos() {
      let verificarBlacklist = false;

      if (this.blacklist_selecionada != "") {
        this.contador_blacklist = 0;

        let telefones_formatados = this.validateJSONBlacklist(this.blacklist_selecionada.telefones);

        this.blacklist_selecionada.telefones_formatados = telefones_formatados;
        verificarBlacklist = true;

        if (!telefones_formatados) {
          this.blacklist_selecionada.telefones_formatados = [];
          verificarBlacklist = false;
        }
      }

      this.contacts = [];

      let arrayContatos = [];

      if (this.list_break == "CSV") {
        arrayContatos = this.listToSend.split(",");
      } else if (this.list_break == "Excel") {
        arrayContatos = this.listToSend.split("\n");
      } else if (this.list_break == "JSON") {
        if (this.validateJSON(this.listToSend)) {
          arrayContatos = this.extractJSON();

          let json = JSON.parse(this.listToSend);

          //CONVERTENDO \n PARA ENVIAR COM ENTER NORMALMENTE
          for (let j = 0; j < json.length; j++) {
            for (var prop in json[j]) {
              if (typeof json[j][prop] == "string") {
                json[j][prop] = this.replaceAll(json[j][prop], "\\n", "\n");
              }
            }
          }

          this.meta_dados = JSON.stringify(json);
        } else {
          this.showErrorWithButton(
            "Ops...",
            null,
            "JSON Inválido! Confira os dados e tente novamente.<br/><br/>" +
              " <div style='font-size: 12px'>Ficou com dúvidas? Você pode validar o seu JSON!<br/>" +
              "<br/><a style='color:green' target='_blank' href='https://jsonformatter.curiousconcept.com/'> Valide aqui o seu JSON </a>" +
              "</div>"
          );

          return 0;
        }
      }

      console.log("Lista");
      console.log(arrayContatos);

      for (let i = 0; i < arrayContatos.length; i++) {
        //FIX UNDEFINED ISSUE
        if (!arrayContatos[i]) {
          arrayContatos[i] = "84";
        }

        let newPhone = "";

        //REMOVENDO TODOS OS CARACTERES, EXCETO NÚMEROS
        newPhone = arrayContatos[i].replace(/[^Z0-9]/g, "");

        //ADICIONANDO 55 CASO NÃO TENHA
        if (newPhone.substr(0, 2) != "55") {
          newPhone = "55" + newPhone;
        }

        let isOnTheList = false;

        for (let i = 0; i < this.contacts.length; i++) {
          if (this.contacts[i].phone == newPhone) {
            isOnTheList = true;
          }
        }

        if (verificarBlacklist && newPhone.length >= 12) {
          let esta_na_black = this.verificarBlacklist(newPhone);

          if (esta_na_black) {
            isOnTheList = true;
            this.contador_blacklist = this.contador_blacklist + 1;
          }
        }

        console.log(newPhone);

        if ((!isOnTheList || this.dont_remove_duplicated) && newPhone.length >= 12) {
          this.contacts.push({
            phone: newPhone,
          });
        }
      }

      this.index = 0;

      this.contEnviados = 0;

      let html =
        "Foram encontrados <b style='color: green'>" +
        this.contacts.length +
        " contato(s)</b> <br/>" +
        "<b>pré-validado(s)</b>" +
        "<br/><b style='font-size:12px'>*Pré-validação exclui contatos com menos de 8 caracteres</b>";

      if (verificarBlacklist) {
        html = html + "<br/><br/><b style='color: red'>" + this.contador_blacklist + " contato(s)</b> estão na <b>blacklist</b> e foram removidos";
      }

      html = html + "<br/><br/>Deseja prosseguir com a campanha?" + "<br/>Nome da campanha: <b>" + this.formData.nome + "</b>";

      if (this.contacts.length == 0) {
        this.showErrorMessageWithButton("Ops...", "Sua lista possui 0 contatos pré-validados");
      } else {
        this.$swal
          .fire({
            title: "Confirmação",
            html: html,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#4caf50",
            cancelButtonColor: "#d33",
            confirmButtonText: "Criar Envio",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.criarEnvio();
            }
          });
      }
    },

    create() {
      let data = {
        funil_id: this.envio.funil_id,
      };

      axios
        .post(`/campanha/store`, data)
        .then((response) => {
          console.log("Retorno dos FUNIS");
          console.log(response.data);

          this.funil = response.data.funil;

          console.log(this.funil);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    sendToList() {
      let verificarBlacklist = false;

      if (this.blacklist_selecionada != "") {
        this.contador_blacklist = 0;

        let telefones_formatados = this.validateJSONBlacklist(this.blacklist_selecionada.telefones);

        this.blacklist_selecionada.telefones_formatados = telefones_formatados;
        verificarBlacklist = true;

        if (!telefones_formatados) {
          this.blacklist_selecionada.telefones_formatados = [];
          verificarBlacklist = false;
        }
      }

      this.contacts = [];

      let arrayContatos = [];

      if (this.list_break == "CSV") {
        arrayContatos = this.listToSend.split(",");
      } else if (this.list_break == "Excel") {
        arrayContatos = this.listToSend.split("\n");
      } else if (this.list_break == "JSON") {
        if (this.validateJSON(this.listToSend)) {
          arrayContatos = this.extractJSON();

          let json = JSON.parse(this.listToSend);

          //CONVERTENDO \n PARA ENVIAR COM ENTER NORMALMENTE
          for (let j = 0; j < json.length; j++) {
            for (var prop in json[j]) {
              if (typeof json[j][prop] == "string") {
                json[j][prop] = this.replaceAll(json[j][prop], "\\n", "\n");
              }
            }
          }

          this.meta_dados = JSON.stringify(json);
        } else {
          this.showErrorWithButton(
            "Ops...",
            null,
            "JSON Inválido! Confira os dados e tente novamente.<br/><br/>" +
              " <div style='font-size: 12px'>Ficou com dúvidas? Você pode validar o seu JSON!<br/>" +
              "<br/><a style='color:green' target='_blank' href='https://jsonformatter.curiousconcept.com/'> Valide aqui o seu JSON </a>" +
              "</div>"
          );

          return 0;
        }
      }

      for (let i = 0; i < arrayContatos.length; i++) {
        //FIX UNDEFINED ISSUE
        if (!arrayContatos[i]) {
          arrayContatos[i] = "84";
        }

        let newPhone = "";

        if (this.envio_para_grupos == 0) {
          //REMOVENDO TODOS OS CARACTERES, EXCETO NÚMEROS
          newPhone = arrayContatos[i].replace(/[^Z0-9]/g, "");

          //ADICIONANDO 55 CASO NÃO TENHA
          if (newPhone.substr(0, 2) != "55") {
            newPhone = "55" + newPhone;
          }
        } else {
          newPhone = arrayContatos[i].split("@")[0];
        }

        let isOnTheList = false;

        for (let i = 0; i < this.contacts.length; i++) {
          if (this.contacts[i].phone == newPhone) {
            isOnTheList = true;
          }
        }

        if (verificarBlacklist && newPhone.length >= 12) {
          let esta_na_black = this.verificarBlacklist(newPhone);

          if (esta_na_black) {
            isOnTheList = true;
            this.contador_blacklist = this.contador_blacklist + 1;
          }
        }

        if ((!isOnTheList || this.dont_remove_duplicated) && newPhone.length >= 12) {
          this.contacts.push({
            phone: newPhone,
          });
        }
      }

      this.index = 0;

      this.contEnviados = 0;

      let html =
        "Foram encontrados <b style='color: green'>" +
        this.contacts.length +
        " contato(s)</b> <br/>" +
        "<b>pré-validado(s)</b>" +
        "<br/><b style='font-size:12px'>*Pré-validação exclui contatos com menos de 8 caracteres</b>";

      if (verificarBlacklist) {
        html = html + "<br/><br/><b style='color: red'>" + this.contador_blacklist + " contato(s)</b> estão na <b>blacklist</b> e foram removidos";
      }

      html = html + "<br/><br/>Deseja prosseguir com o envio?" + "<br/>Nome do envio: <b>" + this.nome_envio + "</b>";

      if (this.contacts.length == 0) {
        this.showErrorMessageWithButton("Ops...", "Sua lista possui 0 contatos pré-validados");
      } else {
        this.$swal
          .fire({
            title: "Confirmação",
            html: html,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#4caf50",
            cancelButtonColor: "#d33",
            confirmButtonText: "Criar Envio",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.criarEnvio();
            }
          });
      }
    },

    replaceAll(string, search, replace) {
      return string.split(search).join(replace);
    },

    verificarBlacklist(telefone_busca) {
      for (let i = 0; i < this.blacklist_selecionada.telefones_formatados.length; i++) {
        let telefone = this.blacklist_selecionada.telefones_formatados[i].slice(this.blacklist_selecionada.telefones_formatados[i].length - 8);
        telefone_busca = telefone_busca.slice(telefone_busca.length - 8);

        console.log("Comparando " + telefone);
        console.log("Com " + telefone_busca);
        console.log("/n/n");

        if (telefone == telefone_busca) {
          return true;
        }
      }

      return false;
    },

    criarEnvio() {
      let data = {
        nome: this.formData.nome,
        intevalo_minimo: this.formData.intevalo_minimo,
        intevalo_maximo: this.formData.intevalo_maximo,
        qtd_envios_pausa: this.formData.qtd_envios_pausa,
        tempo_pausa: this.formData.tempo_pausa,
        container_id: this.container.id,
        mensagem: this.mesage_to_send,
        url: this.url_to_send,
        numeros: this.contacts,
        tipo_envio: "TEXTO",
        funil_id: null,
        total_contatos: this.contacts.length,
        meta_dados: this.meta_dados,
      };

      /*
      if (this.funil_selecionado != "") {
        data.funil_id = this.funil_selecionado.id;
      }
      */

      console.log("Dados do envio");
      console.log(data);

      axios
        .post(`/user/campanhas/store`, data)
        .then((response) => {
          console.log("Envio criado!");
          console.log(response);

          this.showSuccessMessage("Envio criado!");

          if (!this.nao_redirecionar) {
            window.location.href = "/send/" + response.data.envio_id + "/true";
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },

    validateJSONBlacklist(json) {
      console.log("VALIDANDO JSON");

      try {
        let jsonValido = JSON.parse(json);

        console.log("JS");
        console.log(jsonValido);

        if (jsonValido) {
          return jsonValido;
        } else {
          return false;
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },

    validateJSON(json) {
      console.log("VALIDANDO JSON");

      try {
        let jsonValido = JSON.parse(json);

        console.log("JS");
        console.log(jsonValido);

        if (jsonValido) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },

    extractJSON() {
      console.log("Listando json");
      console.log(this.listToSend);

      let json = JSON.parse(this.listToSend);

      console.log(json[0]);

      let arrayContatos = [];

      for (let i = 0; i < json.length; i++) {
        let telefone = "";

        if (json[i].Telefone) {
          telefone = json[i].Telefone;
        }

        if (json[i].telefone) {
          telefone = json[i].telefone;
        }

        arrayContatos.push(telefone);
      }

      return arrayContatos;
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
