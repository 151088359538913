<template>
  <div class="col-md-12">
    <!-- general form elements -->
    <div class="card card-primary">
      <div class="card-header">
        <h3 class="card-title">
          <label for="exampleSelectRounded0">Mensagens Padrão</label>
          <select @change="selecionarMensagem()" class="custom-select rounded-0" id="exampleSelectRounded0" v-model="mensagem_selecionada">
            <option value=""></option>
            <option v-for="mensagem in mensagens_padrao" :key="mensagem" :value="mensagem">
              {{ mensagem.nome }}
            </option>
          </select>
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group" v-if="containers && containers.length > 1">
              <label for="exampleSelectRounded0">Selecione uma conexão</label>
              <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="container">
                <option v-for="container in containers" :key="container" :value="container">
                  {{ container.nome }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="exampleSelectRounded0">Tipo de Envio</label>
              <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="tipo_envio">
                <option value="texto">Texto</option>
                <option value="imagem">Imagem</option>
                <option value="video">Video</option>
                <option value="link">Link</option>
                <option value="voz">Voz</option>
                <option value="botoes_com_imagem">Botão + imagem</option>
              </select>
            </div>

            <!-- FORM -->
            <div class="form-group">
              <label for="exampleInputEmail1">Nome do Envio</label>
              <input class="form-control" id="exampleInputEmail1" placeholder="Digite o nome do seu envio" v-model="nome_envio" />
            </div>

            <div v-if="tipo_envio != 'voz' && tipo_envio != '' && tipo_envio != 'botoes_com_imagem'">
              <div class="form-group">
                <label>Mensagem</label>
                <textarea class="form-control" rows="3" placeholder="Digite a mensagem que deseja enviar ..." v-model="mesage_to_send"></textarea>
              </div>
            </div>

            <div v-if="tipo_envio == 'imagem'">
              <div class="form-group">
                <label for="imageToSend">Url da imagem</label>
                <textarea class="form-control" placeholder="Url da imagem a ser enviada" v-model="url_to_send" id="imageToSend" rows="3" />
                <a href="/gallery" style="font-size: 15px">Não possui url? Busque na sua galeria.</a>
              </div>
              <div class="form-group">
                <div v-if="url_to_send" class="form-check">
                  <input v-model="preview" type="checkbox" class="form-check-input" id="preview" />
                  <label class="form-check-label" for="preview">Previsualizar imagem?</label>
                </div>
              </div>
              <div v-if="preview" class="form-check">
                <img id="qrCode" :src="url_to_send" />
              </div>
            </div>

            <div v-if="tipo_envio == 'video'">
              <div class="form-group">
                <label for="imageToSend">Url do vídeo</label>

                <textarea class="form-control" placeholder="URL do vídeo a ser enviado" v-model="url_to_send" id="imageToSend" rows="3" />

                <a href="/gallery" style="font-size: 15px">Não possui url? Busque na sua galeria.</a>
              </div>
              <div class="form-group">
                <div v-if="url_to_send" class="form-check">
                  <input v-model="preview" type="checkbox" class="form-check-input" id="preview" />
                  <label class="form-check-label" for="preview">Previsualizar vídeo?</label>
                </div>
              </div>
              <div v-if="preview" class="form-check">
                <video v-if="preview" controls width="250" height="150">
                  <source :src="url_to_send" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>

            <div v-if="tipo_envio == 'voz' || tipo_envio == 'audio'">
              <div class="form-group">
                <label for="imageToSend">Url</label>
                <br />
                <textarea class="form-control" placeholder="Url do áudio a ser enviado" v-model="url_to_send" id="imageToSend" rows="4" cols="40" />
                <a href="/gallery" style="font-size: 15px">Não possui url? Busque na sua galeria.</a>

                <br />
                <video v-if="url_to_send" controls width="250" height="150">
                  <source :src="url_to_send" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>

            <div v-if="tipo_envio == 'link'">
              <div class="form-group">
                <label for="imageToSend">Url:</label>
                <input
                  placeholder="Url a ser enviada"
                  style="width: 100%"
                  type="text"
                  id="phone"
                  nome="phone"
                  v-model="url_to_send"
                  class="form-control"
                />
              </div>
            </div>

            <div v-if="tipo_envio != ''" class="form-group">
              <label>Telefones</label>
              <textarea class="form-control" rows="3" placeholder="Digite os telefones ..." v-model="listToSend"></textarea>

              <div class="row">
                <div class="col-1"></div>
                <div class="col-3">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" value="CSV" checked v-model="list_break" />
                    <label class="form-check-label">CSV</label>
                  </div>
                </div>
                <div class="col-1"></div>
                <div class="col-3">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="radio1" value="Excel" v-model="list_break" />
                    <label class="form-check-label">Excel</label>
                  </div>
                </div>
                <div class="col-1"></div>
                <div class="col-3">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="radio1" value="JSON" v-model="list_break" />
                    <label class="form-check-label">JSON</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="exampleSelectRounded0">Enviar para</label>
              <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="envio_para_grupos">
                <option :value="0">Contatos</option>
                <option :value="1">Grupos</option>
              </select>
            </div>

            <div class="form-group">
              <label for="exampleSelectRounded0">Blacklist</label>
              <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="blacklist_selecionada">
                <option selected value=""></option>
                <option v-for="blacklist in blacklists" :key="blacklist" :value="blacklist">
                  {{ blacklist.nome }}
                </option>
              </select>
            </div>

            <div v-if="tipo_envio != ''" class="form-check">
              <input v-model="add_to_funil" type="checkbox" class="form-check-input" id="exampleCheck1" />
              <label class="form-check-label" for="exampleCheck1">Adicionar a um funil?</label>
            </div>

            <div v-if="add_to_funil" class="form-group">
              <br />
              <label for="exampleSelectRounded0">Funil</label>
              <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="funil_selecionado">
                <option selected value=""></option>
                <option v-for="funil in funis" :key="funil" :value="funil">{{ funil.nome }}</option>
              </select>
            </div>

            <div class="form-check">
              <input v-model="dont_remove_duplicated" type="checkbox" class="form-check-input" id="preview" />
              <label class="form-check-label" for="preview">Não remover números repetidos</label>
            </div>

            <!--
            <div id="accordion" class="col-md-12">
              <div class="card card-info">
                <div class="card-header" data-toggle="collapse" :href="'#collapse1'">
                  <label class="form-check-label" for="preview">Avançado</label>
                </div>
                <div id="collapse1" class="collapse" data-parent="#accordion">
                  <div class="card-body">
                    <div class="row" style="padding: 0px">
                      <div class="col-sm-12">
                        <div class="form-group">

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            -->
            <!-- FORM -->
          </div>

          <div class="col-sm-6">
            <!-- ENVIO DE BOTÕES -->
            <div v-if="tipo_envio == 'botoes' || tipo_envio == 'botoes_com_imagem'">
              <div class="form-group" v-if="tipo_envio == 'botoes_com_imagem'">
                <label>URL imagem</label>
                <input style="width: 100%" type="text" v-model="mensagem.url_imagem_botao" class="form-control" />
              </div>

              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Saudação</label>
                    <input style="width: 100%" type="text" v-model="mensagem.saudacao" class="form-control" />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Rodapé</label>
                    <input style="width: 100%" type="text" v-model="mensagem.rodape" class="form-control" />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="exampleSelectRounded0">Tipo de botão</label>
                <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="mensagem.tipo_botao">
                  <option value="url">Url</option>
                  <option value="texto">Texto</option>
                </select>
              </div>

              <div class="form-group">
                <label>Texto</label>
                <textarea
                  class="form-control"
                  rows="4"
                  placeholder="Digite a mensagem que deseja enviar ..."
                  v-model="mensagem.texto_botao"
                ></textarea>
              </div>

              <div class="form-group"></div>

              <div class="form-group">
                <label for="exampleSelectRounded0">Quantidade de botões</label>
                <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="mensagem.qtd_botoes">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-4" v-if="mensagem.qtd_botoes >= 1">
                  <div class="form-group">
                    <label>Botão 01</label>
                    <input style="width: 100%" type="text" v-model="mensagem.botao_1_texto" class="form-control" />
                  </div>
                  <div class="form-group" v-if="mensagem.tipo_botao == 'url'">
                    <label>Url botão 01</label>
                    <input style="width: 100%" type="text" v-model="mensagem.botao_1_valor" class="form-control" />
                  </div>
                </div>
                <div class="col-sm-12 col-md-4" v-if="mensagem.qtd_botoes >= 2">
                  <div class="form-group">
                    <label>Botão 02</label>
                    <input style="width: 100%" type="text" v-model="mensagem.botao_2_texto" class="form-control" />
                  </div>
                  <div class="form-group" v-if="mensagem.tipo_botao == 'url'">
                    <label>Url botão 02</label>
                    <input style="width: 100%" type="text" v-model="mensagem.botao_2_valor" class="form-control" />
                  </div>
                </div>
                <div class="col-sm-12 col-md-4" v-if="mensagem.qtd_botoes >= 3">
                  <div class="form-group">
                    <label>Botão 03</label>
                    <input style="width: 100%" type="text" v-model="mensagem.botao_3_texto" class="form-control" />
                  </div>
                  <div class="form-group" v-if="mensagem.tipo_botao == 'url'">
                    <label>Url botão 03</label>
                    <input style="width: 100%" type="text" v-model="mensagem.botao_3_valor" class="form-control" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->

      <div class="card-footer">
        <button @click="validateFields()" class="btn btn-success">Enviar</button>
      </div>
    </div>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  props: [
    "my_container",
    "blacklists",
    "containers",
    "usuario",
    "funis",
    "mensagens_padrao",
    "contatos_para_envio",
    "id_mensagem_padrao",
    "aux_list_break",
    "nao_redirecionar",
    "aux_meta_dados",
    "editar",
    "cancelar",
  ],

  mixins: [wppController, sweetAlert, Swal],

  data() {
    return {
      container: {},
      nome_envio: null,
      mesage_to_send: "",
      url_to_send: null,
      isConnected: false,
      send_to_list: false,
      add_to_funil: false,
      list_break: "CSV",
      listToSend: null,
      contacts: [],
      preview: false,
      funil_selecionado: "",
      tipo_envio: "",
      mensagem_selecionada: "",
      envio_para_grupos: 0,
      blacklist_selecionada: "",
      contador_blacklist: 0,
      dont_remove_duplicated: false,
      mensagem: {},
    };
  },

  mounted() {
    if (this.containers) {
      this.container = this.containers[0];
    } else {
      this.container = this.my_container;
    }

    console.log("Create Send Ready");
    console.log(this.tipo_envio);
    console.log(this.usuario.porta);

    console.log(this.id_mensagem_padrao);

    //CASO TENHA ESSES 2 PARAMETROS O COMPONENTE DE ENVIO ESTÁ SENDO CHAMADO EM OUTRO COMPONENTE
    if (this.contatos_para_envio) {
      console.log("ENTROU NO IF");

      //CASO NÃO VENHA LIST BREAK, O PADRÃO É CSV
      let list_break = this.aux_list_break;

      if (!list_break) {
        list_break = "CSV";
      }

      //ATRIBUINDO CONTATOS
      this.listToSend = this.contatos_para_envio;

      if (this.id_mensagem_padrao) {
        let indice = this.findMensagemPadrao(this.id_mensagem_padrao);

        this.mensagem_selecionada = this.mensagens_padrao[indice];

        this.selecionarMensagem();

        if (!this.editar) {
          let html = "Digite o nome do envio";

          this.$swal
            .fire({
              title: "Confirmação",
              html: html,
              icon: "info",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Confirmar",
              input: "text",
              inputAttributes: {
                autocapitalize: "off",
                required: false,
              },
              inputValidator: (value) => {
                if (!value) {
                  return "O nome do envio não pode ficar em branco!";
                } else {
                  this.nome_envio = value;
                  this.sendToList();
                }
              },
            })
            .then((result) => {
              if (result.isDismissed) {
                this.cancelar();
              }
            });
        }
      }
    }
  },

  methods: {
    selecionarMensagem() {
      if (this.mensagem_selecionada != "") {
        this.nome_envio = this.mensagem_selecionada.nome;
        this.url_to_send = this.mensagem_selecionada.url;
        this.mesage_to_send = this.mensagem_selecionada.mensagem;
        this.tipo_envio = this.mensagem_selecionada.tipo;

        if (this.aux_meta_dados) {
          this.meta_dados = JSON.stringify(this.aux_meta_dados);

          console.log("Meta no envio");
          console.log(this.aux_meta_dados);
        }

        if (this.mensagem_selecionada.funil_id) {
          let indice = this.findFunil(this.mensagem_selecionada.funil_id);

          this.funil_selecionado = this.funis[indice];
          this.add_to_funil = true;
        } else {
          this.funil_selecionado = "";
          this.add_to_funil = false;
        }
      } else {
        this.tipo_envio = "";
      }
    },

    findFunil(id) {
      let indice = this.funis.findIndex((element) => element["id"] == id);

      return indice;
    },

    findMensagemPadrao(id) {
      let indice = this.mensagens_padrao.findIndex((element) => element["id"] == id);

      return indice;
    },

    validateFields() {
      console.log("Mensagem");
      console.log(this.mensagem);

      this.formatarBotoes();

      if (!this.nome_envio) {
        this.showErrorMessageWithButton("Ops...", "O campo nome é obrigatório!");
      } else if (this.tipo_envio == "texto" && !this.mesage_to_send) {
        this.showErrorMessageWithButton("Ops...", "A mensagem não pode ficar em banco no envio de texto!");
      } else if (!this.listToSend) {
        this.showErrorMessageWithButton("Ops...", "A lista de contatos não pode ficar em branco!");
      } else {
        this.sendToList();
      }
    },

    formatarBotoes() {
      if (this.tipo_envio == "botoes" || this.tipo_envio == "botoes_com_imagem") {
        let json = {
          qtd_botoes: this.mensagem.qtd_botoes,
          tipo_botao: this.mensagem.tipo_botao,
          texto_botao: this.mensagem.texto_botao,
          saudacao: this.mensagem.saudacao,
          rodape: this.mensagem.rodape,
        };

        if (this.tipo_envio == "botoes_com_imagem") {
          json.url_imagem_botao = this.mensagem.url_imagem_botao;
        }

        let botoes = [];

        if (this.mensagem.qtd_botoes >= 1) {
          botoes.push({ botao_texto: this.mensagem.botao_1_texto, botao_valor: this.mensagem.botao_1_valor });
        }
        if (this.mensagem.qtd_botoes >= 2) {
          botoes.push({ botao_texto: this.mensagem.botao_2_texto, botao_valor: this.mensagem.botao_2_valor });
        }
        if (this.mensagem.qtd_botoes >= 3) {
          botoes.push({ botao_texto: this.mensagem.botao_3_texto, botao_valor: this.mensagem.botao_3_valor });
        }

        json.botoes = botoes;

        console.log("Json pronto");
        console.log(json);

        this.meta_dados = JSON.stringify(json);
      }
    },
    sendToList() {
      let verificarBlacklist = false;

      if (this.blacklist_selecionada != "") {
        this.contador_blacklist = 0;

        let telefones_formatados = this.validateJSONBlacklist(this.blacklist_selecionada.telefones);

        this.blacklist_selecionada.telefones_formatados = telefones_formatados;
        verificarBlacklist = true;

        if (!telefones_formatados) {
          this.blacklist_selecionada.telefones_formatados = [];
          verificarBlacklist = false;
        }
      }

      this.contacts = [];

      let arrayContatos = [];

      console.log("Vreak");
      console.log(this.list_break);

      if (this.list_break == "CSV") {
        arrayContatos = this.listToSend.split(",");
      } else if (this.list_break == "Excel") {
        arrayContatos = this.listToSend.split(/\r?\n/);
      } else if (this.list_break == "JSON") {
        if (this.validateJSON(this.listToSend)) {
          arrayContatos = this.extractJSON();

          let json = JSON.parse(this.listToSend);

          //CONVERTENDO \n PARA ENVIAR COM ENTER NORMALMENTE
          for (let j = 0; j < json.length; j++) {
            for (var prop in json[j]) {
              if (typeof json[j][prop] == "string") {
                json[j][prop] = this.replaceAll(json[j][prop], "\\n", "\n");
              }
            }
          }

          this.meta_dados = JSON.stringify(json);
        } else {
          this.showErrorWithButton(
            "Ops...",
            null,
            "JSON Inválido! Confira os dados e tente novamente.<br/><br/>" +
              " <div style='font-size: 12px'>Ficou com dúvidas? Você pode validar o seu JSON!<br/>" +
              "<br/><a style='color:green' target='_blank' href='https://jsonformatter.curiousconcept.com/'> Valide aqui o seu JSON </a>" +
              "</div>"
          );

          return 0;
        }
      }

      for (let i = 0; i < arrayContatos.length; i++) {
        //FIX UNDEFINED ISSUE
        if (!arrayContatos[i]) {
          arrayContatos[i] = "84";
        }

        let newPhone = "";

        if (this.envio_para_grupos == 0) {
          //REMOVENDO TODOS OS CARACTERES, EXCETO NÚMEROS
          newPhone = arrayContatos[i].replace(/[^Z0-9]/g, "");

          //ADICIONANDO 55 CASO NÃO TENHA
          if (newPhone.substr(0, 2) != "55") {
            newPhone = "55" + newPhone;
          }
        } else {
          newPhone = arrayContatos[i].split("@")[0];
        }

        let isOnTheList = false;

        for (let i = 0; i < this.contacts.length; i++) {
          if (this.contacts[i].phone == newPhone) {
            isOnTheList = true;
          }
        }

        if (verificarBlacklist && newPhone.length >= 12) {
          let esta_na_black = this.verificarBlacklist(newPhone);

          if (esta_na_black) {
            isOnTheList = true;
            this.contador_blacklist = this.contador_blacklist + 1;
          }
        }

        if ((!isOnTheList || this.dont_remove_duplicated) && newPhone.length >= 12) {
          this.contacts.push({
            phone: newPhone,
          });
        }
      }

      this.index = 0;

      this.contEnviados = 0;

      let html =
        "Foram encontrados <b style='color: green'>" +
        this.contacts.length +
        " contato(s)</b> <br/>" +
        "<b>pré-validado(s)</b>" +
        "<br/><b style='font-size:12px'>*Pré-validação exclui contatos com menos de 8 caracteres</b>";

      if (verificarBlacklist) {
        html = html + "<br/><br/><b style='color: red'>" + this.contador_blacklist + " contato(s)</b> estão na <b>blacklist</b> e foram removidos";
      }

      html = html + "<br/><br/>Deseja prosseguir com o envio?" + "<br/>Nome do envio: <b>" + this.nome_envio + "</b>";

      if (this.contacts.length == 0) {
        this.showErrorMessageWithButton("Ops...", "Sua lista possui 0 contatos pré-validados");
      } else {
        this.$swal
          .fire({
            title: "Confirmação",
            html: html,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#4caf50",
            cancelButtonColor: "#d33",
            confirmButtonText: "Criar Envio",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.criarEnvio();
            }
          });
      }
    },

    replaceAll(string, search, replace) {
      return string.split(search).join(replace);
    },

    verificarBlacklist(telefone_busca) {
      for (let i = 0; i < this.blacklist_selecionada.telefones_formatados.length; i++) {
        let telefone = this.blacklist_selecionada.telefones_formatados[i].slice(this.blacklist_selecionada.telefones_formatados[i].length - 8);
        telefone_busca = telefone_busca.slice(telefone_busca.length - 8);

        console.log("Comparando " + telefone);
        console.log("Com " + telefone_busca);
        console.log("/n/n");

        if (telefone == telefone_busca) {
          return true;
        }
      }

      return false;
    },

    criarEnvio() {
      if (this.mesage_to_send == "") {
        this.mesage_to_send = "-";
      }

      let data = {
        nome: this.nome_envio,
        container_id: this.container.id,
        mensagem: this.mesage_to_send,
        url: this.url_to_send,
        numeros: this.contacts,
        tipo_envio: this.tipo_envio.toUpperCase(),
        funil_id: null,
        total_contatos: this.contacts.length,
        meta_dados: this.meta_dados,
        envio_para_grupos: this.envio_para_grupos,
      };

      if (this.funil_selecionado != "") {
        data.funil_id = this.funil_selecionado.id;
      }

      console.log("Dados do envio");
      console.log(data);

      axios
        .post(`/send/store`, data)
        .then((response) => {
          console.log("Envio criado!");
          console.log(response);

          this.showSuccessMessage("Envio criado!");

          if (!this.nao_redirecionar) {
            window.location.href = "/send/" + response.data.envio_id + "/true";
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },

    validateJSONBlacklist(json) {
      console.log("VALIDANDO JSON");

      try {
        let jsonValido = JSON.parse(json);

        console.log("JS");
        console.log(jsonValido);

        if (jsonValido) {
          return jsonValido;
        } else {
          return false;
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },

    validateJSON(json) {
      console.log("VALIDANDO JSON");

      try {
        let jsonValido = JSON.parse(json);

        console.log("JS");
        console.log(jsonValido);

        if (jsonValido) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },

    extractJSON() {
      console.log("Listando json");
      console.log(this.listToSend);

      let json = JSON.parse(this.listToSend);

      console.log(json[0]);

      let arrayContatos = [];

      for (let i = 0; i < json.length; i++) {
        let telefone = "";

        if (json[i].Telefone) {
          telefone = json[i].Telefone;
        }

        if (json[i].telefone) {
          telefone = json[i].telefone;
        }

        arrayContatos.push(telefone);
      }

      return arrayContatos;
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
