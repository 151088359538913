<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

  <div>
    <br />
    <h2 style="text-align: center;">Envio Clickmassa</h2>
    <h6 style="text-align: center;">A entrega da mensagem será feita através do Clickmassa</h6>
    <h6 style="text-align: center;">E não abrirá ticket no sistema</h6>
    <h6 style="text-align: center;color:rgb(170, 24, 24)">Verifique sua conexão</h6>
    <br />
  </div>

  <div class="col-md-12">
    <!-- general form elements -->
    <div class="card card-primary">
      <div class="card-header">
        <h3 class="card-title">Envio</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <!-- FORM -->
            <div class="form-group">
              <label for="exampleInputEmail1">Nome do Envio</label>
              <input :disabled="!editar_envio" class="form-control" id="exampleInputEmail1"
                placeholder="Digite o nome do seu envio" v-model="envio.nome" />
            </div>

            <div class="form-group">
              <label for="exampleSelectRounded0">Tempo de envio</label>
              <select @change="alterarTempo()" class="custom-select rounded-0" v-model="tipo_tempo_envio">
                <option value="Recomendado">Recomendado (20s - 40s)</option>
                <option value="Aquecimento">Aquecimento (40s - 60s)</option>
                <option value="Envio Rapido">Envio Rapido (12s - 20s)</option>
              </select>
            </div>

            <div v-if="!envio_concluido" class="form-group">
              <div class="form-check">
                <input v-model="exibir_detalhes" type="checkbox" class="form-check-input" id="exibir_contatos"
                  @click="show" />
                <label class="form-check-label" for="preview">Exibir detalhes
                </label>
              </div>
            </div>

            <div v-if="exibir_detalhes">
              <div class="form-group">
                <label for="exampleSelectRounded0">Tipo de Envio</label>
                <select :disabled="!editar_envio" class="custom-select rounded-0" id="exampleSelectRounded0"
                  v-model="tipo_envio">
                  <option value="texto">Texto</option>
                  <option value="imagem">Imagem</option>
                  <option value="video">Video</option>
                  <option value="link">Link</option>
                  <option value="voz">Voz</option>
                </select>
              </div>

              <div v-if="tipo_envio != 'voz'">
                <div class="form-group">
                  <label>Mensagem</label>
                  <textarea :disabled="!editar_envio" class="form-control" rows="3"
                    placeholder="Digite a mensagem que deseja enviar ..." v-model="envio.mensagem"></textarea>
                </div>
              </div>

              <div v-if="tipo_envio != 'texto'">
                <div class="form-group">
                  <label for="imageToSend">Url da mídia</label>
                  <textarea :disabled="!editar_envio" class="form-control" placeholder="Url da mídia a ser enviada"
                    v-model="envio.url" id="imageToSend" rows="3" />
                  <a href="/gallery" target="_blank" style="font-size: 15px">
                    Não possui url? Busque na sua galeria.</a>
                </div>
                <div class="form-group">
                  <div v-if="envio.url" class="form-check">
                    <input v-model="preview" type="checkbox" class="form-check-input" id="preview" />
                    <label class="form-check-label" for="preview">Previsualizar?
                    </label>
                  </div>
                </div>
                <div v-if="preview" class="form-check">
                  <img id="qrCode" :src="envio.url"
                    style="min-width: 300px;min-height: 200px;max-width: 300px;max-height: 200px;" />
                </div>
              </div>

              <div v-if="tipo_envio == 'video'">
                <div v-if="preview" class="form-check">
                  <video v-if="preview" controls width="250" height="150">
                    <source :src="envio.url" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>

              <div v-if="tipo_envio == 'voz'">
                <div class="form-group">
                  <br />
                  <video v-if="envio.url" controls width="250" height="150"
                    poster="{{ asset('/dist/img/whats-logo.png') }}">
                    <source :src="envio.url" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>

              <div v-if="!envio_concluido" class="form-group">
                <button v-if="!editar_envio" @click="editar_envio = true" class="btn btn-flat btn-info"
                  style="border-radius: 10px">
                  Editar Envio
                </button>

                <button v-if="editar_envio" @click="confirmarEdicao()" class="btn btn-flat btn-success"
                  style="border-radius: 10px">
                  Salvar alterações
                </button>

                <button v-if="editar_envio" @click="editar_envio = false" class="btn btn-flat btn-danger"
                  style="border-radius: 10px">
                  Cancelar
                </button>
              </div>

            </div>

            <div v-if="!envio_concluido" class="form-group">
              <div class="form-check">
                <input v-model="exibir_contatos" type="checkbox" class="form-check-input" id="exibir_contatos"
                  @click="show" />
                <label class="form-check-label" for="preview">Exibir contatos pendentes?
                </label>
              </div>
            </div>

            <div class="form-group">
              <label v-if="exibir_contatos">Números pendentes</label>
              <textarea placeholder="Nenhum contato pendente..." v-model="lista_de_contatos" id="textToSend" rows="4"
                cols="40" disabled="true" class="form-control" v-if="exibir_contatos" />
            </div>

            <div v-if="add_to_funil" class="form-group">
              <label for="exampleSelectRounded0">Funil</label>
              <select class="custom-select rounded-0" id="exampleSelectRounded0">
                <option>Funil 1</option>
                <option>Funil 2</option>
                <option>Funil 3</option>
              </select>
            </div>
            <label class="sent" style="font-weight: bold; font-size: 15px; color: green">
              Enviado para: {{ envio.calculo_enviados }}
            </label>
            <br />
            <label class="invalid" style="font-weight: bold; font-size: 15px; color: red">
              Inválidos: {{ envio.calculo_invalidos }}
            </label>
            <br />
            <br />
            <h3 v-if="!envio_concluido" class="message">{{ message }}</h3>

            <!--<div v-if="usuario.api_name == 'clinicaampla'">
            <div>
              <div class=" form-group">
                <label for="exampleInputEmail1">Url</label>
                <input :disabled="true" class="form-control" v-model="clickmassa.url_clickmassa" />
              </div>

              <div class="form-group">
                <label for="exampleInputEmail1">Token</label>
                <input :disabled="true" class="form-control" v-model="clickmassa.token_clickmassa" />
              </div>
            </div>
          -->

            <div v-if="envio_concluido">
              <h3 class="message" style="color: green">
                Envio concluído
              </h3>

              <a href="/send/continue" class="btn btn-success">
                Listar envios abertos
              </a>
            </div>

            <!-- FORM -->
          </div>
        </div>
      </div>
      <!-- /.card-body -->

      <div class="card-footer">
        <button v-if="!envio_concluido && continuar_envio" @click="sendMessage()" class="btn btn-success">
          Enviar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import sendController from "../controller/wppController";
import wppController from "../controller/sendController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: ["my_container", "envio", "continue", "usuario"],

  mixins: [wppController, sweetAlert, Swal, sendController],

  components: { Loading },

  data() {
    return {
      container: {},
      nome_envio: null,
      mesage_to_send: "",
      isConnected: false,
      send_to_list: false,
      add_to_funil: false,
      list_break: "CSV",
      listToSend: null,
      contacts: [],
      preview: false,
      tipo_envio: "texto",
      contatos: [],
      message: "Clique em Continuar",
      loading: false,
      index: 0,
      apiId: "",
      envio_concluido: false,
      exibir_contatos: false,
      lista_de_contatos: "",
      continuar_envio: false,
      editar_envio: false,
      exibir_detalhes: false,
      isLoading: false,
      minTime: 20,
      maxTime: 40,
      tipo_tempo_envio: "Recomendado",
      clickmassa: {},
    };
  },

  mounted() {

    this.clickmassa.url_clickmassa = "https://enterprise-33api.clickmassa.com.br/v1/api/external/540e10b3-3b27-458a-98a6-6c5ce9fb6b50/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0ZW5hbnRJZCI6NSwicHJvZmlsZSI6ImFkbWluIiwic2Vzc2lvbklkIjo1LCJpYXQiOjE2OTA5OTY0MDgsImV4cCI6MTc1NDA2ODQwOH0.8z_t6EQXBVzIKX5b4pZ1TI9i6RwNJLAFXdTPoz2ciFI";
    this.clickmassa.token_clickmassa = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0ZW5hbnRJZCI6MSwicHJvZmlsZSI6ImFkbWluIiwic2Vzc2lvbklkIjoxLCJpYXQiOjE2ODY1ODM1ODUsImV4cCI6MTc0OTY1NTU4NX0.8bQ1QRean0YO8uObYjO0OpC1Rv4gtwFuRgKyLo7qwWs";

    //this.testeClick();

    this.container = this.my_container;
    this.apiId = this.container.chave_api;

    console.log(this.usuario);
    console.log("CREATE");
    console.log(this.envio);

    //BUSCANDO OS DADOS A PARTIR DO NUMERO NO JSON META_DADOS DO ENVIO
    this.json_envio = JSON.parse(this.envio.meta_dados);

    console.log("JSON AQUI");
    console.log(this.json_envio);

    //NÃO PRECISA VERIFICAR A CONEXÃO POIS ELA PERTENCE AO CLICKMASSA
    //this.checkConnection((conectado) => {

    if (true) {
      this.buscarNumerosNaoEnviados(this.envio.id, (numeros) => {
        if (this.envio.funil_id) {
          this.buscarFunil();
        }

        if (this.container.possui_funil == 1) {
          if (envio.id_funil) {
            this.buscarFunil();
          }
        } else {
          this.funil = undefined;
        }

        this.contatos = numeros;

        this.loading = false;

        //FORMATANDO LISTA DE CONTATOS PARA EXIBIR
        for (let i = 0; i < this.contatos.length; i++) {

          //CASO NÃO SEJA ENVIO PARA GRUPOS, ADICIONAR O '@c.us'
          if (this.envio.envio_para_grupos == 0) {
            this.contatos[i].phone = this.contatos[i].numero + "@c.us";
          } else {
            this.contatos[i].phone = this.contatos[i].numero + "@g.us";
          }

          console.log("OI OI OIO IO ");
          console.log(this.envio.envio_para_grupos);
          console.log(this.contatos[i].phone);

          //REMOVENDO ESPAÇO EM BRANCO INICIAL
          if (i == 0) {
            this.lista_de_contatos = this.lista_de_contatos + this.contatos[i].numero;
          } else {
            this.lista_de_contatos =
              this.lista_de_contatos + "  " + this.contatos[i].numero;
          }

          if (i + 1 != this.contatos.length) {
            this.lista_de_contatos + ",";
          }
        }

        //VERIFICANDO SE DEVE CONTINUAR O ENVIO IMEDIATAMENTE
        if (this.contatos.length > 0) {
          if (this.continue == "true") {
            this.sendMessage();
          } else {
            this.continuar_envio = true;
          }
        } else {
          this.envio_concluido = true;

          this.finalizarEnvio(this.envio.id);
        }
      });
    }

    this.tipo_envio = this.envio.tipo_envio.toLowerCase();

    console.log(this.tipo_envio);
    //});


  },

  methods: {

    testeClick() {
      let data = {
        "body": "Teste Whatsz + clickmassa 01",
        "number": "558488992898",
        "externalKey": "0"
      }

      axios
        .post(this.clickmassa.url_clickmassa, data)
        .then((response) => {

          console.log("Mensagem enviada!");

        })
        .catch((error) => {
          console.log("Mensagem não enviada!");
          console.log(error);
        });
    },

    alterarTempo() {

      //console.log("Alterando");

      if (this.tipo_tempo_envio == "Recomendado") {
        this.minTime = 20;
        this.maxTime = 40;
      } else if (this.tipo_tempo_envio == "Aquecimento") {
        this.minTime = 40;
        this.maxTime = 60;
      } else if (this.tipo_tempo_envio == "Envio Rapido") {
        this.minTime = 12;
        this.maxTime = 20;
      }
      /*
      for (let i = 0; i < 50; i++) {
        console.log(this.getTime());
      }*/
    },

    confirmarEdicao() {

      this.isLoading = true;

      let data = {
        id: this.envio.id,
        nome: this.envio.nome,
        mensagem: this.envio.mensagem,
        url: this.envio.url,
        tipo_envio: this.tipo_envio.toUpperCase()
      };

      console.log(data);

      axios
        .post(`/send/edit`, data)
        .then((response) => {
          this.showSuccessMessage("Envio editado!");
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessageWithButton("Falha ao editar envio");
          this.isLoading = false;
        });

    },

    checkConnection(callback) {
      this.axios
        .get(process.env.MIX_VUE_APP_ENDPOINT + this.usuario.porta + `/sessionStatus/` + this.container.chave_api)
        .then((response) => {
          if (response.data.status == "CONNECTED" || response.data.status == "inChat") {
            callback(true);
          } else {
            this.message = "Verifique a aba Conexão...";
            callback(false);
          }
        })
        .catch((error) => {
          this.message = "Api não encontrada...";
          callback(false);
        });
    },

    sendMessage() {
      this.continuar_envio = false;

      this.timeToSend = this.getTime();

      console.log("Tempo inicial - " + this.timeToSend);

      let that = this;

      for (let i = 0; i <= this.timeToSend; i++) {
        setTimeout(function () {
          console.log("Tempo para enviar - " + that.timeToSend);
          if (that.timeToSend > 0) {
            that.message = "Próxima mensagem em " + that.timeToSend + " segundos";
            that.timeToSend -= 1;
          } else {
            //ENVIANDO MENSAGEM
            if (that.index < that.contatos.length) {
              that.send(that.contatos[that.index].phone, that.envio.mensagem, () => {
                if (that.index < that.contatos.length) {
                  that.sendMessage();
                }

                that.posEnvio();
              });

              that.index += 1;
            } else {
              this.posEnvio();
            }
          }
        }, i * 1000);
      }
    },

    redirecionar() {
      /*
      let url_envio = this.$route.path;

      let url = url_envio.split("false");

      if (url.length == 1) {
        window.location.reload();
      } else {
        window.location.href = `/#` + url[0] + "true";
        window.location.reload();
      }*/

      window.location.reload();
    },

    send(number, text, callback) {
      if (this.envio.envio_para_grupos == 1) {
        this.preEnvio(number, number.split("@")[0], text);

        console.log("\n\n");
        console.log("Enviando para grupo");

        this.enviar(
          this.tipo_envio,
          number,
          text,
          this.envio.url,
          this.apiId,
          this.usuario.porta,
          (timeout, phone, sucess) => {
            this.getResult(timeout, phone, sucess, callback);
          }
        );
      } else {

        this.message = "Validando número...";

        //Validando número antes do envio
        this.validarNumero(number, this.container.chave_api, this.usuario.porta, (validNumber, timeout) => {
          //Adicionando feedback para o cliente
          this.message = "Enviando...";

          if (validNumber) {
            let onlyNumber = validNumber.split("@")[0];

            this.preEnvio(onlyNumber, number.split("@")[0], text);

            this.checkFunil(onlyNumber);

            if (text) {
              //VERIFICANDO SE TEM ALGUMA TAG NO TEXTO PARA SER SUBSTITUIDA POR VALORES NO JSON
              text = this.formatarMensagem(text, number);
            }

            //ADICIONANDO RANDOM DE PONTOS PARA DIFERENCIAR MENSAGENS
            let points = Math.floor(Math.random() * 30) + 1;

            //ADICIONANDO ESPAÇO ANTES DOS PONTOS
            text = text + "\n";

            for (let i = 0; i < points; i++) {
              text = text + ".";
            }

            this.enviarClickMassa(
              this.tipo_envio,
              validNumber,
              text,
              this.envio.url,
              this.clickmassa.token_clickmassa,
              this.clickmassa.url_clickmassa,
              (timeout, phone, sucess) => {
                this.getResult(timeout, phone, sucess, callback);
              }
            );
          } else {
            if (timeout) {
              this.message = "Falha ao validar número, conexão instável...";

              //REDUZINDO INDICE PARA TENTAR NOVAMENTE VALIDAR O MESMO NÚMERO
              this.index -= 1;

              //AGUARDANDO 2 SEGUNDOS ANTES DE CHAMAR CALLBACK
              //SOMENTE PARA DAR TEMPO LER A MENSAGEM

              let that = this;

              setTimeout(function () {
                that.getResult(true, false, false, callback);
              }, 2000);
            } else {
              this.message = "WhatsApp inválido...";

              //REGISTRANDO MENSAGEM COM STATUS DE NÚMERO INVÁLIDO
              //TABELA new_mensagens
              this.criarMensagemDBStatus(number.split("@")[0], text, this.envio.id, this.container.id, "NÚMERO INVÁLIDO");

              //ATUALIZANDO TOTAL DE NÚMEROS INVÁLIDOS NO ENVIO
              this.atualizarTotalInvalidos(this.envio.id);

              this.contInvalidNumbers += 1;

              this.envio.total_invalidos += 1;
              this.envio.calculo_invalidos = Number(this.envio.calculo_invalidos) + 1;
              //this.envio.calculo_total = Number(this.envio.calculo_total) + 1;;

              //REGISTRANDO MENSAGEM COM STATUS DE NÚMERO INVÁLIDO
              //TABELA numeros_envios
              this.atualizarStatusNumeroDB(
                this.envio.id,
                number.split("@")[0],
                "NÚMERO INVÁLIDO",
                number.split("@")[0]
              );

              //FINALIZANDO ENVIO
              if (
                this.envio.calculo_enviados + this.envio.calculo_invalidos ==
                this.envio.calculo_total) {

                this.message = "Envio concluído";

                this.envio_concluido = true;

                window.location.reload();
              }

              //AGUARDANDO 2 SEGUNDOS ANTES DE CHAMAR CALLBACK
              //SOMENTE PARA DAR TEMPO LER A MENSAGEM
              setTimeout(function () {
                callback();
              }, 2000);
            }
          }
        });
      }

    },

    getResult(timeout, phone, sucess, callback) {
      if (timeout) {

        //Tentar reconectar
        this.status_conexao = "Disconnected";

        this.message = "Verifique a aba Conexão...";

        this.redirecionar();

      } else {
        //Verificando se foi enviado com sucesso
        console.log("Checking status...");

        if (sucess) {
          console.log("Success");
          //Prosseguir envio
          if (phone) {
            console.log("GET RESULT");
            console.log("Enviados " + this.envio.calculo_enviados);
            console.log("Invalidos " + this.envio.calculo_invalidos);
            console.log("Total " + this.envio.calculo_total);

            if (
              Number(this.envio.calculo_enviados) + Number(this.envio.calculo_invalidos) ==
              Number(this.envio.calculo_total)) {

              this.message = "Envio concluído";

              window.location.reload();
            }
          }

          callback();
        } else {
          this.redirecionar();
        }
      }
    },

    preEnvio(formatedPhone, phone, text) {
      if (phone) {
        this.contEnviados += 1;
        this.envio.total_enviados += 1;
        this.envio.calculo_enviados = Number(this.envio.calculo_enviados) + 1;;
        //this.envio.calculo_total = Number(this.envio.calculo_total) + 1;;

        this.criarMensagemDB(formatedPhone, text, this.envio.id, this.container.id);
        this.atualizarTotalEnviados(this.envio.id);
        this.atualizarStatusNumeroDB(this.envio.id, phone, "ENVIADO", formatedPhone);
      }
    },

    posEnvio() {
      console.log("POS ENVIO");
      console.log("Enviados " + this.envio.calculo_enviados);
      console.log("Invalidos " + this.envio.calculo_invalidos);
      console.log("Total " + this.envio.calculo_total);

      if (
        Number(this.envio.calculo_enviados) + Number(this.envio.calculo_invalidos) ==
        Number(this.envio.calculo_total)) {

        this.message = "Envio concluído";

        this.envio_concluido = true;

        this.envio.status_do_envio = "CONCLUIDO";

        window.location.reload();
      }
    },

    buscarFunil() {
      console.log("Buscando funil " + this.envio.funil_id);

      let data = {
        funil_id: this.envio.funil_id,
      };

      axios
        .post(`/funil/search`, data)
        .then((response) => {
          console.log("Retorno dos FUNIS");
          console.log(response.data);

          this.funil = response.data.funil;

          console.log(this.funil);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    checkFunil(phone) {
      console.log("\n\n\nVerificando funil");
      console.log(this.container.url);

      //CÓDIGO OFICIAL DO FUNIL
      if (this.funil) {
        console.log("ADICIONANDO AO FUNIL");

        this.addToFunil(phone, this.funil.id);
        //this.sendToWebhook(this.container.id, phone, this.funil.id, this.apiId, this.usuario.porta);
      } else {
        console.log("FUNIL EM BRANCO");
      }
    },

    addToFunil(phone, idFunil) {
      console.log("\n\nADICIONANDO AO FUNIL");

      console.log(process.env.MIX_VUE_APP_ENDPOINT + this.usuario.porta + `/funil/add/` + this.container.id + `/` + phone + `/` + idFunil + "/" + this.container.chave_api);

      axios.get(process.env.MIX_VUE_APP_ENDPOINT + this.usuario.porta + `/funil/add/` + this.container.id + `/` + phone + `/` + idFunil + "/" + this.container.chave_api)
        .then((response) => {
          console.log("\n\n\n\n\nRetorno da api FUNIL");
          console.log(response.data);
        })
        .catch((error) => {
          console.log("\n\n\n\n\nERRO da api FUNIL");
          console.log(error);
        });
    },

    getTime() {
      let time = Math.floor(Math.random() * ((this.maxTime - this.minTime) + 1)) + this.minTime;
      //let time = Math.floor(Math.random() * (30 - 1 + 1)) + 20;

      //let time = Math.floor(Math.random() * (5 - 1 + 1)) + 2;

      //this.message = "Próxima mensagem em " + time + " segundos";

      console.log("Aguardando " + time + " segundos...");

      return time;
    },

    formatarMensagem(mensagem, numero) {
      //VERIFICANDO SE O ENVIO POSSUI METADADADOS
      //O ENVIO SÓ POSSUI METADADOS CASO TENHA SIDO ENVIADO UM JSON
      if (this.envio.meta_dados) {
        //BUSCANDO TAGS ADICIONADAS NA MENSAGEM
        let arrayTags = this.localizar_tags(this.envio.mensagem);

        console.log("TAGS ENCONTRADAS");
        console.log(arrayTags);

        //VERIFICANDO SE O TEXTO POSSUI ALGUMA TAG
        if (arrayTags && arrayTags.length > 0) {
          //BUSCANDO OS DADOS A PARTIR DO NUMERO NO JSON META_DADOS DO ENVIO
          let json = JSON.parse(this.envio.meta_dados);

          let matchJSON = this.buscarNumeroNoJSON(numero, json);

          //CASO ENCONTRE OS DADOS NO JSON
          if (matchJSON) {
            let mensagemFinal = this.envio.mensagem;

            for (var prop in matchJSON) {
              //VERIFICANDO SE O TEXTO CONTEM UMA TAG COM A CHAVE DO JSON
              if (this.envio.mensagem.includes("<" + prop + ">")) {
                console.log("ENTROU NO IF");
                mensagemFinal = this.replaceAll(
                  mensagemFinal,
                  "<" + prop + ">",
                  matchJSON[prop]
                );
              }
            }

            return mensagemFinal;
          }
        }
      }

      return mensagem;
    },

    buscarNumeroNoJSON(numero) {
      console.log("BUSCANDO MATCH PARA " + numero);

      let json = JSON.parse(JSON.stringify(this.json_envio));

      console.log(this.json_envio);
      console.log(json);

      if (numero.length >= 8) {

        for (let i = 0; i < json.length; i++) {

          let telefone = "";

          if (json[i].Telefone) {
            telefone = json[i].Telefone;
          } else {
            telefone = json[i].telefone;
          }

          if (telefone) {
            telefone = telefone.replace(/[^Z0-9]/g, "");
            telefone = telefone.slice(telefone.length - 8);

            //VERIFICANDO COM 'INCLUDES' POIS O CÓDIGO ADICIONA O 55 NO NUMERO E NÃO NO JSON
            console.log(json[i]);
            console.log("CHECANDO TRUE - " + json[i].enviado + " Possui ? " + !json[i].enviado);
            console.log("COMPARANDO " + telefone);

            if (numero.includes(telefone) && telefone.length >= 8 && (!json[i].enviado || json[i].enviado != true)) {
              console.log("ACHOU");
              json[i].enviado = true;
              this.json_envio[i].enviado = true;

              for (let j = 0; j < json.length; j++) {
                for (var prop in json[j]) {
                  if (typeof json[j][prop] == "string") {
                    json[j][prop] = this.replaceAll(json[j][prop], "\\n", "\n");
                    json[j][prop] = this.replaceAll(json[j][prop], "\n", "\\n");
                  }
                }
              }

              this.salvarJSONNoEnvio(JSON.stringify(json), this.envio.id);

              return this.json_envio[i];
            }
          } else {
            console.log("Campo telefone não encontrado");
          }
        }
      } else {
        console.log("Número inválido na validação do JSON");
      }
    },

    salvarJSONNoEnvio(json, id_envio) {

      console.log("ATUALIZANDO JSON");

      let data = {
        json: json,
        id_envio: id_envio,
      };

      console.log(data);

      axios
        .post('/send/update_json', data)
        .then((response) => {
          if (response.data) {
            console.log("JSON ATUALIZADO");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    replaceAll(string, search, replace) {
      return string.split(search).join(replace);
    },

    localizar_tags(texto) {
      var emails = new Array();
      let i = 0;

      while (texto.search("<") != -1) {
        let pos_inicio = texto.search("<");
        let pos_fim = texto.search(">");

        let email = texto.substring(pos_inicio + 1, pos_fim);
        emails[i] = email;

        texto = texto.substring(pos_fim + 1, texto.length);
        i++;
      }

      return emails;
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}

.message {
  color: rgb(40, 65, 177);
  font-weight: bold;
  font-size: 15px;
}
</style>
