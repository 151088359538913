<template>
  <div @click="firstClick" @click.self="firstClick">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

    <br />
    <!-- FILTRAR USUÁRIOS-->
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Filtrar clientes por funil</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label for="endereco_estado">Funil</label>

              <select class="custom-select rounded-0" v-model="funil_selecionado" @change="buscarClientesFunil()">
                <option v-for="funil in funis" :value="funil" :key="funil">{{ funil.nome }}</option>
              </select>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label for="endereco_estado">Etapa</label>

              <select class="custom-select rounded-0" v-model="mensagem_selecionada" @change="buscarClientesFunil()">
                <option value=""></option>
                <option v-for="mensagem in mensagens_funil" :value="mensagem.tag" :key="mensagem">{{ mensagem.tag }}</option>
                <option value="null">Concluído</option>
              </select>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label
                for="estado_civil"
                class="input-group-text my-group-text"
                style="color: black; margin-left: 25px; background-color: white !important; border: none"
              >
                Somente pendentes
              </label>
              <input
                style="margin-left: 35px; height: 25px; width: 25px; margin-top: 5px"
                v-model="somente_pendentes"
                type="checkbox"
                @change="buscarClientesFunil()"
              />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label for="endereco_estado">De</label>

              <input v-model="dataInicio" type="date" class="form-control" />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label for="endereco_estado">Até</label>

              <input v-model="dataFim" type="date" class="form-control" />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label for="endereco_estado">Filtrar por</label>

              <select class="custom-select rounded-0" v-model="tipo_filtro_data" @change="buscarClientesFunil()">
                <option value="inicio">Data de ínicio</option>
                <option value="resposta">Última resposta</option>
              </select>
            </div>
          </div>
          <div class="col-sm-12">
            <button type="submit" class="btn btn-primary" @click="hiddenbuscarClientesFunil" id="hiddenSearch" hidden>Play</button>
            <button type="submit" class="btn btn-primary" @click="buscarClientesFunil">Buscar</button>
            <button type="submit" class="btn btn-default" @click="limparFiltros" style="margin-left: 5px">Limpar filtros</button>
          </div>
        </div>
      </div>
    </div>

    <button class="btn btn-info" @click="ExportExcel('xlsx')">
      <i class="fa-solid fa-download" style="margin-right: 5px"></i>
      Baixar Excel xlsx
    </button>
    <!-- TABELA USUÁRIOS-->
    <div class="card card-primary">
      <div class="card-header">
        <h3 class="card-title">
          Clientes
          <span class="badge badge-warning" style="margin-left: 5px">{{ leads.length }}</span>
        </h3>
      </div>
      <div class="card-body p-0">
        <table class="table">
          <thead>
            <tr>
              <th>Número</th>
              <th>Data início</th>
              <th>Finalizado</th>
              <th>Etapa atual</th>
              <th>Última resposta</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="container in leads" :key="container">
              <td data-label="Número">{{ container.numero }}</td>
              <td data-label="Data início">{{ formatDate(container.created_at) }}</td>
              <td data-label="Finalizado">
                <span v-if="container.concluido == 1">Sim</span>
                <span v-if="container.concluido == 0">Não</span>
              </td>
              <td data-label="Etapa atual">{{ container.tag }}</td>
              <td data-label="Última resposta">{{ formatDate(container.updated_at) }}</td>
              <td data-label="Ações">
                <button
                  style="margin-left: 5px"
                  class="btn btn-success btn-sm"
                  data-toggle="modal"
                  data-target="#create-lead-modal"
                  @click="selecionarCliente(container)"
                >
                  Criar lead
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card-footer clearfix">
        <!-- TOTAIS  -->
        <div style="font-size: 13px" class="float-left" aria-live="polite">
          Exibindo do {{ inicio + 1 }} ao {{ inicio + leads.length }}
          <p>Total {{ total }}</p>
        </div>

        <p class="card-title" style="font-size: 12px; color: black; margin-right: 5px; margin-left: 10px">Por página</p>

        <select @change="alterarQtdPagina()" class="float-left card-titlecustom-select rounded-0" style="font-size: 12px" v-model="qtd_por_pagina">
          <option :value="1">1</option>
          <option :value="2">2</option>
          <option :value="7">7</option>
          <option :value="15">15</option>
          <option :value="25">25</option>
          <option :value="50">50</option>
          <option :value="75">75</option>
          <option :value="100">100</option>
          <option :value="200">200</option>
          <option :value="250">250</option>
        </select>

        <div class="card-tools">
          <div class="input-group input-group-sm" style="width: 150px"></div>
        </div>

        <!-- PAGINAÇÃO  -->
        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item"><a class="page-link" @click="selecionarPagina(pagina_atual - 1)">&laquo;</a></li>

          <li v-for="pagina in paginas" :key="pagina" :value="pagina" class="page-item">
            <a :class="pagina === pagina_atual ? 'page-link current-page' : 'stopped'" class="page-link" @click="selecionarPagina(pagina)">
              {{ pagina }}
            </a>
          </li>

          <li class="page-item"><a class="page-link" @click="selecionarPagina(pagina_atual + 1)">&raquo;</a></li>
        </ul>
      </div>
    </div>

    <table class="table" ref="exportable_table" v-show="false">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Telefone</th>
          <th>Cnpj</th>
          <th>Vidas</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="container in leads" :key="container">
          <td data-label="Nome">
            <span v-if="container.json_leads">{{ container.json_leads.nome }}</span>
          </td>
          <td data-label="Telefone">{{ container.numero }}</td>
          <td data-label="Cnpj">
            <span v-if="container.json_leads">
              <span v-if="container.json_leads.cnpj == 1">Sim</span>
              <span v-else>Não</span>
            </span>
          </td>
          <td data-label="Vidas">
            <span v-if="container.json_leads">{{ container.json_leads.qtd_vidas }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      @click.self="cancelModal"
      class="modal fade"
      id="create-lead-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <form @submit.prevent>
        <div class="modal-dialog" style="text-align: left">
          <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              <h4>Cadastrar lead</h4>
              <h6 style="color: red" v-if="cliente_selecionado.concluido == 1">
                * Este lead tá com status concluído, verifique se já foi criado na aba leads
              </h6>

              <div class="form-group">
                <label for="email">Nome</label>
                <input id="nome" type="text" class="form-control" v-model="formData.nome" autocomplete="name" />

                <span style="color: red" v-for="error in v$.nome.$errors" :key="error.$uid">
                  {{ error.$message }}
                </span>
              </div>

              <div class="form-group">
                <label for="password">Número</label>
                <input id="porta" type="porta" class="form-control" v-model="formData.number" />

                <span style="color: red" v-for="error in v$.number.$errors" :key="error.$uid">
                  {{ error.$message }}
                </span>
              </div>

              <div class="form-group">
                <label for="password">CNPJ</label>

                <select class="custom-select rounded-0" v-model="formData.cnpj">
                  <option value="Não">Não</option>
                  <option value="Sim">Sim</option>
                </select>

                <span style="color: red" v-for="error in v$.cnpj.$errors" :key="error.$uid">
                  {{ error.$message }}
                </span>
              </div>

              <div class="form-group">
                <label for="password">Vidas</label>
                <input id="porta" type="porta" class="form-control" v-model="formData.qtd_vidas" />

                <span style="color: red" v-for="error in v$.qtd_vidas.$errors" :key="error.$uid">
                  {{ error.$message }}
                </span>
              </div>

              <div class="form-group">
                <label for="password">Confirmação</label>

                <select class="custom-select rounded-0" v-model="formData.confirmacao">
                  <option value="Não">Não</option>
                  <option value="Sim">Sim</option>
                </select>

                <span style="color: red" v-for="error in v$.confirmacao.$errors" :key="error.$uid">
                  {{ error.$message }}
                </span>
              </div>

              <div class="form-group">
                <label for="password">Origem</label>

                <select class="custom-select rounded-0" v-model="formData.origem">
                  <option value="qualificacao">Qualificacao</option>
                  <option value="redisparo">Redisparo</option>
                  <option value="SP">SP</option>
                </select>
              </div>

              <button @click="criarLead()" class="btn btn-success btn-sm">Salvar</button>

              <button
                id="close_create_container"
                style="margin-left: 5px"
                class="btn btn-danger btn-sm"
                data-toggle="modal"
                data-target="#create-lead-modal"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import wppController from "../../controller/wppController";
import sendController from "../../controller/sendController";
import sweetAlert from "../../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

import useVueLidate from "@vuelidate/core";
import { required } from "../../locales/i18n.js";
import { computed, reactive } from "vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [wppController, sendController, sweetAlert, Swal],

  components: { Loading },

  props: ["funis", "users", "parceiros"],

  setup() {
    const formData = reactive({
      nome: "",
      number: "",
      origem: "qualificacao",
      cnpj: "",
      qtd_vidas: "",
      confirmacao: "",
    });

    const rules = computed(() => {
      return {
        nome: { required },
        number: { required },
        origem: { required },
        qtd_vidas: { required },
        cnpj: { required },
        confirmacao: { required },
      };
    });

    const v$ = useVueLidate(rules, formData);

    return { formData, v$ };
  },

  data() {
    return {
      container: {},
      nome: "",
      email: "",
      senha: "",
      isLoading: false,
      lead_selecionado: "",
      leads: [],
      acoes_usuario: [],
      acao_selecionada: "",
      filtro: "",
      paginas: [],
      inicio: 0,
      total: 0,
      qtd_por_pagina: 25,
      pagina_atual: 1,
      editar_lead: false,
      criar_lead: false,
      user_selecionado: "",
      origem_selecionada: "",
      telefone_teste: "",
      mensagem_teste_envio: "",
      enviado_para: "",
      notificacao_sonora: false,
      is_first_click: true,
      ultima_linha_excel: "",
      somente_pendentes: false,
      mensagens_funil: [],
      mensagem_selecionada: "",
      dataInicio: "",
      dataFim: "",
      tipo_filtro_data: "inicio",
      cliente_selecionado: "",
    };
  },

  mounted() {
    //BUSCANDO DATAS
    this.dataFim = new Date();
    this.dataInicio = new Date();

    this.dataInicio = this.formatSelectedDate(this.dataInicio);
    this.dataFim = this.formatSelectedDate(this.dataFim);
  },

  methods: {
    ExportExcel(type, fn, dl) {
      var elt = this.$refs.exportable_table;
      var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(wb, fn || ("Leads " + "." || "SheetJSTableExport.") + (type || "xlsx"));
    },

    selecionarCliente(cliente) {
      console.log("Cliente selecionado");
      console.log(cliente);

      this.cliente_selecionado = cliente;

      this.formData.nome = "";
      this.formData.number = cliente.numero;
      this.formData.origem = "qualificacao";
      this.formData.qtd_vidas = "";
      this.formData.cnpj = "";
      this.formData.confirmacao = "Sim";

      if (cliente.meta_dados) {
        let json = JSON.parse(cliente.meta_dados);

        if (json.nome) {
          this.formData.nome = json.nome;
        }

        if (json.cnpj) {
          if (json.cnpj == 1) {
            this.formData.cnpj = "Sim";
          } else {
            this.formData.cnpj = "Não";
          }
        }

        if (json.qtd_vidas) {
          this.formData.qtd_vidas = json.qtd_vidas;
        }
      }
    },

    async criarLead() {
      const result = await this.v$.$validate();

      if (result) {
        this.isLoading = true;

        let data = {
          nome: this.formData.nome,
          number: this.formData.number,
          origem: this.formData.origem,
          cnpj: this.formData.cnpj,
          qtd_vidas: this.formData.qtd_vidas,
          confirmacao: this.formData.confirmacao,
        };

        axios
          .post(`/admin/lead/store`, data)
          .then((response) => {
            this.encerrarFunil();
          })
          .catch((error) => {
            this.isLoading = false;
            this.showErrorMessageWithButton("Ops..", error.response.data);
            console.log(error.response.data);
          });
      }
    },

    encerrarFunil() {
      let data = {
        id: this.cliente_selecionado.id,
      };

      axios
        .post(`/admin/clientes_funil/encerrar_funil`, data)
        .then((response) => {
          this.isLoading = false;

          this.showSuccessMessage("Lead cadastrado!");
          this.buscarClientesFunil();
        })
        .catch((error) => {
          this.isLoading = false;
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    copiarLead(lead) {
      let that = this;

      this.$copyText(this.formatarMensagem(lead)).then(
        function (e) {
          console.log(e);
          that.showSuccessMessage("Dados copiados!");
        },
        function (e) {
          alert("Can not copy");
          console.log(e);
        }
      );
    },

    formatarMensagem(lead) {
      let mensagem =
        "*Nome:* " + lead.nome + "\n\n" + "*Telefone:* " + lead.number + "\n\n" + "*CNPJ:* " + lead.cnpj + "\n\n" + "*Vidas:* " + lead.qtd_vidas;

      return mensagem;
    },

    firstClick() {
      if (this.is_first_click) {
        this.notificacao_sonora = true;
        this.is_first_click = false;
      }
    },

    limparFiltros() {
      this.enviado_para = "";
      this.filtro = "";
      this.origem_selecionada = "";

      this.buscarClientesFunil();
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },

    getFormatedText(title) {
      if (title && title.length > 18) {
        return title.substring(0, 18) + "...";
      } else {
        return title;
      }
    },

    buscarClientesFunil() {
      if (this.funil_selecionado) {
        let aux_dataInicio = this.formatSelectedDate(this.dataInicio);
        let aux_dataFim = this.formatDateToSearch(this.dataFim);

        let data = {
          data_inicio: aux_dataInicio,
          data_fim: aux_dataFim,
          enviado_para: this.enviado_para,
          filtro: this.filtro,
          inicio: this.inicio,
          tamanho: this.qtd_por_pagina,
          somente_pendentes: this.somente_pendentes,
          funil_id: this.funil_selecionado.id,
          tipo_filtro_data: this.tipo_filtro_data,
        };

        if (this.mensagem_selecionada) {
          data.tag = this.mensagem_selecionada;
        }

        console.log("Buscando");
        console.log(data);

        axios
          .post(`/admin/clientes_funil/search`, data)
          .then((response) => {
            this.leads = response.data.clientes_funil;
            this.total = response.data.total;

            this.mensagens_funil = response.data.mensagens_funil;

            this.isLoading = false;

            for (let i = 0; i < this.leads.length; i++) {
              if (this.leads[i].meta_dados) {
                this.leads[i].json_leads = JSON.parse(this.leads[i].meta_dados);
              }
            }

            console.log("leads");
            console.log(this.leads);
            console.log(response.data);

            if (response.data.automation_controll) {
              this.ultima_linha_excel = response.data.automation_controll.last_row_excel_leads;
            } else {
              this.ultima_linha_excel = "";
            }

            this.pagination(response.data);
          })
          .catch((error) => {
            console.log(error);
            this.showErrorMessageWithButton("Ops..", error.response.data);
            console.log(error.response.data);
          });
      } else {
        this.showErrorMessageWithButton("Ops...", "Selecione um funil");
      }
    },

    alterarQtdPagina() {
      this.pagina_atual = 1;
      this.inicio = 0;

      this.buscarClientesFunil();
    },

    formatSelectedDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },

    formatDateToSearch(date) {
      return moment(date).format("yyyy-MM-DD 23:59:59");
    },

    selecionarPagina(pagina) {
      if (pagina <= this.paginas.length && pagina > 0) {
        this.inicio = this.qtd_por_pagina * (pagina - 1);
        this.pagina_atual = pagina;
        this.buscarClientesFunil();
      }
    },

    pagination(data) {
      this.paginas = [];

      let length = data.total / this.qtd_por_pagina;
      let i = 0;

      //DEFININDO MÁXIMO E MÍNIMO DE PÁGINAS
      if (length < 0) {
        length = 0;
      } else if (length > 10) {
        if (this.pagina_atual >= 10) {
          let minimo = this.pagina_atual - 10;

          if (length - this.pagina_atual > 10) {
            length = this.pagina_atual + 10;
            i = minimo;
          } else {
            i = this.pagina_atual - 10 + (length - this.pagina_atual);
          }
        } else {
          length = 10;
        }
      }

      for (i; i < length; i++) {
        this.paginas.push(i + 1);
      }
    },

    selecionarContainer(usuario) {
      this.lead_selecionado = usuario;
    },

    addAcao() {
      this.acoes_usuario.push(this.acao_selecionada.acao);
    },

    confirmarDelete(container) {
      let html = "Deseja <b style='color: red'> deletar</b> o lead " + "<b style='font-size:25px'>" + container.nome + "?";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!",
        })
        .then((result) => {
          if (result.value) {
            this.deletar(container);
          }
        });
    },

    deletar(container) {
      let data = {
        id: container.id,
      };

      axios
        .post(`/admin/lead/delete`, data)
        .then((response) => {
          this.showSuccessMessage("Conexão deletada");

          this.buscarClientesFunil();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    update() {
      let data = {
        id: this.lead_selecionado.id,
        acoes_personalizadas: JSON.stringify(this.acoes_usuario),
      };

      axios
        .post(`/admin/leads/update/acoes`, data)
        .then((response) => {
          this.showSuccessMessage("Ações atualizadas!");

          window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
