<template>
  <button @click="connect()" v-if="!isConnected" id="qrCode" type="button" class="btn btn-block btn-primary btn-sm">
    QR Code
  </button>

  <button @click="disconnect()" v-if="isConnected" type="button" class="btn btn-block btn-outline-danger btn-sm">
    DESCONECTAR
  </button>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";

export default {
  props: ["my_container", "teste", "usuario"],

  mixins: [wppController, sweetAlert],

  data() {
    return {
      container: {},
      isConnected: false,
      porta: ""
    };
  },

  mounted() {
    this.container = this.my_container;

    console.log("Load component");
    console.log(this.container);

    if (this.my_container.porta) {
      this.porta = this.my_container.porta;
    } else {
      this.porta = this.usuario.porta;
    }

    this.getStatus();
  },

  methods: {
    connect() {
      console.log("Carregando QR - Sessão = " + this.container.chave_api);

      this.axios
        .get(process.env.MIX_VUE_APP_ENDPOINT + this.porta + `/load/` + this.container.id + "/" + this.container.chave_api + "/" + this.container.user_id)
        .then((response) => {
          console.log("Resposta do carregamento ");
          console.log(this.porta);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    disconnect() {
      this.confirmWarning("Deseja desconectar sua sessão?", () => {
        this.axios
          .get(
            process.env.MIX_VUE_APP_ENDPOINT + this.porta + `/logout/` + this.container.chave_api
          )
          .then((response) => {
            console.log("Resposta do carregamento ");
            console.log(response.data);

            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },

    getStatus() {
      this.getConnectionStatus(this.container.chave_api, this.porta, (status) => {
        console.log("STATUS");
        console.log(status);

        if (status == "CONNECTED" || status == "inChat") {
          this.isConnected = true;
        } else {
          this.isConnected = false;
        }
      });
    },
  },
};
</script>
