<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

  <div
    @click.self="cancelModal"
    class="modal fade"
    id="create-lead-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <form @submit.prevent>
      <div class="modal-dialog" style="text-align: left">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            <h4>Cadastrar lead</h4>

            <div class="form-group">
              <label for="email">Nome</label>
              <input id="nome" type="text" class="form-control" v-model="formData.nome" autocomplete="name" />

              <span style="color: red" v-for="error in v$.nome.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">Número</label>
              <input id="porta" type="porta" class="form-control" v-model="formData.number" />

              <span style="color: red" v-for="error in v$.number.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">CNPJ</label>

              <select class="custom-select rounded-0" v-model="formData.cnpj">
                  <option value="Não">Não</option>
                  <option value="Sim">Sim</option>
                </select>

              <span style="color: red" v-for="error in v$.cnpj.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">Vidas</label>
              <input id="porta" type="porta" class="form-control" v-model="formData.qtd_vidas" />

              <span style="color: red" v-for="error in v$.qtd_vidas.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">Confirmação</label>
              <input id="porta" type="porta" class="form-control" v-model="formData.confirmacao" />

              <span style="color: red" v-for="error in v$.confirmacao.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">Origem</label>

              <select class="custom-select rounded-0" v-model="formData.origem" @change="searchLeads()">
                <option value="importacao">Importação</option>
                <option value="formulario">Formulário</option>
              </select>

              <span style="color: red" v-for="error in v$.origem.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <button @click="store()" class="btn btn-success btn-sm">Salvar</button>

            <button
              id="close_create_container"
              style="margin-left: 5px"
              class="btn btn-danger btn-sm"
              data-toggle="modal"
              data-target="#create-lead-modal"
              @click="setCriarContainer(false)"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import wppController from "../../controller/wppController.js";
import sweetAlert from "../../controller/sweetAlert.js";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import useVueLidate from "@vuelidate/core";
import { required } from "../../locales/i18n.js";
import { computed, reactive } from "vue";

export default {
  mixins: [wppController, sweetAlert, Swal],

  components: { Loading },

  props: ["container", "setCriarContainer", "containerCriado"],

  data() {
    return {
      nome: "",
      email: "",
      senha: "",
      isLoading: false,
      my_container: {},
    };
  },

  setup() {
    const formData = reactive({
      nome: "",
      number: "",
      origem: "formulario",
      cnpj: "",
      qtd_vidas: "",
      confirmacao: "",
    });

    const rules = computed(() => {
      return {
        nome: { required },
        number: { required },
        origem: { required },
        qtd_vidas: { required },
        cnpj: { required },
        confirmacao: { required },
      };
    });

    const v$ = useVueLidate(rules, formData);

    return { formData, v$ };
  },

  mounted() {},

  methods: {
    selecionarUsuario() {
      if (this.formData.user_id) {
        let indice = this.users.findIndex((element) => element["id"] == this.formData.user_id);

        this.formData.porta = this.users[indice].porta;
        this.formData.servidor = this.users[indice].url;
      }
    },

    cancelModal() {
      this.setCriarContainer(false);
    },

    async store() {
      const result = await this.v$.$validate();

      if (result) {
        this.isLoading = true;

        let data = {
          nome: this.formData.nome,
          number: this.formData.number,
          origem: this.formData.origem,
          cnpj: this.formData.cnpj,
          qtd_vidas: this.formData.qtd_vidas,
          confirmacao: this.formData.confirmacao,
        };

        axios
          .post(`/admin/lead/store`, data)
          .then((response) => {
            this.isLoading = false;

            this.showSuccessMessage("Lead cadastrado!");
            this.containerCriado();
          })
          .catch((error) => {
            this.isLoading = false;
            this.showErrorMessageWithButton("Ops..", error.response.data);
            console.log(error.response.data);
          });
      }
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
