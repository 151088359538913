<template>
  <div id="app" class="app">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

    <br />
    <div class="row">
      <div class="col-md-4 col-sm-6 col-12">
        <div class="info-box">
          <span class="info-box-icon bg-success"><i style="color: white" class="fas fa-solid fa-users"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Total de Envios para Continuar</span>
            <span class="info-box-number">{{ total }}</span>
          </div>
        </div>
      </div>
    </div>

    <button style="max-width: 50px" @click="validateToDelete()" type="button" class="btn btn-danger btn-block btn-sm">
      <i class="fa fa-trash"></i>
    </button>

    <table>
      <thead>
        <tr>
          <th style="width: 5%"></th>
          <th scope="col" style="width: 30%">Nome</th>
          <th scope="col">Data</th>
          <th scope="col" v-if="containers.length > 1">Container</th>
          <th scope="col">Contatos</th>
          <th scope="col">Ações</th>
          <th scope="col" v-if="user.api_name == 'ampla'">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="envio in envios" :key="envio.id">
          <td data-label="Região">
            <input type="checkbox" :id="envio.id" :value="envio.id" v-model="envios_selecionados" />
          </td>
          <td data-label="Nome" style="color: black">
            {{ envio.nome }}
          </td>
          <td data-label="Data" style="color: black">
            {{ formatDate(envio.created_at) }}
          </td>
          <td v-if="containers.length > 1" data-label="Container" style="color: black">
            {{ envio.container }}
          </td>
          <td data-label="Contatos" style="color: black">
            {{ envio.total_contatos }}
          </td>
          <td data-label="Ações" style="color: black">
            <a target="_blank" :href="getUrl(envio)">CONTINUAR</a>
          </td>

          <td data-label="Ações" v-if="user.api_name == 'ampla'">
            <a target="_blank" :href="getUrlClickmassa(envio)" style="color: rgb(207, 38, 38)">ClickMassa</a>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="card-footer clearfix">
      <!-- TOTAIS  -->
      <div style="font-size: 13px" class="float-left" aria-live="polite">
        Exibindo do {{ inicio + 1 }} ao {{ inicio + envios.length }}
        <p>Total {{ total }}</p>
      </div>

      <p class="card-title" style="font-size: 12px; color: black; margin-right: 5px; margin-left: 10px">Por página</p>

      <select @change="alterarQtdPagina()" class="float-left card-titlecustom-select rounded-0" style="font-size: 12px" v-model="qtd_por_pagina">
        <option :value="1">1</option>
        <option :value="5">5</option>
        <option :value="10">10</option>
        <option :value="15">15</option>
        <option :value="25">25</option>
      </select>

      <div class="card-tools">
        <div class="input-group input-group-sm" style="width: 150px"></div>
      </div>

      <!-- PAGINAÇÃO  -->
      <ul class="pagination pagination-sm m-0 float-right">
        <li class="page-item"><a class="page-link" href="#" @click="selecionarPagina(pagina_atual - 1)">&laquo;</a></li>

        <li v-for="pagina in paginas" :key="pagina" :value="pagina" class="page-item">
          <a :class="pagina === pagina_atual ? 'page-link current-page' : 'stopped'" class="page-link" href="#" @click="selecionarPagina(pagina)">
            {{ pagina }}
          </a>
        </li>

        <li class="page-item"><a class="page-link" href="#" @click="selecionarPagina(pagina_atual + 1)">&raquo;</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import sweetAlert from "../controller/sweetAlert";

export default {
  name: "ContinueSends",

  props: ["containers", "user"],

  mixins: [sweetAlert],

  components: { Loading },

  data() {
    return {
      messages: [],
      envios: [],
      isLoading: false,
      envios_selecionados: [],
      total: 0,
      inicio: 0,
      paginas: [],
      pagina_atual: 1,
      pagina_atual: 1,
      qtd_por_pagina: 10,
    };
  },

  mounted() {
    console.log("Exibindo agora");
    console.log(this.containers);

    this.buscarEnviosEmAberto();
  },

  methods: {
    buscarEnviosEmAberto() {
      this.isLoading = true;

      let data = {
        user_id: this.user.id,
        inicio: this.inicio,
        tamanho: this.qtd_por_pagina,
      };

      axios
        .post(`/user/envio/search_em_aberto`, data)
        .then((response) => {
          this.envios = response.data.envios;
          this.total = response.data.total;

          console.log("Envios");
          console.log(this.envios);

          this.isLoading = false;

          this.pagination(response.data);
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    pagination(data) {
      this.paginas = [];

      let length = data.total / this.qtd_por_pagina;
      let i = 0;

      //DEFININDO MÁXIMO E MÍNIMO DE PÁGINAS
      if (length < 0) {
        length = 0;
      } else if (length > 10) {
        if (this.pagina_atual >= 10) {
          let minimo = this.pagina_atual - 10;

          if (length - this.pagina_atual > 10) {
            length = this.pagina_atual + 10;
            i = minimo;
          } else {
            i = this.pagina_atual - 10 + (length - this.pagina_atual);
          }
        } else {
          length = 10;
        }
      }

      for (i; i < length; i++) {
        this.paginas.push(i + 1);
      }
    },

    alterarQtdPagina() {
      this.pagina_atual = 1;
      this.inicio = 0;

      this.buscarEnviosEmAberto();
    },

    selecionarPagina(pagina) {
      if (pagina <= this.paginas.length && pagina > 0) {
        this.inicio = this.qtd_por_pagina * (pagina - 1);
        this.pagina_atual = pagina;
        this.buscarEnviosEmAberto();
      }
    },

    somar(envio) {
      return Number(envio.calculo_enviados) + Number(envio.calculo_invalidos);
    },

    getUrl(envio) {
      return "/send/" + envio.id + "/true";
    },

    getUrlClickmassa(envio) {
      return "/send/clickmassa/" + envio.id + "/true";
    },

    hidePdi() {
      this.showPdi = false;
    },

    back() {
      this.resend = false;
    },

    validateToDelete() {
      if (this.envios_selecionados.length == 0) {
        this.showErrorMessageWithButton("Ops...", "Você precisa selecionar um ou mais leads");
      } else {
        this.confirmarDelete();
      }
    },

    confirmarDelete() {
      let html =
        "Deseja <b style='font-size:20px'>Deletar </b> os <b style='font-size:25px'>" + this.envios_selecionados.length + "</b> envios selecionados?";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!",
        })
        .then((result) => {
          if (result.value) {
            this.deletar();
          }
        });
    },

    deletar() {
      this.isLoading = true;

      let data = {
        envios: this.envios_selecionados,
      };

      axios
        .post("/send/delete", data)
        .then((response) => {
          this.showSuccessMessage("Deletado com sucesso!");

          window.location.reload();
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY - HH:mm");
    },

    formatSelectedDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },

    formatDateToSearch(date) {
      return moment(date).format("yyyy-MM-DD 23:59:59");
    },
  },
};
</script>

<style scoped>

.current-page{
  background-color: #0d6efd;
  color: white;
}

label {
  font-weight: normal;
  color: gray;
}

.stati {
  background: #fff;
  width: 12em;
  height: 4em;
  padding: 1em;
  margin: 1em 0;
  /* Safari */
  box-shadow: 0px 0.2em 0.3em rgb(0, 0, 0, 0.8);
}

.stati.bg-orange {
  background: rgb(243, 156, 18);
  color: white;
}

.stati.bg-nephritis {
  background: rgb(39, 174, 96);
  color: white;
}

.stati.bg-peter_river {
  background: rgb(52, 152, 219);
  color: white;
}

.stati.bg-green_sea {
  background: rgb(22, 160, 133);
  color: white;
}

.stati div {
  width: calc(100% - 3.5em);
  display: block;
  float: right;
  text-align: right;
}

.stati fa {
  font-size: 3.5em;
}

.stati div {
  width: calc(100% - 3.5em);
  display: block;
  float: right;
  text-align: right;
}

.stati div b {
  font-size: 2.2em;
  width: 100%;
  padding-top: 0px;
  margin-top: -0.2em;
  margin-bottom: -0.2em;
  display: block;
}

.stati div span {
  font-size: 1em;
  width: 100%;
  color: rgb(0, 0, 0, 0.8);
  display: block;
}

.stati.left div {
  float: left;
  text-align: left;
}

.stati.bg-turquoise {
  background: rgb(26, 188, 156);
  color: white;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table tr:nth-child(even) {
  background: #fff;
}

table tr:nth-child(odd) {
  background: #eee;
}

table.pdi tr:nth-child(even) {
  background: #fff;
}

table.pdi tr:nth-child(odd) {
  background: #80f0af;
}

table caption {
  font-size: 1.2em;
  margin: 0.1em 0 0.5em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.1em;
}

table th,
table td {
  padding: 0.2em;
  text-align: center;
  font-size: 0.9em;
}

table th {
  font-size: 0.7em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    padding: 0.4em;
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.9em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

.cursor {
  cursor: pointer;
}

.button-voltar {
  background-color: #b8ab39;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.button-select-date {
  background-color: white;
  color: black;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  width: 100%;
  margin: 4px 2px;
  cursor: pointer;
}

.button-select-date-focused {
  background-color: white;
  color: black;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  width: 100%;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.9);
  -moz-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.9);
  box-shadow: 0 0 3px 1px rgba(255, 169, 0, 0.9);
}

.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17;
  border-radius: 5px;
  width: 100%;
  margin: 4px 2px;
  cursor: pointer;
}

.button-exit {
  background-color: #a67e21;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

body {
  font-family: Verdana, Geneva, sans-serif;
  font-size: 14px;
  background: #f2f2f2;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
}

.form_wrapper {
  width: 500px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 15px;
  margin: 0% auto 0;
  position: relative;
  z-index: 1;
}

.form_container {
  padding: 15px;
}

.form_wrapper h2 {
  font-size: 1.5em;
  line-height: 1.5em;
  margin: 0;
}

.form_wrapper .title_container {
  text-align: center;
  margin: -15px -15px 15px;
  padding: 15px 0;
}

.form_wrapper h3 {
  font-size: 1.1em;
  font-weight: normal;
  line-height: 1.5em;
  margin: 0;
}

.form_wrapper .row {
  margin: 10px -15px;
}

.form_wrapper .row > div {
  padding: 0 15px;
  box-sizing: border-box;
}

.form_wrapper .col_half {
  width: 50%;
  float: left;
}

.form_wrapper .col_full {
  width: 100%;
  float: left;
}

.form_wrapper .col_three {
  width: 33%;
  float: left;
}

.form_wrapper .col_four {
  width: 25%;
  float: left;
}

.form_wrapper label {
  display: block;
  margin: 0 0 5px;
}

.form_wrapper .input_field,
.form_wrapper .textarea_field {
  position: relative;
}

.form_wrapper .input_field > span,
.form_wrapper .textarea_field > span {
  position: absolute;
  left: 0;
  top: 0;
  color: #333;
  height: 100%;
  text-align: center;
  width: 20px;
}

.form_wrapper .textarea_field > span {
  max-height: 35px;
}

.input_field {
  font-size: 12px;
}

.form_wrapper .input_field > span > i,
.form_wrapper .textarea_field > span > i {
  padding-top: 5px;
}

.form_wrapper input[type="text"],
.form_wrapper input[type="date"],
.form_wrapper input[type="email"],
.form_wrapper input[type="tel"],
textarea {
  width: 100%;
  padding: 6px 6px 6px 35px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  outline: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form_wrapper textarea {
  height: 8em;
}

.form_wrapper input[type="text"]:focus,
.form_wrapper input[type="date"]:focus,
.form_wrapper input[type="email"]:focus,
.form_wrapper input[type="tel"]:focus,
textarea:focus {
  -webkit-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
  -moz-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
  box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
  border: 1px solid #f5ba1a;
}

.form_wrapper input[type="submit"] {
  height: 50px;
  line-height: 50px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-size: 1.2em;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form_wrapper input[type="submit"]:hover,
.form_wrapper input[type="submit"]:focus {
  background: #daa106;
}

.credit {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 15px;
  color: #f5ba1a;
}

.credit a {
  color: #daa106;
}

@media (max-width: 600px) {
  .form_wrapper .col_half,
  .form_wrapper .col_three,
  .form_wrapper .col_four {
    width: 100%;
    float: none;
  }

  .form_wrapper label {
    margin: 10px 0;
  }
}
</style>
