<template>
  <div class="card-body p-2">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

    <div class="form-group" v-if="containers.length > 1">
      <label for="exampleSelectRounded0">Selecione uma conexão</label>
      <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="container">
        <option v-for="container in containers" :key="container" :value="container">
          {{ container.nome }}
        </option>
      </select>

      <button class="button" type="button" @click="loadContatos()" style="width: 100%; border-radius: 5px; padding: 10px 10px">Buscar</button>
    </div>

    <br />
    <div class="row">
      <div class="col-md-4 col-sm-6 col-12">
        <div class="info-box">
          <span class="info-box-icon bg-success"><i style="color: white" class="fas fa-solid fa-users"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Total de Contatos</span>
            <span class="info-box-number">{{ contatos_formatados.length }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="form-check">
        <input v-model="remover_salvos" type="checkbox" class="form-check-input" @change="removerSalvos()" />
        <label class="form-check-label" for="preview">Remover contatos salvos</label>
      </div>
      <div class="form-check">
        <input v-model="filtrar_etiqueta" type="checkbox" class="form-check-input" />
        <label class="form-check-label" for="preview">Filtrar etiqueta</label>
      </div>
    </div>
    <div v-if="filtrar_etiqueta">
      <div class="form-group">
        <label>Etiqueta</label>
        <input class="form-control" v-model="label" />
      </div>
      <button class="btn btn-success" type="button" @click="loadContatos()" style="border-radius: 5px; padding: 10px 10px">Buscar</button>
      <br />
      <br />
      <br />
    </div>
    <button class="btn btn-success" type="button" @click="ExportExcel()" style="border-radius: 5px; padding: 10px 10px">Download</button>

    <!-- GRUPOS
    <div class="row" style="margin-top: 20px" v-if="contatos && contatos.length">
      <TransitionGroup name="cart" tag="ul" class="list-group-item col-md-12">
        <div class="grupo" v-for="(contato, index) in contatos" :key="index">
          <div class="form-check">
            <label class="form-check-label">{{ contato.phone }}</label>
          </div>
        </div>
      </TransitionGroup>
    </div>
     -->

    <table ref="exportable_table">
      <thead>
        <tr>
          <th scope="col">Número</th>
          <th scope="col">Nome</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="contato in contatos_formatados" :key="contato">
          <td style="color: black">
            {{ contato.phone }}
          </td>

          <td style="color: black">
            {{ contato.nome }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import sweetAlert from "../controller/sweetAlert";

export default {
  props: ["containers", "usuario"],

  mixins: [sweetAlert],

  components: { Loading },

  data() {
    return {
      grupos: [],
      aux_grupos: [],
      isLoading: false,
      grupos_string: "",
      selectedGroups: [],
      contatos: [],
      allSelected: false,
      membros: [],
      membros_string: "",
      nome_grupo: "",
      exibir_selecionados: false,
      container: "",
      porta: "",
      contatos_formatados: "",
      remover_salvos: false,
      filtrar_etiqueta: false,
      label: "",
    };
  },

  mounted() {
    //INICIALIZANDO CONTAINER
    this.container = this.containers[0];

    if (this.container.porta) {
      this.porta = this.container.porta;
    } else {
      this.porta = this.usuario.porta;
    }

    this.apiId = this.container.chave_api;

    console.log("Exibindo agora");
    console.log(this.container);
    console.log(this.usuario);

    //this.buscarGrupos();
    this.loadContatos();
  },

  methods: {
    ExportExcel(type, fn, dl) {
      var elt = this.$refs.exportable_table;
      var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(wb, fn || ("Contatos " + "." || "SheetJSTableExport.") + (type || "xlsx"));
    },

    filtrarGrupos() {
      this.allSelected = false;

      this.aux_grupos = [];

      for (let i = 0; i < this.grupos.length; i++) {
        if (this.grupos[i].contact.formattedName.toUpperCase().includes(this.nome_grupo.toUpperCase())) {
          this.aux_grupos.push(this.grupos[i]);
        }
      }
    },

    selectAll() {
      if (!this.allSelected) {
        for (let i = 0; i < this.aux_grupos.length; i++) {
          if (!this.selectedGroups.includes(this.aux_grupos[i])) {
            this.selectedGroups.push(this.aux_grupos[i]);
          }
        }

        this.allSelected = true;
      } else {
        for (let i = 0; i < this.aux_grupos.length; i++) {
          let indice = this.findGrupoInSelectedGroups(this.aux_grupos[i].id._serialized);

          if (indice >= 0) {
            this.selectedGroups.splice(indice, 1);
          }
        }

        this.allSelected = false;
      }
    },

    findGrupoInSelectedGroups(id) {
      let indice = this.selectedGroups.findIndex((element) => element["id"]["_serialized"] == id);

      return indice;
    },

    removerSalvos() {
      this.contatos_formatados = [];

      for (let i = 0; i < this.contatos.length; i++) {
        let name = "";

        if (this.contatos[i].obj.contact) {
          name = this.contatos[i].obj.contact.formattedName;
        } else if (this.contatos[i].obj.formattedName) {
          name = this.contatos[i].obj.formattedName;
        }

        if (this.remover_salvos) {
          if (name && name.includes("+55")) {
            this.contatos_formatados.push({
              phone: this.contatos[i].phone.split("@")[0],
              nome: name,
            });
          }
        } else {
          this.contatos_formatados.push({
            phone: this.contatos[i].phone.split("@")[0],
            nome: name,
          });
        }
      }
    },

    loadContatos() {
      this.contatos = [];
      this.contatos_formatados = [];

      let data = {
        apiId: this.container.chave_api,
      };

      console.log(process.env.MIX_VUE_APP_ENDPOINT + this.porta + `/getContacts`);

      let url = process.env.MIX_VUE_APP_ENDPOINT + this.porta + `/getContacts`;

      if (this.filtrar_etiqueta) {
        url = process.env.MIX_VUE_APP_ENDPOINT + this.porta + `/getContacts/label`;

        data.label = this.label;
      }

      axios
        .post(url, data)
        .then((response) => {
          console.log("Contatos");
          console.log(response.data);

          let allcontatos = response.data.contacts;

          this.contatos = allcontatos;

          this.contatos_formatados = [];

          console.log(this.contatos);

          for (let i = 0; i < this.contatos.length; i++) {
            let name = "";

            if (this.contatos[i].obj.contact) {
              name = this.contatos[i].obj.contact.formattedName;
            } else if (this.contatos[i].obj.formattedName) {
              name = this.contatos[i].obj.formattedName;
            }

            this.contatos_formatados.push({
              phone: this.contatos[i].phone.split("@")[0],
              nome: name,
            });
          }

          console.log("Formatados");
          console.log(this.contatos_formatados);

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    replaceAll(string, search, replace) {
      return string.split(search).join(replace);
    },

    extrairGrupos() {
      this.grupos_string = "";

      for (let i = 0; i < this.selectedGroups.length; i++) {
        this.grupos_string = this.grupos_string + this.selectedGroups[i].id._serialized;

        if (i + 1 < this.selectedGroups.length) {
          this.grupos_string = this.grupos_string + ",";
        }
      }
    },

    extrairMembros() {
      this.isLoading = true;
      this.membros_string = "";
      this.membros = [];

      for (let i = 0; i < this.selectedGroups.length; i++) {
        this.extrair(i);
      }
    },

    extrair(indice) {
      let grupo = this.selectedGroups[indice].id._serialized;

      console.log("GRUPO = " + grupo);

      let data = {
        chave_api: this.container.chave_api,
        group_id: grupo,
      };

      axios
        .post(process.env.MIX_VUE_APP_ENDPOINT + this.porta + "/list/group_members/", data)
        .then((response) => {
          console.log("Retorno dos MEMBROS");
          console.log(response.data);

          if (response.data.membros) {
            let dadosMembros = response.data.membros;

            for (let i = 0; i < dadosMembros.length; i++) {
              let phone = dadosMembros[i].id.user;

              this.membros.push(phone);
              this.membros_string = this.membros_string + phone + "\n";
            }

            if (indice + 1 >= this.selectedGroups.length) {
              this.isLoading = false;

              //REMOVENDO ÚLTIMA VÍRGULA
              this.membros_string = this.membros_string.slice(0, -1);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.grupo {
  width: 33%;
  float: left;
  text-align: left;
}

@media screen and (max-width: 1000px) {
  .grupo {
    width: 50%;
  }
}

@media screen and (max-width: 700px) {
  .grupo {
    width: 100%;
  }
}

.cart-enter-active,
.cart-leave-active {
  transition: all 0.5s ease;
}

.cart-enter-from,
.cart-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

label {
  font-weight: normal;
  color: gray;
}

.stati {
  background: #fff;
  width: 12em;
  height: 4em;
  padding: 1em;
  margin: 1em 0;
  /* Safari */
  box-shadow: 0px 0.2em 0.3em rgb(0, 0, 0, 0.8);
}

.stati.bg-orange {
  background: rgb(243, 156, 18);
  color: white;
}

.stati.bg-nephritis {
  background: rgb(39, 174, 96);
  color: white;
}

.stati.bg-peter_river {
  background: rgb(52, 152, 219);
  color: white;
}

.stati.bg-green_sea {
  background: rgb(22, 160, 133);
  color: white;
}

.stati div {
  width: calc(100% - 3.5em);
  display: block;
  float: right;
  text-align: right;
}

.stati fa {
  font-size: 3.5em;
}

.stati div {
  width: calc(100% - 3.5em);
  display: block;
  float: right;
  text-align: right;
}

.stati div b {
  font-size: 2.2em;
  width: 100%;
  padding-top: 0px;
  margin-top: -0.2em;
  margin-bottom: -0.2em;
  display: block;
}

.stati div span {
  font-size: 1em;
  width: 100%;
  color: rgb(0, 0, 0, 0.8);
  display: block;
}

.stati.left div {
  float: left;
  text-align: left;
}

.stati.bg-turquoise {
  background: rgb(26, 188, 156);
  color: white;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table tr:nth-child(even) {
  background: #fff;
}

table tr:nth-child(odd) {
  background: #eee;
}

table.pdi tr:nth-child(even) {
  background: #fff;
}

table.pdi tr:nth-child(odd) {
  background: #80f0af;
}

table caption {
  font-size: 1.2em;
  margin: 0.1em 0 0.5em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.1em;
}

table th,
table td {
  padding: 0.2em;
  text-align: center;
  font-size: 0.9em;
}

table th {
  font-size: 0.7em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    padding: 0.4em;
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.9em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

.cursor {
  cursor: pointer;
}

.button-voltar {
  background-color: #b8ab39;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.button-select-date {
  background-color: white;
  color: black;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  width: 100%;
  margin: 4px 2px;
  cursor: pointer;
}

.button-select-date-focused {
  background-color: white;
  color: black;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  width: 100%;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.9);
  -moz-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.9);
  box-shadow: 0 0 3px 1px rgba(255, 169, 0, 0.9);
}

.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17;
  border-radius: 5px;
  width: 100%;
  margin: 4px 2px;
  cursor: pointer;
}

.button-exit {
  background-color: #a67e21;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

body {
  font-family: Verdana, Geneva, sans-serif;
  font-size: 14px;
  background: #f2f2f2;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
}

.form_wrapper {
  width: 500px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 15px;
  margin: 0% auto 0;
  position: relative;
  z-index: 1;
}

.form_container {
  padding: 15px;
}

.form_wrapper h2 {
  font-size: 1.5em;
  line-height: 1.5em;
  margin: 0;
}

.form_wrapper .title_container {
  text-align: center;
  margin: -15px -15px 15px;
  padding: 15px 0;
}

.form_wrapper h3 {
  font-size: 1.1em;
  font-weight: normal;
  line-height: 1.5em;
  margin: 0;
}

.form_wrapper .row {
  margin: 10px -15px;
}

.form_wrapper .row > div {
  padding: 0 15px;
  box-sizing: border-box;
}

.form_wrapper .col_half {
  width: 50%;
  float: left;
}

.form_wrapper .col_full {
  width: 100%;
  float: left;
}

.form_wrapper .col_three {
  width: 33%;
  float: left;
}

.form_wrapper .col_four {
  width: 25%;
  float: left;
}

.form_wrapper label {
  display: block;
  margin: 0 0 5px;
}

.form_wrapper .input_field,
.form_wrapper .textarea_field {
  position: relative;
}

.form_wrapper .input_field > span,
.form_wrapper .textarea_field > span {
  position: absolute;
  left: 0;
  top: 0;
  color: #333;
  height: 100%;
  text-align: center;
  width: 20px;
}

.form_wrapper .textarea_field > span {
  max-height: 35px;
}

.input_field {
  font-size: 12px;
}

.form_wrapper .input_field > span > i,
.form_wrapper .textarea_field > span > i {
  padding-top: 5px;
}

.form_wrapper input[type="text"],
.form_wrapper input[type="date"],
.form_wrapper input[type="email"],
.form_wrapper input[type="tel"],
textarea {
  width: 100%;
  padding: 6px 6px 6px 35px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  outline: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form_wrapper textarea {
  height: 8em;
}

.form_wrapper input[type="text"]:focus,
.form_wrapper input[type="date"]:focus,
.form_wrapper input[type="email"]:focus,
.form_wrapper input[type="tel"]:focus,
textarea:focus {
  -webkit-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
  -moz-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
  box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
  border: 1px solid #f5ba1a;
}

.form_wrapper input[type="submit"] {
  height: 50px;
  line-height: 50px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-size: 1.2em;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form_wrapper input[type="submit"]:hover,
.form_wrapper input[type="submit"]:focus {
  background: #daa106;
}

.credit {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 15px;
  color: #f5ba1a;
}

.credit a {
  color: #daa106;
}

@media (max-width: 600px) {
  .form_wrapper .col_half,
  .form_wrapper .col_three,
  .form_wrapper .col_four {
    width: 100%;
    float: none;
  }

  .form_wrapper label {
    margin: 10px 0;
  }
}
</style>
