<template>
  <div>
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />
    <button data-toggle="modal" data-target="#login-modal" type="button" class="btn btn-info">
      Nova Blacklist <i class="nav-icon far fa-plus-square"></i>
    </button>

    <div class="modal fade" id="login-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true">
      <form @submit.prevent>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                &times;
              </button>
              <h4>Nova Blacklist</h4>
              <br />
              <div class="form-group">
                <label for="email">Nome</label>
                <input class="form-control" id="nome" type="text" v-model="nome" autocomplete="name" />
              </div>

              <div class="form-group">
                <label for="user_type">Tag</label>
                <select class="form-control" id="user_type" v-model="tag">
                  <option value=""></option>
                  <option value="Aniversariantes">Aniversariantes</option>
                  <option value="Atendidos">PDI</option>
                  <option value="Atendidos">PDI 15 dias</option>
                  <option value="Agendados">Agendados</option>
                  <option value="Gerados automaticamente">Gerados automaticamente</option>
                </select>
              </div>

              <button @click="validateFields()" class="btn btn-primary btn-flat">
                Criar lista <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [wppController, sweetAlert, Swal],

  props: ['times'],

  components: { Loading },

  data() {
    return {
      nome: "",
      tag: "",
      isLoading: false,
    };
  },

  mounted() {
    console.log("GERENTES");
    console.log(this.gerentes);
  },

  methods: {

    validateFields() {
      if (!this.nome) {
        this.showErrorMessageWithButton("Ops...", "O campo nome é obrigatório!");
      } else {
        this.create();
      }
    },

    async create() {

      this.isLoading = true;

      let data = {
        nome: this.nome,
        tag: this.tag,
      };

      console.log("CRIANDO");
      console.log(data);

      axios
        .post(`/blacklist/store`, data)
        .then((response) => {
          this.showSuccessMessage("Blacklist criada!");

          window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    replaceAll(string, search, replace) {
      return string.split(search).join(replace);
    },

  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
