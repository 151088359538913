<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

  <div
    @keydown.esc="cancelModal"
    @click.self="cancelModal"
    class="modal fade"
    id="edit-lead-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <form @submit.prevent>
      <div class="modal-dialog" style="text-align: left">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            <h4>Editar lead {{ lead.id }}</h4>
            <div class="form-group">
              <label for="email">Nome</label>
              <input id="nome" type="text" class="form-control" v-model="formData.nome" autocomplete="name" />

              <span style="color: red" v-for="error in v$.nome.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">Número</label>
              <input id="porta" type="porta" class="form-control" v-model="formData.number" autocomplete="porta" />

              <span style="color: red" v-for="error in v$.number.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">CNPJ</label>
              <input id="porta" type="porta" class="form-control" v-model="formData.cnpj" />

              <span style="color: red" v-for="error in v$.cnpj.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">Vidas</label>
              <input id="porta" type="porta" class="form-control" v-model="formData.qtd_vidas" />

              <span style="color: red" v-for="error in v$.qtd_vidas.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">Confirmação</label>
              <input id="porta" type="porta" class="form-control" v-model="formData.confirmacao" />

              <span style="color: red" v-for="error in v$.confirmacao.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">Origem</label>

              <select class="custom-select rounded-0" v-model="formData.origem">
                <option value="importacao">Importação</option>
                <option value="formulario">Formulário</option>
              </select>

              <span style="color: red" v-for="error in v$.origem.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">Enviado para</label>
              <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="formData.parceiro_id">
                <option v-for="parceiro in parceiros" :key="parceiro.id" :value="parceiro.id">{{ parceiro.nome }}</option>
              </select>
            </div>

            <button @click="update()" class="btn btn-success btn-sm">Salvar</button>

            <button
              id="close_edit_container"
              style="margin-left: 5px"
              class="btn btn-danger btn-sm"
              data-toggle="modal"
              data-target="#edit-lead-modal"
              @click="setEditarContainer(false)"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import wppController from "../../controller/wppController";
import sweetAlert from "../../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import useVueLidate from "@vuelidate/core";
import { required } from "../../locales/i18n.js";
import { computed, reactive } from "vue";

export default {
  mixins: [wppController, sweetAlert, Swal],

  components: { Loading },

  props: ["lead", "setEditarContainer", "containerEditado", "parceiros"],

  data() {
    return {
      nome: "",
      email: "",
      senha: "",
      isLoading: false,
      my_container: {},
    };
  },

  setup() {
    const formData = reactive({
      nome: "",
      number: "",
      origem: "",
      enviado: "",
      enviado_para: "",
      cnpj: "",
      qtd_vidas: "",
      confirmacao: "",
      parceiro_id: "",
    });

    const rules = computed(() => {
      let local_rules = {
        nome: { required },
        number: { required },
        origem: { required },
        qtd_vidas: { required },
        cnpj: { required },
        confirmacao: { required },
      };

      /*
      if (formData.enviado == 1) {
        local_rules.enviado_para = {
          required,
        };
      }
        */

      return local_rules;
    });

    const v$ = useVueLidate(rules, formData);

    return { formData, v$ };
  },

  mounted() {
    console.log(this.container);

    this.formData.nome = this.lead.nome;
    this.formData.number = this.lead.number;
    this.formData.origem = this.lead.origem;
    this.formData.enviado = this.lead.enviado;
    this.formData.enviado_para = this.lead.enviado_para;
    this.formData.cnpj = this.lead.cnpj;
    this.formData.qtd_vidas = this.lead.qtd_vidas;
    this.formData.confirmacao = this.lead.confirmacao;
    this.formData.parceiro_id = this.lead.parceiro_id;
  },

  methods: {
    cancelModal() {
      this.setEditarContainer(false);
    },

    async update() {
      const result = await this.v$.$validate();

      if (result) {
        this.isLoading = true;

        let data = {
          lead_id: this.lead.id,
          nome: this.formData.nome,
          number: this.formData.number,
          origem: this.formData.origem,
          porta: this.formData.porta,
          enviado: this.formData.enviado,
          enviado_para: this.formData.enviado_para,
          cnpj: this.formData.cnpj,
          qtd_vidas: this.formData.qtd_vidas,
          confirmacao: this.formData.confirmacao,
          parceiro_id: this.formData.parceiro_id,
        };

        axios
          .post(`/admin/lead/update`, data)
          .then((response) => {
            this.isLoading = false;

            this.showSuccessMessage("Lead atualizado!");
            this.containerEditado();
          })
          .catch((error) => {
            this.isLoading = false;
            this.showErrorMessageWithButton("Ops..", error.response.data);
            console.log(error.response.data);
          });
      } else{
        console.log(this.v$);
      }
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
