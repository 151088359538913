<template>
  <div>
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />
    <button data-toggle="modal" data-target="#login-modal" type="button" class="btn btn-success">
      Novo usuário
      <i style="margin-left: 5px" class="fa fa-plus"></i>
    </button>

    <div class="modal fade" id="login-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <form @submit.prevent>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              <h4>Novo usuário</h4>
              <div class="form-group">
                <label for="email">Nome</label>
                <input id="nome" type="text" class="form-control" v-model="nome" autocomplete="name" />
              </div>

              <div class="form-group">
                <label for="email">Email</label>
                <input id="email" type="email" class="form-control" v-model="email" autocomplete="email" />
              </div>

              <div class="form-group">
                <label for="password">Senha</label>
                <input id="senha" type="senha" class="form-control" v-model="senha" autocomplete="senha" />
              </div>

              <div class="form-group">
                <label for="password">Porta</label>
                <input id="porta" type="porta" class="form-control" v-model="porta" autocomplete="porta" />
              </div>

              <div class="form-group">
                <label for="password">Servidor</label>
                <input id="url" type="url" class="form-control" v-model="url" autocomplete="url" />
              </div>

              <div class="form-group">
                <label for="user_type">Api Name (Integrações)</label>
                <input class="form-control" id="cnpj" type="text" v-model="api_name" />
              </div>

              <div class="form-group">
                <label for="user_type">Tipo</label>
                <select class="custom-select rounded-0" id="exampleSelectRounded0" v-model="tipo">
                  <option value="Cliente Gmed">Cliente Gmed</option>
                  <option value="Cliente Feegow">Cliente Feegow</option>
                  <option value="Cliente Disparador">Cliente Disparador</option>
                  <option value="Cliente Grupos">Cliente Grupos</option>
                  <option value="Admin Indicazap">Admin Indicazap</option>
                </select>
              </div>

              <button @click="validateFields()" class="btn btn-success btn-sm">
                Cadastrar
                <i style="margin-left: 5px" class="fa fa-check"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [wppController, sweetAlert, Swal],

  components: { Loading },

  data() {
    return {
      container: {},
      nome: "",
      email: "",
      senha: "",
      porta: "",
      url: "",
      isLoading: false,
      api_name: "",
      tipo: "Cliente Disparador",
    };
  },

  mounted() {},

  methods: {
    validateFields() {
      if (!this.nome) {
        this.showErrorMessageWithButton("Ops...", "O campo nome é obrigatório!");
      } else if (!this.validateEmail()) {
        this.showErrorMessageWithButton("Ops...", "Digite um email válido!");
      } else if (this.senha == "") {
        this.showErrorMessageWithButton("Ops...", "A senha não pode ficar em branco!");
        // } else if (this.porta == "") {
        //   this.showErrorMessageWithButton("Ops...", "A porta não pode ficar em branco!");
      } else if (this.url == "") {
        this.showErrorMessageWithButton("Ops...", "A url não pode ficar em branco!");
      } else {
        this.create();
      }
    },

    create() {
      this.isLoading = true;

      let data = {
        nome: this.nome,
        email: this.email,
        senha: this.senha,
        porta: this.porta,
        url: this.url,
        api_name: this.api_name,
        tipo: this.tipo,
        chave: this.generateKey(),
      };

      console.log("CRIANDO");
      console.log(data);

      axios
        .post(`/admin/users/create`, data)
        .then((response) => {
          console.log("Usuário criado!");
          console.log(response);

          this.showSuccessMessage("Usuário criado!");

          window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    validateEmail() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        return true;
      } else {
        return false;
      }
    },

    generateKey() {
      return Math.random().toString(36).slice(2);
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
