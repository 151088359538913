<template>
  <div @click="firstClick" @click.self="firstClick">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />
    <br />

    <admin-create-formulario :setCriarformulario="setCriarformulario" :formularioCriado="formularioCriado" />

    <button data-toggle="modal" data-target="#create-formulario-modal" @click="criar_lead = true" type="button" class="btn btn-success">
      Cadastrar formulário
      <i style="margin-left: 5px" class="fa fa-plus"></i>
    </button>
    <!-- TABELA USUÁRIOS-->
    <div class="card card-primary">
      <div class="card-header">
        <h3 class="card-title">
          Formulários
          <span class="badge badge-warning" style="margin-left: 5px">{{ formularios.length }}</span>
        </h3>
      </div>
      <div class="card-body p-0">
        <table class="table">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Hash</th>
              <th style="width: 100px">Editar</th>
              <th style="width: 100px">Deletar</th>
              <th style="width: 100px">Copiar</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="formulario in formularios" :key="formulario">
              <td data-label="Nome">{{ formulario.nome }}</td>
              <td data-label="Hash">{{ formulario.hash }}</td>

              <td>
                <button
                  data-toggle="modal"
                  data-target="#edit-formulario-modal"
                  @click="selecionarformulario(formulario), (editar_formulario = true)"
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  Editar
                </button>
              </td>

              <td>
                <button
                  data-toggle="modal"
                  data-target="#acoes-modal"
                  @click="confirmarDelete(formulario)"
                  type="button"
                  class="btn btn-danger btn-sm"
                >
                  Deletar
                </button>
              </td>
              <td>
                <button @click="copiarFormulario(formulario)" type="button" class="btn btn-success btn-sm">Copiar</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card-footer clearfix">
        <!-- TOTAIS  -->
        <div style="font-size: 13px" class="float-left" aria-live="polite">
          Exibindo do {{ inicio + 1 }} ao {{ inicio + formularios.length }}
          <p>Total {{ total }}</p>
        </div>

        <p class="card-title" style="font-size: 12px; color: black; margin-right: 5px; margin-left: 10px">Por página</p>

        <select @change="alterarQtdPagina()" class="float-left card-titlecustom-select rounded-0" style="font-size: 12px" v-model="qtd_por_pagina">
          <option :value="1">1</option>
          <option :value="2">2</option>
          <option :value="7">7</option>
          <option :value="15">15</option>
          <option :value="25">25</option>
        </select>

        <div class="card-tools">
          <div class="input-group input-group-sm" style="width: 150px"></div>
        </div>

        <!-- PAGINAÇÃO  -->
        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item"><a class="page-link" @click="selecionarPagina(pagina_atual - 1)">&laquo;</a></li>

          <li v-for="pagina in paginas" :key="pagina" :value="pagina" class="page-item">
            <a :class="pagina === pagina_atual ? 'page-link current-page' : 'stopped'" class="page-link" @click="selecionarPagina(pagina)">
              {{ pagina }}
            </a>
          </li>

          <li class="page-item"><a class="page-link" @click="selecionarPagina(pagina_atual + 1)">&raquo;</a></li>
        </ul>
      </div>
    </div>

    <admin-edit-formulario
      v-if="editar_formulario"
      :formulario="formulario_selecionado"
      :setEditarformulario="setEditarformulario"
      :formularioEditado="formularioEditado"
    />
  </div>
</template>

<script>
import moment from "moment";
import wppController from "../../controller/wppController";
import sendController from "../../controller/sendController";
import sweetAlert from "../../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [wppController, sendController, sweetAlert, Swal],

  components: { Loading },

  props: ["users", "parceiros"],

  data() {
    return {
      formulario: {},
      nome: "",
      email: "",
      senha: "",
      isLoading: false,
      formulario_selecionado: "",
      formularios: [],
      acoes_usuario: [],
      acao_selecionada: "",
      filtro: "",
      paginas: [],
      inicio: 0,
      total: 0,
      qtd_por_pagina: 25,
      pagina_atual: 1,
      editar_formulario: false,
      criar_lead: false,
      user_selecionado: "",
      origem_selecionada: "",
      telefone_teste: "",
      mensagem_teste_envio: "",
      enviado_para: "",
      notificacao_sonora: false,
      is_first_click: true,
      ultima_linha_excel: "",
      somente_pendentes: false,
    };
  },

  mounted() {
    this.searchFormularios();
  },

  methods: {
    marcarEnviado(lead) {
      let data = {
        parceiro_id: lead.parceiro_id,
        lead_id: lead.id,
      };

      axios
        .post(`/admin/lead/update_enviado`, data)
        .then((response) => {
          this.showSuccessMessage("Lead atualizado!");

          let indice = this.formularios.findIndex((element) => element["id"] == lead.id);
          this.formularios[indice].enviado = 1;
          this.formularios[indice].enviado_em = new Date();
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    atualizarformularios() {
      this.isLoading = true;

      axios
        .post(process.env.MIX_VUE_APP_SEACH_formularios_EXCEL + `/update`)
        .then((response) => {
          setTimeout(() => {
            this.searchFormularios();
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    copiarFormulario(formulario) {
      let that = this;
      let endpoint = this.replaceAll(process.env.MIX_VUE_APP_ENDPOINT, ":", "");
      let text = endpoint + "/formulario/" + formulario.hash;

      this.$copyText(text).then(
        function (e) {
          console.log(e);
          that.showSuccessMessage("Dados copiados!");
        },
        function (e) {
          alert("Can not copy");
          console.log(e);
        }
      );
    },
    
    replaceAll(string, search, replace) {
      return string.split(search).join(replace);
    },

    updateExcelRows() {
      let html = "Tem certeza que deseja <b style='font-size:20px'>alterar </b> essa informação?";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!",
        })
        .then((result) => {
          if (result.value) {
            let data = {
              last_row_excel_formularios: this.ultima_linha_excel,
            };

            axios
              .post(`/admin/automation/updateExcelRows`, data)
              .then((response) => {
                this.showSuccessMessage("Linha atualizada");
              })
              .catch((error) => {
                console.log(error);
                this.showErrorMessageWithButton("Ops..", error.response.data);
                console.log(error.response.data);
              });
          }
        });
    },

    firstClick() {
      if (this.is_first_click) {
        this.notificacao_sonora = true;
        this.is_first_click = false;
      }
    },

    limparFiltros() {
      this.enviado_para = "";
      this.filtro = "";
      this.origem_selecionada = "";

      this.searchFormularios();
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },

    getFormatedText(title) {
      if (title && title.length > 18) {
        return title.substring(0, 18) + "...";
      } else {
        return title;
      }
    },

    alterarQtdPagina() {
      this.pagina_atual = 1;
      this.inicio = 0;

      this.searchFormularios();
    },

    formularioCriado() {
      this.setCriarformulario(false);

      this.searchFormularios();
    },

    setCriarformulario(value) {
      let btn = document.getElementById("close_create_formulario");
      if (btn) {
        btn.click();
      }

      this.criar_lead = value;
    },

    formularioEditado() {
      this.setEditarformulario(false);

      this.searchFormularios();
    },

    setEditarformulario(value) {
      let btn = document.getElementById("close_edit_formulario");
      if (btn) {
        btn.click();
      }

      this.editar_formulario = value;
    },

    searchFormularios() {
      let data = {
        inicio: this.inicio,
        tamanho: this.qtd_por_pagina,
      };

      console.log("Buscando");
      console.log(data);

      axios
        .post(`/admin/formularios/search`, data)
        .then((response) => {
          this.formularios = response.data.formularios;
          this.total = response.data.total;

          this.isLoading = false;

          console.log("formularios");
          console.log(this.formularios);
          console.log(response.data);

          if (response.data.automation_controll) {
            this.ultima_linha_excel = response.data.automation_controll.last_row_excel_formularios;
          } else {
            this.ultima_linha_excel = "";
          }

          this.pagination(response.data);
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    selecionarPagina(pagina) {
      if (pagina <= this.paginas.length && pagina > 0) {
        this.inicio = this.qtd_por_pagina * (pagina - 1);
        this.pagina_atual = pagina;
        this.searchFormularios();
      }
    },

    pagination(data) {
      this.paginas = [];

      let length = data.total / this.qtd_por_pagina;
      let i = 0;

      //DEFININDO MÁXIMO E MÍNIMO DE PÁGINAS
      if (length < 0) {
        length = 0;
      } else if (length > 10) {
        if (this.pagina_atual >= 10) {
          let minimo = this.pagina_atual - 10;

          if (length - this.pagina_atual > 10) {
            length = this.pagina_atual + 10;
            i = minimo;
          } else {
            i = this.pagina_atual - 10 + (length - this.pagina_atual);
          }
        } else {
          length = 10;
        }
      }

      for (i; i < length; i++) {
        this.paginas.push(i + 1);
      }
    },

    selecionarformulario(usuario) {
      this.formulario_selecionado = usuario;
    },

    addAcao() {
      this.acoes_usuario.push(this.acao_selecionada.acao);
    },

    confirmarDelete(formulario) {
      let html = "Deseja <b style='color: red'> deletar</b> o lead " + "<b style='font-size:25px'>" + formulario.nome + "?";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!",
        })
        .then((result) => {
          if (result.value) {
            this.deletar(formulario);
          }
        });
    },

    deletar(formulario) {
      let data = {
        id: formulario.id,
      };

      axios
        .post(`/admin/lead/delete`, data)
        .then((response) => {
          this.showSuccessMessage("Conexão deletada");

          this.searchFormularios();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    update() {
      let data = {
        id: this.formulario_selecionado.id,
        acoes_personalizadas: JSON.stringify(this.acoes_usuario),
      };

      axios
        .post(`/admin/formularios/update/acoes`, data)
        .then((response) => {
          this.showSuccessMessage("Ações atualizadas!");

          window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
