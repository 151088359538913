<template>
    <div>
        <button data-toggle="modal" data-target="#login-modal" type="button" class="btn btn-primary">
            Cadastrar campanha <i class="nav-icon far fa-plus-square"></i>
        </button>

        <div class="modal fade" id="login-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
            aria-hidden="true">
            <form @submit.prevent>
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body">
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                                &times;
                            </button>
                            <h4>Nova Campanha</h4>
                            <br />

                            <div class="form-group">
                                <label for="email">Nome</label>
                                <input class="form-control" id="nome" type="text" v-model="nome" />
                            </div>

                            <div class="form-group">
                                <label for="email">Url imagem </label>
                                <input class="form-control" id="nome" type="text" v-model="url_imagem" />
                                <p style="color: rgb(101, 104, 104); font-size: 11px; float: right;">Tamanho recomendado: 300px x 200px</p>
                                <br/>
                            </div>

                            <button @click="validateFields()" class="btn btn-success btn-sm">
                                Cadastrar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
  
<script>
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import "vue-loading-overlay/dist/vue-loading.css";
import Loading from "vue-loading-overlay";

export default {

    mixins: [sweetAlert, Swal],

    props: [],

    components: [Loading],

    data() {
        return {
            nome: "",
            telefone_titular: "",
            isLoading: false,
            url_imagem: "https://res.cloudinary.com/djyh2nsyi/image/upload/v1701264721/jb8xsfzmf3yqz7zuzy3v.jpg"
        };
    },

    mounted() { },

    methods: {

        validateFields() {
            if (!this.nome) {
                this.showErrorMessageWithButton("Ops...", "O campo nome é obrigatório!");
            } else {
                this.create();
            }
        },

        create() {
            this.isLoading = true;

            let data = {
                nome: this.nome,
                url_imagem: this.url_imagem,
            };

            console.log("CRIANDO");
            console.log(data);

            axios
                .post(`/admin-indicazap/campanha/store`, data)
                .then((response) => {

                    this.showSuccessMessage("Campanha cadastrada!");

                    window.location.reload();
                })
                .catch((error) => {
                    this.showErrorMessageWithButton("Ops..", error);
                    console.log(error);
                });

        },
    },
};
</script>

