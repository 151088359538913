<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

  <div
    @keydown.esc="cancelModal"
    @click.self="cancelModal"
    class="modal fade"
    id="edit-container-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <form @submit.prevent>
      <div class="modal-dialog" style="text-align: left">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            <h4>Editar conexão {{ container.id }}</h4>
            <div class="form-group">
              <label for="email">Nome</label>
              <input id="nome" type="text" class="form-control" v-model="formData.nome" autocomplete="name" />

              <span style="color: red" v-for="error in v$.nome.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">Servidor</label>
              <input id="porta" type="porta" class="form-control" v-model="formData.servidor" autocomplete="porta" />

              <span style="color: red" v-for="error in v$.servidor.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <div class="form-group">
              <label for="password">Porta</label>
              <input id="porta" type="porta" class="form-control" v-model="formData.porta" autocomplete="porta" />
            </div>

            <button @click="update()" class="btn btn-success btn-sm">Salvar</button>

            <button
              id="close_edit_container"
              style="margin-left: 5px"
              class="btn btn-danger btn-sm"
              data-toggle="modal"
              data-target="#edit-container-modal"
              @click="setEditarContainer(false)"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import wppController from "../../controller/wppController";
import sweetAlert from "../../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import useVueLidate from "@vuelidate/core";
import { required } from "../../locales/i18n.js";
import { computed, reactive } from "vue";

export default {
  mixins: [wppController, sweetAlert, Swal],

  components: { Loading },

  props: ["container", "setEditarContainer", "containerEditado"],

  data() {
    return {
      nome: "",
      email: "",
      senha: "",
      isLoading: false,
      my_container: {},
    };
  },

  setup() {
    const formData = reactive({
      nome: "",
      porta: "",
      servidor: "",
    });

    const rules = computed(() => {
      return {
        nome: { required },
        servidor: { required },
      };
    });

    const v$ = useVueLidate(rules, formData);

    return { formData, v$ };
  },

  mounted() {
    if (!this.container.porta) {
      this.formData.porta = this.container.porta_usuario;
    } else {
      this.formData.porta = this.container.porta;
    }

    console.log(this.container);

    this.formData.nome = this.container.nome;
    this.formData.servidor = this.container.nome_pm2;
  },

  methods: {
    cancelModal() {
      this.setEditarContainer(false);
    },

    async update() {
      const result = await this.v$.$validate();

      if (result) {
        this.isLoading = true;

        let data = {
          id: this.container.id,
          nome: this.formData.nome,
          nome_pm2: this.formData.servidor,
          porta: this.formData.porta,
        };

        axios
          .post(`/user/connection/update`, data)
          .then((response) => {
            this.isLoading = false;

            this.showSuccessMessage("Conexão atualizada!");
            this.containerEditado();
          })
          .catch((error) => {
            this.isLoading = false;
            this.showErrorMessageWithButton("Ops..", error.response.data);
            console.log(error.response.data);
          });
      }
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
