<template>
  <div>
    <meta charset="utf8mb4" />

    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

    <div v-if="showEmptyGallery" style="text-align: center; padding-top: 10%">
      <h1>Ops... :(</h1>
      <h4>Sua galeria está vazia</h4>
    </div>

    <div v-if="!loading && !showEmptyGallery">
      <div v-for="media in midias" :key="media.asset_id">
        <div class="img" v-bind:class="{ check: url == media.url, normal: url == '' }">
          <img v-if="media.resource_type == 'image'" :src="media.url" :alt="media.asset_id" width="250" height="150" />

          <video controls v-if="media.format == 'mp4' || media.format == 'mp3' || media.format == 'ogg'" width="250"
            height="150">
            <source :src="media.url" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button style="
              width: 100%;
              margin-top: 5%;
              font-size: 15px;
              padding: 2px;
              border-radius: 5px;
              border-color: #d9d9d9;
              border-style: solid;
            " type="button" v-if="url != media.url" @click="copy(media.url)">
            <i class="fas fa-solid fa-clipboard"></i>
          </button>
          <button style="
              width: 100%;
              margin-top: 5%;
              font-size: 15px;
              padding: 2px;
              border-radius: 5px;
              border-color: green;
              border-style: solid;
            " type="button" @click="copy(media.url)" v-if="url == media.url">
            <i style="color: green" class="fas fa-solid fa-clipboard-check"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "ShowMessages",

  components: { Loading },

  props: ["my_container"],

  data() {
    return {
      midias: [],
      url: "",
      loading: false,
      uploadLink: "",
      apiId: 0,
      container: "",
      showEmptyGallery: false,
      isLoading: true,
    };
  },

  mounted() {
    axios
      .get(
        process.env.MIX_VUE_APP_ENDPOINT_CLOUD +
        `/cloud/resources/get/` +
        this.my_container.id
      )
      .then((response) => {
        this.midias = response.data.result.resources;
        console.log("Mídias!");
        console.log(this.midias);
        this.isLoading = false;

        if (!this.midias || this.midias.length == 0) {
          this.showEmptyGallery = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  methods: {
    copy(url) {
      this.url = url;

      this.$copyText(url).then(
        function (e) {
          console.log(e);
        },
        function (e) {
          alert("Can not copy");
          console.log(e);
        }
      );
    },

    onCopy(text) {
      alert("Texto copiado " + text);
    },

    onCopy(text) {
      alert("Texto copiado " + text);
    },
  },
};
</script>

<style>
.button {
  background-color: #2c3e50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.button:hover {
  border-radius: 0px;
}

div.img img {
  width: auto;
}

div.img video {
  max-width: 100%;
}

div.img {
  margin: 30px 5px 0px 0px;
  padding: 5px;
  box-sizing: border-box;
  background: #f1f1f1;
  line-height: 0px;
  height: auto;
  width: 24%;
  float: left;
  text-align: center;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-radius: 4px;
}

div.img:hover {
  transform: scale(1.1);
}

div.img img {
  display: inline-block;
  margin: 3px;
}

div.img.normal {
  opacity: 1;
}

div.img {
  opacity: 0.3;
}

div.img.check {
  opacity: 1;
}

div.img video {
  display: inline-block;
  margin: 3px;
}

@keyframes bgfade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes bgfade

/* Safari and Chrome */
  {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 800px) {
  div.img {
    margin: 30px 5px 0px 0px;
    width: 47%;
  }

  div.img img {
    max-width: 150px;
  }

  html {
    height: 120%;
    width: 100%;
  }

  div.img:hover {
    transform: scale(1);
  }
}

body {
  background-image: -webkit-gradient(linear,
      80 bottom,
      left 60,
      /*color-stop(0, rgb(91, 135, 115)),
    color-stop(0.57, rgb(66, 110, 110))*/
      color-stop(0, rgb(223, 223, 223)),
      color-stop(0.57, rgb(255, 255, 255)));
}
</style>
