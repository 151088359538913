<template>
  <br />

  <div class="row">
    <div class=" col-md-12">
      <img v-if="campanha" :src="campanha.url_imagem" style="max-width: 300px;max-height: 200px;" />
    </div>

    <div class=" col-md-12">
      <br />
      <br />
      <h5 style="text-align: center;  font-weight: bold">Olá, {{ usuario.name }}! </h5>
      <h5 style="text-align: center;">Clique no botão abaixo e escaneie o QR code <br />para gerar uma lista de indicações
      </h5>
    </div>

    <div class=" col-md-12">
      <br />
      <h6 style="text-align: center; font-weight: bold; font-size: 18px;" v-if="campanha">{{ campanha.nome }}</h6>
    </div>

    <div class=" col-md-12">
      <div class="form-group">
        <button @click="connect()" id="qrCode" type="button" class="btn btn-success btn-sm my-btn">
          Qr code
        </button>
       
        <a :href="getUrl()" type="button" class="btn btn-primary btn-sm my-btn"
          style="min-width:512 !important; min-height: 512 !important; color: white">
          Cadastrados
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";

export default {
  props: ["my_container", "chave_api", "usuario", "campanha"],

  mixins: [wppController, sweetAlert],

  data() {
    return {
      container: {},
      isConnected: false,
    };
  },

  mounted() {
    this.container = this.my_container;

    console.log("Load component");
    console.log(this.container);
    console.log(this.campanha);
  },

  methods: {
    connect() {
      console.log("Carregando QR - Sessão = " + this.chave_api);
      console.log("Indicazap");

      console.log(process.env.MIX_VUE_APP_ENDPOINT + this.usuario.porta + `/load/` + this.container.id + "/" + this.chave_api + "/" + this.usuario.id + "/" + this.campanha.id);

      this.axios
        .get(process.env.MIX_VUE_APP_ENDPOINT + this.usuario.porta + `/load/` + this.container.id + "/" + this.chave_api + "/" + this.usuario.id + "/" + this.campanha.id)
        .then((response) => {
          console.log("Resposta do carregamento ");
          console.log(this.usuario.porta);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getUrl() {
      return "/indica/relatorio/" + this.usuario.telefone + "/" + this.campanha.id + "/" + this.usuario.id
    },

    generateSessionName() {
      this.sessionName = Math.random().toString(36).slice(2);
      console.log("Session - " + this.sessionName);
    },
  },
};
</script>

<style>
.my-btn {
  font-weight: bold;
  color: black;
  font-size: 18px;
  height: 40px;
  width: 300px;
  color: white;
}

.my-image {
  font-weight: bold;
  color: black;
  font-size: 18px;
  height: 40px;
  width: 300px;
  color: white;
}
</style>
