<template>
  <div class="container-fluid">
    <div class="row">
      <!-- CONTATOS -->
      <div class="col-md-12">
        <div class="card card-info">
          <div class="card-header">
            <h3 class="card-title">Detalhes do envio </h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <!-- FORM -->
                <div class="form-group">
                  <label for="exampleInputEmail1">Nome do envio</label>
                  <input
                    class="form-control"
                    id="exampleInputEmail1"
                    placeholder="Digite o nome do seu envio"
                    v-model="nome_envio"
                  />
                </div>

                <div class="form-group">
                  <label>Telefones</label>
                  <textarea
                    class="form-control"
                    rows="3"
                    placeholder="Digite os telefones ..."
                    v-model="listToSend"
                  ></textarea>

                  <div class="row">
                    <div class="col-1"></div>
                    <div class="col-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="CSV"
                          checked
                          v-model="list_break"
                        />
                        <label class="form-check-label">CSV</label>
                      </div>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="radio1"
                          value="Excel"
                          v-model="list_break"
                        />
                        <label class="form-check-label">Excel</label>
                      </div>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="radio1"
                          value="JSON"
                          v-model="list_break"
                        />
                        <label class="form-check-label">JSON</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="add_to_funil" class="form-group">
                  <label for="exampleSelectRounded0">Funil</label>
                  <select class="custom-select rounded-0" id="exampleSelectRounded0">
                    <option>Funil 1</option>
                    <option>Funil 2</option>
                    <option>Funil 3</option>
                  </select>
                </div>

                <!-- FORM -->
              </div>
            </div>
          </div>
          <!-- /.card-body -->

          <div class="card-footer">
            <button @click="validateFields()" class="btn btn-success">Enviar</button>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <button @click="addMessage()" class="btn btn-success">
          <i class="fa fa-plus"></i>
          Add Mensagem
          <span class="badge bg-danger">{{ mensagens.length }}</span>
        </button>
      </div>
      <br />
      <br />
      <!-- MENSAGENS -->
      <div class="col-md-3" v-for="mensagem in mensagens" :key="mensagem.id">
        <div class="card card-primary">
          <div class="card-header">
            <h3 class="card-title">Resposta Esperada {{ mensagem.id }}</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <!-- <div class="form-group">
                  <label for="exampleSelectRounded0">Tipo de mensagem</label>
                  <select
                    class="custom-select rounded-0"
                    id="exampleSelectRounded0"
                    v-model="mensagem.tipo_envio"
                  >
                    <option value="texto">Texto</option>
                    <option value="imagem">Imagem</option>
                    <option value="video">Video</option>
                    <option value="link">Link</option>
                    <option value="voz">Voz</option>
                  </select>
                </div>  -->

                <div class="form-group">
                  <label for="exampleInputEmail1">Tempo para envio (em segundos)</label>
                  <input
                    class="form-control"
                    id="exampleInputEmail1"
                    placeholder="Ex: 10"
                    v-model="mensagem.tempo"
                  />
                </div>

                <!-- <div v-if="mensagem.tipo_envio != 'voz'">
                  <div class="form-group">
                    <label>Mensagem teste</label>
                    <textarea
                      v-bind:class="{
                        'form-control': !mensagem.message_error,
                        'form-control is-invalid': mensagem.message_error,
                      }"
                      @blur="validateInput(mensagem)"
                      rows="3"
                      placeholder="Digite a mensagem que deseja enviar ..."
                      v-model="mensagem.mensagem"
                    ></textarea>
                  </div>
                </div> -->

                 <div v-if="mensagem.tipo_envio != 'voz'">
                  <div class="form-group">
                    <label>Mensagem esperada</label>
                    <textarea
                      v-bind:class="{
                        'form-control': !mensagem.message_error,
                        'form-control is-invalid': mensagem.message_error,
                      }"
                      @blur="validateInput(mensagem)"
                      rows="3"
                      placeholder="Digite a mensagem que deseja enviar ..."
                      v-model="mensagem.mensagem"
                    ></textarea>
                  </div>
                </div>

                <div v-if="mensagem.tipo_envio == 'imagem'">
                  <div class="form-group">
                    <label for="imageToSend">Url da imagem</label>
                    <textarea
                      v-bind:class="{
                        'form-control': !mensagem.url_error,
                        'form-control is-invalid': mensagem.url_error,
                      }"
                      @blur="validateInput(mensagem)"
                      placeholder="Url da imagem a ser enviada"
                      v-model="mensagem.url"
                      id="imageToSend"
                      rows="3"
                    />
                    <a href="/admin/gallery" style="font-size: 15px">
                      Não possui url? Busque na sua galeria.</a
                    >
                  </div>
                  <div class="form-group">
                    <div v-if="mensagem.url" class="form-check">
                      <input
                        v-model="mensagem.preview"
                        type="checkbox"
                        class="form-check-input"
                        id="preview"
                      />
                      <label class="form-check-label" for="preview"
                        >Previsualizar imagem?
                      </label>
                    </div>
                  </div>
                  <div v-if="mensagem.preview" class="form-check">
                    <img
                      id="qrCode"
                      :src="mensagem.url"
                      style="
                        min-width: 200px;
                        min-height: 180px;
                        max-width: 200px;
                        max-height: 180px;
                      "
                    />
                    <br />
                    <br />
                  </div>
                </div>

                <div v-if="mensagem.tipo_envio == 'video'">
                  <div class="form-group">
                    <label for="imageToSend">Url do vídeo</label>

                    <textarea
                      v-bind:class="{
                        'form-control': !mensagem.url_error,
                        'form-control is-invalid': mensagem.url_error,
                      }"
                      @blur="validateInput(mensagem)"
                      placeholder="URL do vídeo a ser enviado"
                      v-model="mensagem.url"
                      id="imageToSend"
                      rows="3"
                    />

                    <a href="/admin/gallery" style="font-size: 15px">
                      Não possui url? Busque na sua galeria.</a
                    >
                  </div>
                  <div class="form-group">
                    <div v-if="mensagem.url" class="form-check">
                      <input
                        v-model="mensagem.preview"
                        type="checkbox"
                        class="form-check-input"
                        id="preview"
                      />
                      <label class="form-check-label" for="preview"
                        >Previsualizar vídeo?
                      </label>
                    </div>
                  </div>
                  <div v-if="mensagem.preview" class="form-check">
                    <video v-if="mensagem.preview" controls width="250" height="150">
                      <source :src="mensagem.url" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>

                <div v-if="mensagem.tipo_envio == 'voz'">
                  <div class="form-group">
                    <label for="imageToSend">Url </label>
                    <br />
                    <textarea
                      v-bind:class="{
                        'form-control': !mensagem.url_error,
                        'form-control is-invalid': mensagem.url_error,
                      }"
                      @blur="validateInput(mensagem)"
                      placeholder="Url do áudio a ser enviado"
                      v-model="mensagem.url"
                      id="imageToSend"
                      rows="4"
                      cols="40"
                    />
                    <a href="/admin/gallery" style="font-size: 15px">
                      Não possui url? Busque na sua galeria.</a
                    >
                    <video
                      v-if="mensagem.url"
                      controls
                      style="width: 100%; max-width: 300px; margin-top: 5%"
                      width="300"
                      height="50"
                      poster="{{ asset('/dist/img/whats-logo.png') }}"
                    >
                      <source :src="mensagem.url" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>

                <div v-if="mensagem.tipo_envio == 'link'">
                  <div class="form-group">
                    <label for="imageToSend">Url:</label>
                    <input
                      placeholder="Url a ser enviada"
                      style="width: 100%"
                      type="text"
                      id="phone"
                      nome="phone"
                      v-model="mensagem.url"
                      v-bind:class="{
                        'form-control': !mensagem.url_error,
                        'form-control is-invalid': mensagem.url_error,
                      }"
                      @blur="validateInput(mensagem)"
                    />
                  </div>
                </div>
                <div>
                  <button
                    @click="deleteMessage(mensagem)"
                    class="btn btn-outline-danger btn-sm btn-block"
                  >
                    <i class="fa fa-trash"></i>
                    Deletar Mensagem
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  props: ["my_container"],

  mixins: [wppController, sweetAlert, Swal],

  data() {
    return {
      container: {},
      nome_envio: null,
      mesage_to_send: "",
      url_to_send: null,
      isConnected: false,
      send_to_list: false,
      add_to_funil: false,
      list_break: "CSV",
      listToSend: null,
      contacts: [],
      preview: false,
      tipo_envio: "texto",
      mensagens: [],
    };
  },

  mounted() {
    this.container = this.my_container;
    console.log("Create Send Ready");
    console.log(this.tipo_envio);

    this.addMessage();
  },

  methods: {
    addMessage() {
      let mensagem = {
        id: this.mensagens.length + 1,
        tipo_envio: "texto",
        tag: this.getTag(),
        tempo: 0,
      };

      this.mensagens.push(mensagem);
    },

    getTag() {
      return (
        this.container.url + "-" + new Date().getTime() + "#msg" + this.mensagens.length
      );
    },

    deleteMessage(mensagem) {
      const index = this.mensagens.indexOf(mensagem);

      if (index > -1) {
        this.mensagens.splice(index, 1);
      }

      let aux = 1;

      //REORDENANDO ARRAY
      this.mensagens.forEach((element) => {
        element.id = aux;
        aux++;
      });
    },

    validateInput(mensagem) {
      if (mensagem.tipo_envio == "texto" && !mensagem.mensagem) {
        mensagem.message_error = true;
      } else {
        mensagem.message_error = false;
      }

      if (mensagem.tipo_envio != "texto" && !mensagem.url) {
        mensagem.url_error = true;
      } else {
        mensagem.url_error = false;
      }
    },

    validateFields() {
      if (this.mensagens.length <= 0) {
        this.showErrorMessageWithButton("Ops...", "Você precisa adicionar uma mensagem!");
      } else {
        this.mesage_to_send = this.mensagens[0].mensagem;
        this.url_to_send = this.mensagens[0].url;
        this.tipo_envio = this.mensagens[0].tipo_envio;

        if (!this.nome_envio) {
          this.showErrorMessageWithButton("Ops...", "O nome do envio é obrigatório!");
        } else if (this.hasInputError()) {
          this.showErrorMessageWithButton(
            "Ops...",
            "Preencha todos os campos corretamente!"
          );
        } else if (!this.listToSend) {
          this.showErrorMessageWithButton(
            "Ops...",
            "A lista de contatos não pode ficar em branco!"
          );
        } else if (this.mensagens.length <= 1) {
          this.showErrorMessageWithButton(
            "Ops...",
            "A sequência precisa ter pelo menos 2 mensagens!"
          );
        } else {
          this.sendToList();
        }
      }
    },

    hasInputError() {
      let hasError = false;

      for (let i = 0; i < this.mensagens.length; i++) {
        if (this.mensagens[i].tipo_envio == "texto" && !this.mensagens[i].mensagem) {
          this.mensagens[i].message_error = true;
          hasError = true;
        }

        if (this.mensagens[i].tipo_envio != "texto" && !this.mensagens[i].url) {
          this.mensagens[i].url_error = true;
          hasError = true;
        }
      }

      return hasError;
    },

    sendToList() {
      this.contacts = [];

      let arrayContatos = [];

      console.log("ListBreak");
      console.log(this.list_break);

      if (this.list_break == "CSV") {
        arrayContatos = this.listToSend.split(",");
      } else if (this.list_break == "Excel") {
        arrayContatos = this.listToSend.split("\n");
      } else if (this.list_break == "JSON") {
        if (this.validateJSON(this.listToSend)) {
          arrayContatos = this.extractJSON();
        } else {
          this.showErrorWithButton(
            "Ops...",
            null,
            "JSON Inválido! Confira os dados e tente novamente.<br/><br/>" +
              " <div style='font-size: 12px'>Ficou com dúvidas? Você pode validar o seu JSON!<br/>" +
              "<br/><a style='color:green' target='_blank' href='https://jsonformatter.curiousconcept.com/'> Valide aqui o seu JSON </a>" +
              "</div>"
          );

          return 0;
        }
      }

      console.log(arrayContatos);

      for (let i = 0; i < arrayContatos.length; i++) {
        //FIX UNDEFINED ISSUE
        if (!arrayContatos[i]) {
          arrayContatos[i] = "84";
        }

        //REMOVENDO TODOS OS CARACTERES, EXCETO NÚMEROS
        let newPhone = "55" + arrayContatos[i].replace(/[^Z0-9]/g, "");

        let isOnTheList = false;

        for (let i = 0; i < this.contacts.length; i++) {
          if (this.contacts[i].phone == newPhone) {
            isOnTheList = true;
          }
        }
        if (!isOnTheList) {
          this.contacts.push({
            phone: newPhone,
          });
        }
      }

      console.log("Contatos limpos");
      console.log(this.contacts);

      this.index = 0;

      this.contEnviados = 0;

      this.confirmarEnvio(
        "Confirmação",
        "Foram encontrados " +
          arrayContatos.length +
          " contato(s) deseja prosseguir com o envio?",
        () => {
          this.criarEnvio();
        }
      );
    },

    criarEnvio() {
      this.criarFunil((funil_id) => {
        let meta_dados = this.listToSend;

        if (this.list_break != "JSON") {
          meta_dados = undefined;
        }

        let data = {
          nome: this.nome_envio,
          id_container: this.container.id,
          mensagem: this.mesage_to_send,
          url: this.url_to_send,
          numeros: this.contacts,
          tipo_envio: this.tipo_envio.toUpperCase(),
          id_funil: null,
          total_contatos: this.contacts.length,
          meta_dados: meta_dados,
          funil_id: funil_id,
        };

        console.log("Dados do envio");
        console.log(data);

        axios
          .post(`/send/store`, data)
          .then((response) => {
            console.log("Envio criado!");
            console.log(response);

            this.showSuccessMessage("Envio criado!");

            window.location.href = "/send/" + response.data.envio_id + "/true";
          })
          .catch((error) => {
            console.log(error.response.data);
          });
      });
    },

    criarFunil(callback) {
      
      //DELETANDO PRIMEIRA MENSAGEM, ELA VAI FORA DO FUNIL
      this.mensagens.splice(0, 1);

      this.addMessageFlow();

      let data = {
        nome: this.nome_envio,
        mensagens: this.mensagens,
      };

      console.log("Dados do funil");
      console.log(data);

      axios
        .post(`/funil/store`, data)
        .then((response) => {
          console.log("Funil criado!");
          console.log(response);

          callback(response.data.funil_id);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addMessageFlow() {
      for (let i = 0; i < this.mensagens.length; i++) {
        //CASO TENHA SÓ 1 MENSAGEM OU CASO SEJA O ÚLTIMO ELEMENTO DO ARRAY
        if (this.mensagens.length == 1 || i + 1 == this.mensagens.length) {
          this.mensagens[i].resposta_esperada = [
            {
              proxima_tag: "*",
              encerrar_funil: "true",
              //teste: "teste"
            },
          ];
        } else {
          this.mensagens[i].resposta_esperada = [
            {
              proxima_tag: this.mensagens[i + 1].tag,
              encerrar_funil: "false",
              //teste: "teste"
            },
          ];
        }
      }
    },

    validateJSON(json) {
      console.log("VALIDANDO JSON");

      try {
        let jsonValido = JSON.parse(json);

        console.log("JS");
        console.log(jsonValido);

        if (jsonValido) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },

    extractJSON() {
      console.log("Listando json");
      console.log(this.listToSend);

      let json = JSON.parse(this.listToSend);

      console.log(json[0]);

      let arrayContatos = [];

      for (let i = 0; i < json.length; i++) {
        arrayContatos.push(json[i].Telefone);
      }

      return arrayContatos;
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
