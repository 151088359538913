<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

  <div
    @click.self="cancelModal"
    class="modal fade"
    id="create-formulario-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <form @submit.prevent>
      <div class="modal-dialog" style="text-align: left">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            <h4>Novo formulário</h4>

            <div class="form-group">
              <label for="password">Nome</label>
              <input id="porta" type="porta" class="form-control" v-model="formData.nome" />

              <span style="color: red" v-for="error in v$.nome.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>

            <button @click="store()" class="btn btn-success btn-sm">Salvar</button>

            <button
              id="close_create_formulario"
              style="margin-left: 5px"
              class="btn btn-danger btn-sm"
              data-toggle="modal"
              data-target="#create-formulario-modal"
              @click="setCriarformulario(false)"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import wppController from "../../controller/wppController.js";
import sweetAlert from "../../controller/sweetAlert.js";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import useVueLidate from "@vuelidate/core";
import { required } from "../../locales/i18n.js";
import { computed, reactive } from "vue";

export default {
  mixins: [wppController, sweetAlert, Swal],

  components: { Loading },

  props: ["container", "setCriarformulario", "formularioCriado", "user_selecionado", "users"],

  data() {
    return {
      nome: "",
      email: "",
      senha: "",
      isLoading: false,
      my_container: {},
    };
  },

  setup() {
    const formData = reactive({
      nome: "",
    });

    const rules = computed(() => {
      return {
        nome: { required },
      };
    });

    const v$ = useVueLidate(rules, formData);

    return { formData, v$ };
  },

  mounted() {},

  methods: {
    cancelModal() {
      this.setCriarformulario(false);
    },

    async store() {
      const result = await this.v$.$validate();

      if (result) {
        this.isLoading = true;

        let data = {
          nome: this.formData.nome,
        };

        axios
          .post(`/admin/formulario/store`, data)
          .then((response) => {
            this.isLoading = false;

            this.showSuccessMessage("Formulário criado!");
            this.formularioCriado();
          })
          .catch((error) => {
            this.isLoading = false;
            this.showErrorMessageWithButton("Ops..", error.response.data);
            console.log(error.response.data);
          });
      }
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
