<template>
  <div class="card">
    <div class="card-body p-0">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 25%">Usuario</th>
            <th style="width: 25%">Nome</th>
            <th style="width: 40px">Tag</th>
            <th style="width: 25%">Data</th>
            <th style="width: 10%">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="mensagem in mensagens_padrao" :key="mensagem.id">
            <td data-label="Nome">{{ mensagem.usuario }}</td>
            <td data-label="Nome">{{ mensagem.nome }}</td>
            <td data-label="Tag" v-if="mensagem.tag">{{ mensagem.tag }}</td>
            <td data-label="Tag" v-if="!mensagem.tag"> - </td>
            <td data-label="Data">{{ formatDate(mensagem.created_at) }}</td>
            <td data-label="Ações">
            <td>
              <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />
              <button @click="selecionarMensagem(mensagem)" data-toggle="modal" data-target="#modificar-modal"
                type="button" class="btn btn-primary btn-sm">
                Gerenciar <i class="nav-icon "></i>
              </button>

              <div class="modal fade" id="modificar-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                aria-hidden="true">
                <form @submit.prevent>
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-body">
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                          &times;
                        </button>
                        <h4>Editar mensagem</h4>
                        <br />
                        <div class="form-group">
                          <label>Nome da mensagem</label>
                          <input class="form-control" v-model="nome_selecionado" />
                        </div>

                        <div class="form-group">
                          <label for="exampleInputEmail1">Mensagem</label><br />
                          <div class="row">
                            <div style="margin-bottom: 2px;" class="col-md-12 col-sm-12 col-12">
                              <textarea class="form-control" placeholder="Digite sua mensagem"
                                v-model="mensagem_selecionada" rows="4" cols="40" />
                            </div>
                            <div class="div-left col-md-4 col-sm-6 col-12">
                              <button @click="addTagEdit('<NomePaciente>')" class="tags btn btn-sm">
                                Nome Paciente
                              </button>
                            </div>
                            <div class="div-center col-md-4 col-sm-6 col-12">
                              <button @click="addTagEdit('<Data>')" class="tags btn btn-sm">
                                Data Atendimento
                              </button>
                            </div>
                            <div class="div-right col-md-4 col-sm-6 col-12">
                              <button @click="addTagEdit('<NomeMedico>')" class="tags btn btn-sm">
                                Nome Médico
                              </button>
                            </div>
                            <div class="div-left col-md-4 col-sm-6 col-12">
                              <button @click="addTagEdit('<Procedimento>')" class="tags btn btn-sm">
                                Procedimento
                              </button>
                            </div>
                            <div class="div-center col-md-4 col-sm-6 col-12">
                              <button @click="addTagEdit('<Horario>')" class="tags btn btn-sm">
                                Horário
                              </button>
                            </div>
                            <div class="div-right col-md-4 col-sm-6 col-12">
                              <button @click="addTagEdit('<Convenio>')" class="tags btn btn-sm">
                                Convenio
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="exampleSelectRounded0">Mensagem padrão?</label>
                          <select v-model="mensagem_padrao_selecionada" class="custom-select rounded-0">
                            <option :value="0">Não</option>
                            <option :value="1">Sim</option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label for="exampleSelectRounded0">Tag</label>
                          <select v-model="tag_selecionada" class="custom-select rounded-0">
                            <option value="Aniversariantes">Aniversariantes</option>
                            <option value="Atendidos">Atendidos</option>
                            <option value="Agendados">Agendados</option>
                            <option value="Agendados 1 dia antes">Agendados 1 dia</option>
                            <option value="Agendados 2 dias antes">Agendados 2 dias</option>
                            <option value="Agendados 3 dias antes">Agendados 3 dias</option>
                            <option value="Agendados 7 dias antes">Agendados 7 dias</option>
                            <option value="Exames 1 dia depois">Exames 1 dia depois</option>
                            <option value="Exames 1 dia depois - RETORNO">Exames 1 dia depois - RETORNO</option>
                            <option value="Mensagem automática">Mensagem Automática</option>                            
                          </select>
                        </div>
                        <button style="margin: 8px" @click="update()" class="btn btn-primary btn-sm">
                          Modificar
                        </button>

                        <button @click="confirmaDelete()" class="btn btn-danger btn-sm">
                          Deletar
                        </button>

                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </td>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
    <!-- /.card-body -->
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [wppController, sweetAlert, Swal],

  components: { Loading },

  props: ["mensagens_padrao"],

  data() {
    return {
      container: {},
      nome: "",
      email: "",
      senha: "",
      isLoading: false,
      usuario_selecionado: "",
      nome_selecionado: "",
      tag_selecionada: "",
      mensagem_selecionada: "",
      mensagem_padrao_selecionada: "",
      id_selecionado: "",
    };
  },

  mounted() {
    console.log("MOUNTEDD");

  },

  methods: {

    confirmaDelete() {

      Swal.fire({
        title: 'Deseja apagar sua mensagem?',
        text: "Sua Ação não poderá ser desfeita",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, deletar!'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'Deletado!',
            'Sua mensagem foi deletada',
            'Successo'
          )
          this.delete();
        }
      })
    },

    delete() {

      let data = {
        //usuario_id: this.usuario_selecionado,
        id: this.id_selecionado,
      };

      console.log("DELETANDO...");
      console.log(data);
      axios
        .post(`/admin/mensagens-padrao/delete`, data)
        .then((response) => {
          console.log("Usuário criado!");
          console.log(response);

          //this.showSuccessMessage("Mensagem criada!");

          window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },
    update() {

      let data = {
        nome: this.nome_selecionado,
        usuario_id: this.usuario_selecionado,
        mensagem: this.mensagem_selecionada,
        mensagem_padrao: this.mensagem_padrao_selecionada,
        tag: this.tag_selecionada,
        id: this.id_selecionado,
      };

      console.log("CRIANDO");
      console.log(data);

      axios
        .post(`/admin/mensagens-padrao/update`, data)
        .then((response) => {
          console.log("Usuário criado!");
          console.log(response);

          this.showSuccessMessage("Mensagem criada!");

          window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },


    selecionarMensagem(mensagemselecionada) {
      console.log(mensagemselecionada)

      this.nome_selecionado = mensagemselecionada.nome
      this.tag_selecionada = mensagemselecionada.tag
      this.mensagem_selecionada = mensagemselecionada.mensagem
      this.mensagem_padrao_selecionada = mensagemselecionada.mensagem_padrao
      this.usuario_selecionado = mensagemselecionada.user_id
      this.id_selecionado = mensagemselecionada.id
    },
    addTagEdit(tag) {
      this.mensagem_selecionada = this.mensagem_selecionada + tag;
    },

    formatDate(date) {
      return moment(date).format("DD-MM-yyyy - HH:mm:ss");
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}


.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}


.div-right {
  padding-left: 0px;
}

.div-center {
  padding-right: 0px;
  padding-left: 0px;
}

.div-left {
  padding-right: 0px;
}

@media (max-width: 600px) {
  .div-right {
    padding: 1px 1px 0px 1px;
  }

  .div-center {
    padding: 1px 1px 0px 1px;
  }

  .div-left {
    padding: 1px 1px 0px 1px;
  }
}

.tags {
  width: 100%;
  border-style: solid;
  border-color: black;
  color: white;
  background-color: gray;
  border-width: 2px;
  margin: 0.5px 0.5px 2px 0.5px;
  padding: 0px;
}
</style>
