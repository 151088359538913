<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

  <button style="margin-top: 2%;" data-toggle="modal" data-target="#login-modal" type="button" class="btn btn-success">
    Novo Servidor <i class="nav-icon far fa-plus-square"></i>
  </button>

  <!-- LISTAGEM DOS SERVIDORES-->
  <div class="card">
    <div class="card-body p-0">
      <table class="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Nome</th>
            <th>Porta</th>
            <th>Status</th>
            <th>Memória</th>
            <th>CPU</th>
            <th style="width: 10%">Ações</th>
            <th style="width: 12%">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="servidor in servidores" :key="servidor.id">

            <td data-label="Id">{{ servidor.id }}</td>

            <td data-label="Nome">{{ servidor.nome }}</td>

            <td data-label="Porta">{{ servidor.porta }}</td>

            <td data-label="Status" :class="servidor.status === 'online' ? 'online' : 'stopped'">{{ servidor.status }}</td>

            <td data-label="Mem.">{{ servidor.memoria }}</td>

            <td data-label="CPU">{{ servidor.cpu }}</td>

            <td>
              <button style="width:100%" @click="confirmacao(servidor, 'Reiniciar')" type="button"
                class="btn btn-primary btn-sm">
                Reiniciar
              </button>
            </td>
            <td>
              <button style="width:100%" @click="confirmacao(servidor, 'Deletar')" type="button"
                class="btn btn-danger btn-sm">
                Deletar
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- MODAL NOVO SERVIDOR-->
  <div class="modal fade" id="login-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <form @submit.prevent>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
              &times;
            </button>
            <h4>Novo Servidor</h4>
            <div class="form-group">
              <label for="email">Nome</label>
              <input id="nome" type="text" class="form-control" v-model="nome" />

            </div>

            <div class="form-group">
              <label for="email">Porta</label>
              <input id="email" type="text" class="form-control" v-model="porta" />
            </div>

            <button @click="subirServidor()" class="btn btn-primary btn-flat">
              Cadastrar <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [wppController, sweetAlert, Swal],

  components: { Loading },

  props: [],

  data() {
    return {
      container: {},
      nome: "",
      porta: "",
      isLoading: false,
      servidores: [],
    };
  },

  mounted() {
    this.listarServidores();
  },

  methods: {

    subirServidor() {

      let data = {
        nome: this.nome,
        porta: this.porta,
      }

      axios
        .post(process.env.MIX_VUE_APP_ENDPOINT_PM2 + `/start`, data)
        .then((response) => {
          this.showSuccessMessage("Criado com sucesso!");
          window.location.reload();

        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    listarServidores(data) {

      console.log(process.env.MIX_VUE_APP_ENDPOINT_PM2 + `/list`);

      axios
        .get(process.env.MIX_VUE_APP_ENDPOINT_PM2 + `/list`)
        .then((response) => {
          this.servidores = response.data.processos;
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    confirmacao(servidor, acao) {
      let html = "Deseja <b style='font-size:20px'>" + acao + " </b> o servidor <br/><br/>" +
        "<b style='font-size:25px'>" + servidor.nome + "</b>";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!"
        })
        .then(result => {
          if (result.value) {
            if (acao == "Reiniciar") {
              this.reiniciar(servidor);
            } else {
              this.deletar(servidor);
            }
          }
        });
    },

    reiniciar(servidor) {

      this.isLoading = true;

      let data = {
        appName: servidor.nome
      }

      axios
        .post(process.env.MIX_VUE_APP_ENDPOINT_PM2 + `/restart`, data)
        .then((response) => {
          this.showSuccessMessage("Reiniciado com sucesso!");
          this.isLoading = false;

        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    deletar(servidor) {

      this.isLoading = true;

      let data = {
        appName: servidor.nome
      }

      axios
        .post(process.env.MIX_VUE_APP_ENDPOINT_PM2 + `/delete`, data)
        .then((response) => {
          this.showSuccessMessage("Deletado com sucesso!");
          window.location.reload();

        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },
  },
};
</script>

<style scoped>
.online {
  color: green;
  font-weight: bold;
}

.stopped {
  color: red;
  font-weight: bold;
}

.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
