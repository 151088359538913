<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

  <br />
  <!-- FILTRAR USUÁRIOS-->
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Filtrar usuários</h3>
    </div>
    <div class="card-body">
      <div class="input-group mb-3">
        <input type="text" name="search" class="form-control" placeholder="Nome/email" v-model="filtro" />
        <span class="input-group-append">
          <button type="submit" class="btn btn-primary btn-sm" @click="searchUsers">Buscar</button>
        </span>
      </div>
    </div>
  </div>

  <admin-create-user />
  <!-- TABELA USUÁRIOS-->
  <div class="card card-primary">
    <div class="card-header">
      <h3 class="card-title">Usuários</h3>
    </div>
    <div class="card-body p-0">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 10%">Nome</th>
            <th style="width: 10%">Status</th>
            <th>Tipo</th>
            <th>Conexões</th>
            <th>Ações</th>
            <th>Ações personalizadas</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td data-label="Nome">{{ user.name }}</td>

            <td v-if="user.acesso_negado == 0" data-label="Status" style="color: green">Ativo</td>
            <td v-else style="color: red">Bloqueado</td>

            <td data-label="Tipo">{{ user.tipo }}</td>
            <td data-label="Conexões">{{ user.qtd_conexoes }}</td>

            <td>
              <button
                data-toggle="modal"
                data-target="#edit-user-modal"
                style="width: 100%"
                @click="selecionarUsuario(user), (editar_usuario = true)"
                type="button"
                class="btn btn-primary btn-sm"
              >
                Editar
              </button>
            </td>

            <td>
              <button
                style="font-size: 11px"
                data-toggle="modal"
                data-target="#acoes-modal"
                @click="selecionarUsuario(user)"
                type="button"
                class="btn btn-info"
              >
                Ações personalizadas
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="card-footer clearfix">
      <!-- TOTAIS  -->
      <div style="font-size: 13px" class="float-left" aria-live="polite">
        Exibindo do {{ inicio + 1 }} ao {{ inicio + users.length }}
        <p>Total {{ total }}</p>
      </div>

      <p class="card-title" style="font-size: 12px; color: black; margin-right: 5px; margin-left: 10px">Por página</p>

      <select @change="alterarQtdPagina()" class="float-left card-titlecustom-select rounded-0" style="font-size: 12px" v-model="qtd_por_pagina">
        <option :value="1">1</option>
        <option :value="2">2</option>
        <option :value="7">7</option>
        <option :value="15">15</option>
        <option :value="25">25</option>
      </select>

      <div class="card-tools">
        <div class="input-group input-group-sm" style="width: 150px"></div>
      </div>

      <!-- PAGINAÇÃO  -->
      <ul class="pagination pagination-sm m-0 float-right">
        <li class="page-item"><a class="page-link" @click="selecionarPagina(pagina_atual - 1)">&laquo;</a></li>

        <li v-for="pagina in paginas" :key="pagina" :value="pagina" class="page-item">
          <a :class="pagina === pagina_atual ? 'page-link current-page' : 'stopped'" class="page-link" @click="selecionarPagina(pagina)">
            {{ pagina }}
          </a>
        </li>

        <li class="page-item"><a class="page-link" @click="selecionarPagina(pagina_atual + 1)">&raquo;</a></li>
      </ul>
    </div>
  </div>

  <admin-edit-user v-if="editar_usuario" :user="usuario_selecionado" :setEditarUsuario="setEditarUsuario" :usuarioEditado="usuarioEditado" />

  <div class="modal fade" id="acoes-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" v-if="usuario_selecionado">
    <form @submit.prevent>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            <h4>Ações do usuário</h4>
            <br />

            <div class="form-group">
              <label>Nova ação</label>
              <div class="input-group mb-3">
                <select class="form-control" v-model="acao_selecionada">
                  <option v-for="acao in acoes_personalizadas" :key="acao" :value="acao">
                    {{ acao.acao }}
                  </option>
                </select>

                <div class="input-group-prepend">
                  <button type="button" class="btn btn-success" @click="addAcao()">
                    Add
                    <i style="margin-left: 3px" class="nav-icon far fa-plus-square"></i>
                  </button>
                </div>
              </div>
            </div>

            <table id="zero_config" class="table table-striped table-bordered no-wrap">
              <thead>
                <tr>
                  <th>Ação</th>
                  <th style="width: 15%">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(acao, index) in acoes_usuario" :key="acao" :value="acao">
                  <td>{{ acao }}</td>

                  <td>
                    <button @click="confirmarDelete(acao, index)" type="button" class="btn btn-danger">
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <button data-toggle="modal" data-target="#editar-modal" class="btn btn-info">Cancelar</button>
            <button @click="update()" class="btn btn-primary">Salvar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import wppController from "../controller/wppController";
import sweetAlert from "../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [wppController, sweetAlert, Swal],

  components: { Loading },

  props: ["containers", "acoes_personalizadas"],

  data() {
    return {
      container: {},
      nome: "",
      email: "",
      senha: "",
      isLoading: false,
      usuario_selecionado: "",
      users: [],
      acoes_usuario: [],
      acao_selecionada: "",
      filtro: "",
      paginas: [],
      inicio: 0,
      total: 0,
      qtd_por_pagina: 25,
      pagina_atual: 1,
      editar_usuario: false,
    };
  },

  mounted() {
    this.searchUsers();
  },

  methods: {
    alterarQtdPagina() {
      this.pagina_atual = 1;
      this.inicio = 0;

      this.searchUsers();
    },

    usuarioEditado() {
      this.setEditarUsuario(false);

      this.searchUsers();
    },

    setEditarUsuario(value) {
      let btn = document.getElementById("close_edit_user");
      if (btn) {
        btn.click();
      }

      this.editar_usuario = value;
    },

    searchUsers() {
      let data = {
        filtro: this.filtro,
        inicio: this.inicio,
        tamanho: this.qtd_por_pagina,
      };

      axios
        .post(`/admin/users/search`, data)
        .then((response) => {
          this.users = response.data.users;
          this.total = response.data.total;

          this.pagination(response.data);

          console.log("USERS");
          console.log(this.users);
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    selecionarPagina(pagina) {
      if (pagina <= this.paginas.length && pagina > 0) {
        this.inicio = this.qtd_por_pagina * (pagina - 1);
        this.pagina_atual = pagina;
        this.searchUsers();
      }
    },

    pagination(data) {
      this.paginas = [];

      let length = data.total / this.qtd_por_pagina;
      let i = 0;

      //DEFININDO MÁXIMO E MÍNIMO DE PÁGINAS
      if (length < 0) {
        length = 0;
      } else if (length > 10) {
        if (this.pagina_atual >= 10) {
          let minimo = this.pagina_atual - 10;

          if (length - this.pagina_atual > 10) {
            length = this.pagina_atual + 10;
            i = minimo;
          } else {
            i = this.pagina_atual - 10 + (length - this.pagina_atual);
          }
        } else {
          length = 10;
        }
      }

      for (i; i < length; i++) {
        this.paginas.push(i + 1);
      }
    },

    selecionarUsuario(usuario) {
      this.usuario_selecionado = usuario;

      if (usuario.acoes_personalizadas) {
        this.acoes_usuario = JSON.parse(usuario.acoes_personalizadas);
      } else {
        this.acoes_usuario = [];
      }
    },

    addAcao() {
      this.acoes_usuario.push(this.acao_selecionada.acao);
    },

    confirmarDelete(acao, indice) {
      let html = "Deseja <b style='font-size:20px; color: red'> Remover</b> a ação <br/>" + "<b style='font-size:25px'>" + acao + "</b><br/>?";

      this.$swal
        .fire({
          title: "Confirmação",
          html: html,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!",
        })
        .then((result) => {
          if (result.value) {
            this.acoes_usuario.splice(indice, 1);
            this.update();
          }
        });
    },

    show() {
      let status1 = this.my_container.status_usuario;
      let status2 = "Ativo";
      let email = this.my_container.user.email;

      if (status1 == "Ativo") {
        status2 = "Bloqueado";
      }

      console.log("Status 1 " + status1);
      console.log("Status 2 " + status2);

      this.edit(this.my_container.nome, status1, status2, (response) => {
        if (!response.isDismissed) {
          let data = {
            nome: response.value[0],
            senha: response.value[1],
            status: response.value[2],
            id: this.my_container.id,
            user_id: this.my_container.user_id,
          };

          console.log(data);
          this.validateFields(data);
        }
      });
    },

    validateFields(data) {
      if (!data.nome || data.nome == "") {
        this.showErrorMessageWithButton("Ops...", "O campo nome é obrigatório!");
      } else {
        this.update(data);
      }
    },

    update() {
      let data = {
        id: this.usuario_selecionado.id,
        acoes_personalizadas: JSON.stringify(this.acoes_usuario),
      };

      axios
        .post(`/admin/users/update/acoes`, data)
        .then((response) => {
          this.showSuccessMessage("Ações atualizadas!");

          window.location.reload();
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },
  },
};
</script>

<style scoped>
.swal-modal {
  color: green;
  background-color: rgba(63, 255, 106, 0.69);
  border: 3px solid white;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}
</style>
